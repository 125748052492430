import { get } from 'lodash';
import { request, s3, format } from '@moved/services';

export const makeDOMElement = (
  tagName,
  classNames = null,
  attributes = {},
) => {
  // create new dom element
  const element = document.createElement(tagName);

  // add classes to the element
  if (Array.isArray(classNames)) {
    element.classList.add(...classNames);
  } else if (classNames) {
    element.classList.add(classNames);
  }

  // add attributes to the element
  for (const attrName in attributes) {
    if(attrName === 'value') element.value = attributes[attrName];
    else if(attrName === 'data') {
      for (const dataName in attributes[attrName]) {
        element.dataset[dataName] = attributes[attrName][dataName];
      }
    }
    else {
      element[attrName] = attributes[attrName];
    }
  }

  return element;

};

export const generateUploader = url => ({
  uploader: {
    /**
     * Upload file to the server and return an uploaded data
     * @param {File} file - file selected from the device or pasted by drag-n-drop
     * @return {Promise.<{success, file: {url}}>}
    */
    uploadByFile: file => {
      const filename = format.filename(file.name);
      return request.post(url, {
        filename,
        http_content_type: file.type,
      })
        .then(r => get(r,'data.data.presigned_s3_url'))
        .then(({ signed_request, url }) => s3.putFile(file, signed_request).then(() => url))
        .then(url => ({
          success: 1,
          file: {
            url,
            name: filename,
            size: file.size,
            extension: filename.split('.').pop(),
          },
          title: `Download ${filename}`,
        }));
    },
  },
});
