import DefaultHeading from '@editorjs/header';
import { createRoot } from 'react-dom/client';

import { makeDOMElement } from '../shared/helpers';
import { EditorIconSelector } from '../shared/EditorIconSelector';
import CSS from './Heading.module.scss';

// extending default Heading class with overrides
// https://github.com/editor-js/header/blob/master/src/index.js
export class Heading extends DefaultHeading {

  static allLevels = [
    {
      number: 1,
      classes: ['displayS'],
      tag: 'h1',
      svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="0">
        <path d="M17.0462 9.7H13.3662V8.6C14.5062 8.6 15.4062 8.36 16.1062 7.88C16.8062 7.42 17.1662 6.78 17.2062 6H18.6862V17.46H22.0062V18.96H13.6262V17.46H17.0462V9.7Z" fill="#C2C5C9"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 19V5.51001H2.729V11.267H10.177V5.51001H11.906V19H10.177V12.939H2.729V19H1Z" fill="#151618"/>
      </svg>`,
    },
    {
      number: 2,
      classes: ['headingL'],
      tag: 'h2',
      svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="0">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 19V5.51001H2.729V11.267H10.177V5.51001H11.906V19H10.177V12.939H2.729V19H1Z" fill="#151618"/>
        <path d="M13.7601 10.48C13.7201 10.3 13.7001 10.12 13.7001 9.90001C13.7001 9.34001 13.8001 8.80001 14.0201 8.30001C14.2201 7.80001 14.5201 7.36001 14.9201 6.98001C15.3001 6.60001 15.7801 6.30001 16.3401 6.08001C16.8801 5.86001 17.5001 5.76001 18.2001 5.76001C18.8601 5.76001 19.4601 5.86001 20.0001 6.06001C20.5401 6.28001 20.9801 6.56001 21.3601 6.92001C21.7201 7.28001 22.0001 7.70001 22.2001 8.16001C22.3801 8.64001 22.4801 9.14001 22.4801 9.68001C22.4801 10.54 22.2401 11.3 21.7801 11.96C21.3001 12.62 20.6801 13.2 19.9401 13.66L17.2801 15.36C16.9001 15.62 16.5401 15.92 16.2201 16.26C15.9001 16.6 15.7001 17.02 15.6201 17.48H22.6001V19H13.5801C13.6001 18 13.8401 17.1 14.2801 16.28C14.7201 15.48 15.4601 14.74 16.5001 14.06L18.8201 12.56C19.4601 12.14 19.9601 11.7 20.3001 11.24C20.6401 10.78 20.8001 10.28 20.8001 9.70001C20.8001 9.38001 20.7401 9.08001 20.6401 8.78001C20.5201 8.48001 20.3601 8.22001 20.1401 7.98001C19.9201 7.76001 19.6401 7.58001 19.3001 7.44001C18.9601 7.30001 18.5801 7.24001 18.1601 7.24001C17.7201 7.24001 17.3201 7.32001 16.9801 7.46001C16.6401 7.60001 16.3401 7.80001 16.1001 8.04001C15.8401 8.30001 15.6601 8.60001 15.5401 8.94001C15.4001 9.28001 15.3401 9.66001 15.3401 10.06C15.3401 10.32 15.3601 10.52 15.4001 10.68L13.7601 10.48Z" fill="#C2C5C9"/>
      </svg>`,
    },
    {
      number: 3,
      classes: ['headingM'],
      tag: 'h3',
      svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="0">
        <path d="M18.6262 11.14C19.1662 11.14 19.7062 11.22 20.2062 11.4C20.7062 11.58 21.1462 11.82 21.5262 12.16C21.9062 12.5 22.2062 12.92 22.4262 13.42C22.6462 13.92 22.7662 14.48 22.7662 15.12C22.7662 15.66 22.6662 16.18 22.4662 16.68C22.2462 17.18 21.9462 17.62 21.5662 18C21.1662 18.38 20.6862 18.68 20.1062 18.9C19.5262 19.12 18.8862 19.24 18.1462 19.24C17.4462 19.24 16.8062 19.12 16.2462 18.9C15.6662 18.68 15.1862 18.4 14.7662 18.02C14.3462 17.66 14.0262 17.24 13.7862 16.74C13.5462 16.26 13.4062 15.74 13.3662 15.2L15.0062 14.88C15.0462 15.32 15.1662 15.72 15.3462 16.06C15.5262 16.42 15.7462 16.72 16.0262 16.96C16.3062 17.22 16.6262 17.4 16.9862 17.54C17.3462 17.68 17.7262 17.74 18.1262 17.74C19.0262 17.74 19.7662 17.5 20.3062 17C20.8462 16.52 21.1062 15.9 21.1062 15.16C21.1062 14.3 20.8462 13.64 20.3262 13.22C19.7862 12.8 19.1062 12.58 18.2662 12.58C17.8062 12.58 17.4262 12.62 17.0862 12.72L16.3062 11.32L20.3262 7.52H13.7662V6H22.4862V7.5L18.6262 11.14Z" fill="#C2C5C9"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 19V5.51001H2.729V11.267H10.177V5.51001H11.906V19H10.177V12.939H2.729V19H1Z" fill="#151618"/>
      </svg>`,
    },
    {
      number: 4,
      classes: ['headingS'],
      tag: 'h4',
      svg: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="0">
        <path d="M21.02 6V14.42H23.28V15.94H21.02V18.96H19.38V15.94H13V14.02L18.72 6H21.02ZM19.38 14.42V7.62L14.54 14.42H19.38Z" fill="#C2C5C9"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1 19V5.51001H2.729V11.267H10.177V5.51001H11.906V19H10.177V12.939H2.729V19H1Z" fill="#151618"/>
      </svg>`,
    },
  ];

  constructor({ data, config, api, readOnly, block }) {
    super({ data, config, api, readOnly });

    // extend the class with extra context
    this.block = block;
    this.placeholder = config.placeholder || 'Enter text...';

    // set internal data
    this._levels = Heading.allLevels;
    this._defaultLevel = this._levels[0];
    this._data = {
      text: data.text || '',
      level: parseInt(data.level) || this._defaultLevel.number,
      showIcon: data.showIcon,
      icon: data.icon || null,
    };
    this._element = null;
  }


  /* Internal Data Getters and Setters */
  get data() { return this._data; }
  set data(data) {
    this._data = {
      ...this._data,
      ...data,
    };
    // trigger a re-render
    const wrapper = this._element;
    if(wrapper) wrapper.parentNode.replaceChild(this.render(), wrapper);
  }

  // need to override the parent functions
  get levels() { return this._levels || super.levels; }
  set levels(levels) { this._levels = levels; }

  // need to override the parent functions
  get defaultlevel() { return this._defaultLevel; }
  set defaultlevel(level) { this._defaultlevel = level; }

  get initialIcon() { return { library: 'code', symbol: 'Stop' }; }

  // need to override the parent functions
  get currentLevel() { return this.levels.find(level => level.number === this._data.level); }

  /* EditorJS API class functions */
  save(wrapper) {
    const heading = wrapper.querySelector(`.${CSS.heading}`);
    this._data.text = heading.innerHTML.trim();
    return this._data;
  }

  render() {
    const wrapper = makeDOMElement('div',[CSS.wrapper]);
    const headingTag = makeDOMElement(
      this.currentLevel.tag,
      [CSS.heading,...this.currentLevel.classes],
      {
        innerHTML: this._data.text || '',
        contentEditable: this.readOnly ? 'false' : 'true',
        data: { placeholder: this.placeholder }
      }
    );
    const iconSelector = makeDOMElement('div',[CSS.icon]);

    if(this._data.showIcon) {
      const onIconSelect = (icon) => this.data = { icon };
      const root = createRoot(iconSelector);
      root.render(<EditorIconSelector initial={this.data.icon} onSelect={onIconSelect} />);
      wrapper.appendChild(iconSelector);
    }

    wrapper.appendChild(headingTag);
    this._element = wrapper;

    return wrapper;
  }

  renderSettings() {
    return [
      {
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="0">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M8 5C8 3.34315 9.34315 2 11 2C12.6569 2 14 3.34315 14 5V6H17C18.1046 6 19 6.89543 19 8V11H20C21.6569 11 23 12.3431 23 14C23 15.6569 21.6569 17 20 17H19V20C19 21.1046 18.1046 22 17 22H5C3.89543 22 3 21.1046 3 20V17H5C6.65685 17 8 15.6569 8 14C8 12.3431 6.65685 11 5 11H3V8C3 6.89543 3.89543 6 5 6H8V5Z" fill="#C2C5C9"/>
        </svg>`,
        label: this.api.i18n.t(`With icon`),
        isActive: this._data.showIcon,
        closeOnActivate: true,
        onActivate: () => this.toggleTune({
          showIcon: !this._data.showIcon,
          icon: !this._data.showIcon ? this.initialIcon : null,
        }),
      },
      ...this.levels.map(level => ({
        icon: level.svg,
        label: this.api.i18n.t(`Heading ${level.number}`),
        isActive: this._data.level === level.number,
        closeOnActivate: true,
        toggle: 'level',
        onActivate: () => this.toggleTune({ level: level.number }),
      })),
    ];
  }

  toggleTune(data) {
    this.data = data;
    this.block.dispatchChange();
  }

};
