/*
*   CardSetupField
*     Use this component if you have an existing form with additional fields
*     Params
*       form: (required) formik form reference from parent form
*       ref: (required) a reference to assign to this component that is used
*         to expose the promise to do the stripe card setup request
*         via stripe API. Use this promise in the onSubmit handler where needed
*     API
*       confirmCard(): function added to the ref of this component
*         returns promise of stripe SDK request to confirm the card and returns
*         stripe paymentIntent with paymentMethod attached.
*         (some oddities to note: since this field does its own stripe validation,
*         it will return false to the success function if there was a validation
*         error. The error handler is only used for legitimate error messages
*          from the stripe sdk.)
*/

import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { request } from '@moved/services';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import classNames from 'classnames';
import { get } from 'lodash';

import { FieldDefault } from '../DynamicForm/Fields/FieldDefault';
import formCSS from '../DynamicForm/StyledForm.module.scss';
import stripeCSS from './CardSetupForm.module.scss';

const options = {
  classes: {
    base: classNames(stripeCSS.card_input, 'fs-exclude'),
    invalid: stripeCSS.card_invalid,
    focus: stripeCSS.card_focus,
  },
  style: {
    base: {
      color: stripeCSS.color,
      fontFamily: stripeCSS.fonts,
      fontSize: '14px',
      fontWeight: 400,
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: stripeCSS.placeholder,
      }
    },
    invalid: {
      color: stripeCSS.invalid,
      '::placeholder': {
        color: stripeCSS.invalid
      }
    }
  }
};

const nameField = {
  label: 'Name on card',
  name: 'nameOnCard',
  type: 'text',
  value: '',
  placeholder: ' ',
  required: true,
};

// Base level component to handle just the stripe card setup input(s)
export const CardSetupField = forwardRef(({form, setStripeConfirmCard}, ref) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState(false);
  const [validationError, setValidationError] = useState(false);

  const refreshSetupIntent = () => {
    return request.post(`${process.env.REACT_APP_API_V2_URL}/stripe/setup-intent`)
      .then(r => setClientSecret(r.data.data.client_secret));
  };

  useEffect(() => {
    refreshSetupIntent();
  },[]); // eslint-disable-line

  // define api functions expose on ref
  useImperativeHandle(ref, () => ({
    confirmCard: () => {
      const { nameOnCard } = form.values;
      return new Promise((resolve,reject) => {
        if (!stripe || !elements || !clientSecret) return reject({message:'Stripe data failed to load'});
        stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: { name: nameOnCard },
          }
        }).then((result) => {
          // handle local field validation
          if(get(result, 'error.type') === 'validation_error') {
            setValidationError(get(result, 'error.message'));
            return resolve(false);
          }
          if(validationError) setValidationError(false);
          // handle stripe response (beyond validation errors)
          if(result.error) reject(result.error);
          else resolve(result);
          refreshSetupIntent();
        });
      });
    },
  }));

  return (
    <>
      <FieldDefault input={nameField} form={form}/>
      <div className={formCSS.input_row}>
        <CardElement options={options} onChange={(event) => setValidationError(get(event, 'error.message'))} />
        { validationError && (
          <label className={formCSS.error_msg}>{ validationError }</label>
        )}
      </div>
    </>
  );
});
