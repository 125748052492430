import React, { useState, useEffect, useRef } from 'react';

import { useModal, format } from '@moved/services';

import { EmptyContent } from '../EmptyContent';

import { Button } from '../controls/Button';
import { Select } from '../controls/Select';
import { Text } from '../controls/Text';
import { OptionList } from '../controls/OptionList';
import { ModalTitle, ModalContent, ModalActions } from '../Modal';

import { libraries } from '../Icon/libraries';

import CSS from './IconSelectionModal.module.scss';

export const IconSelectionModal = ({ initial={} }) => {
  const Modal = useModal();
  const element = useRef();
  const [filter, setFilter] = useState('');
  const [library, setLibrary] = useState(initial?.library ?? 'all');
  const [symbolOptions, setSymbolOptions] = useState([]);
  const [selected, setSelected] = useState(initial);

  const availableSymbolOptions = symbolOptions.filter(
    symbol => !filter || symbol?.id?.toLowerCase().includes(filter?.toLowerCase())
  );

  useEffect(() => {
    const sprite = library === 'all' ? element?.current : element?.current?.querySelector(`#${library}`);
    const symbols = sprite ? Array.from(sprite.querySelectorAll("symbol")) : [];
    setSymbolOptions(symbols.map(symbol => ({
      key: `${symbol.parentNode.id}|${symbol.id}`,
      id: symbol.id,
      label: (<>
        <div>{symbol.id}</div>
        { library === 'all' && (<div className='labelXS contentSecondary'>{ format.capitalize(symbol.parentNode?.id) }</div>)}
      </>),
      value: { library: symbol.parentNode?.id, symbol: symbol.id },
      icon: { library: symbol.parentNode?.id, symbol: symbol.id },
      className: CSS.symbolOption,
    })));
  },[library]);

  const submit = () => selected.symbol != null && Modal.close(selected);

  return (
    <>
      <ModalTitle>Choose icon</ModalTitle>

      <ModalContent className={CSS.content}>
        <div className={CSS.hidden} ref={element}>
          { libraries.map(({ name, Component }) => <Component id={name} key={name} />) }
        </div>

        <div className={CSS.filters}>
          <Select
            name='library'
            label='Library'
            value={library}
            isControlled={true}
            options={[
              { label: 'All', value: 'all'},
              ...libraries.map(({ label, name }) => ({ label, value: name }))
            ]}
            onChange={({ library }) => setLibrary(library)}
          />
          <Text
            name='search'
            label='Search...'
            icon={{ symbol:'Search', library:'general' }}
            onChange={({ search }) => setFilter(search)}
            className={CSS.search}
          />
        </div>

        <div className={CSS.scroller}>
          { availableSymbolOptions.length > 0 ? (
            <OptionList
              name='icon'
              options={availableSymbolOptions}
              size='square'
              className={CSS.symbolList}
              value={selected.symbol}
              isControlled={true}
              onChange={({ icon }) => setSelected(icon)}
            />
          ) : (
            <EmptyContent />
          )}
        </div>
      </ModalContent>

      <ModalActions>
        <Button
          text='Cancel'
          color='secondary'
          onClick={() => Modal.close()}
        />
        <Button
          text='Choose'
          color='primary'
          onClick={submit}
          disabled={selected.symbol == null}
        />
      </ModalActions>

    </>
  );
};
