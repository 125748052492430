import React from 'react';
import classNames from 'classnames';

import { Icon } from '../../../sondheim/components/Icon';

import CSS from './Notebox.module.scss';

export const Notebox = ({ heading, body, icon, color='blue', actions, className='', style }) => {
  return (
    <div className={classNames(CSS.note_box, CSS[color], className)} style={style}>
      {icon?.symbol && (
        <Icon
          symbol={icon?.symbol}
          library={icon?.library}
          color={icon?.color ?? color}
          className={CSS.note_icon}
          size='20px'
        />
      )}
      <div className={CSS.note_content}>
        { heading && (<h6 className={CSS.note_heading}>{ heading }</h6>)}
        { body && (<div className={CSS.note_copy}>{ body }</div>)}
      </div>
      { actions && <div className={CSS.actions}>{actions}</div>}
    </div>
  );
};
