import React from 'react';
import classNames from 'classnames';

import fileIcon from './images/file.svg';
import CSS from './FileTypeIcon.module.scss';

const EXTENSIONS = {
  doc: '#3e74da',
  docx: '#3e74da',
  odt: '#3e74da',
  pdf: '#d47373',
  rtf: '#656ecd',
  tex: '#5a5a5b',
  txt: '#5a5a5b',
  pptx: '#e07066',
  ppt: '#e07066',
  mp3: '#eab456',
  mp4: '#f676a6',
  xls: '#3f9e64',
  html: '#2988f0',
  htm: '#2988f0',
  png: '#f676a6',
  jpg: '#f67676',
  jpeg: '#f67676',
  gif: '#f6af76',
  zip: '#4f566f',
  rar: '#4f566f',
  exe: '#e26f6f',
  svg: '#bf5252',
  key: '#e07066',
  sketch: '#df821c',
  ai: '#df821c',
  psd: '#388ae5',
  dmg: '#e26f6f',
  json: '#2988f0',
  csv: '#3f9e64'
};

export const FileTypeIcon = ({ type, className, size = 50 }) => {
  if(!type) return null;
  return (
    <div className={classNames(CSS.file,className)} style={{ color: EXTENSIONS[type.toLowerCase()], height: `${size}px`, width: `${size}px` }} data-extension={type}>
      <span style={{ fontSize: `${parseInt(size/5)}px`}}>{type}</span>
      <svg
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
        role="img" alt="file"
        style={{ height: `${size}px`, width: `${size}px` }}
      >
        <use xlinkHref={`${fileIcon}#file`} />
      </svg>
    </div>
  );
};
