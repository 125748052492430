import { ReactComponent as AnimalsLibrary } from './animals.svg';
import { ReactComponent as ClothesLibrary } from './clothes.svg';
import { ReactComponent as CodeLibrary } from './code.svg';
import { ReactComponent as CommunicationLibrary } from './communication.svg';
import { ReactComponent as CookingLibrary } from './cooking.svg';
import { ReactComponent as CreditCardsLibrary } from './credit-cards.svg';
import { ReactComponent as DesignLibrary } from './design.svg';
import { ReactComponent as DevicesLibrary } from './devices.svg';
import { ReactComponent as ElectricLibrary } from './electric.svg';
import { ReactComponent as FilesLibrary } from './files.svg';
import { ReactComponent as FoodLibrary } from './food.svg';
import { ReactComponent as GeneralLibrary } from './general.svg';
import { ReactComponent as HomeLibrary } from './home.svg';
import { ReactComponent as LayoutLibrary } from './layout.svg';
import { ReactComponent as MapLibrary } from './map.svg';
import { ReactComponent as MediaLibrary } from './media.svg';
import { ReactComponent as NavigationLibrary } from './navigation.svg';
import { ReactComponent as NumberLibrary } from './number.svg';
import { ReactComponent as PeopleLibrary } from './people.svg';
import { ReactComponent as ShoppingLibrary } from './shopping.svg';
import { ReactComponent as SocialLibrary } from './social.svg';
import { ReactComponent as TaskLibrary } from './task.svg';
import { ReactComponent as TextLibrary } from './text.svg';
import { ReactComponent as ThirdPartyLibrary } from './third-party.svg';
import { ReactComponent as VehiclesLibrary } from './vehicles.svg';
import { ReactComponent as WeatherLibrary } from './weather.svg';

const libraries = [
  { name: 'animals', Component: AnimalsLibrary, label: 'Animals' },
  { name: 'clothes', Component: ClothesLibrary, label: 'Clothes' },
  { name: 'code', Component: CodeLibrary, label: 'Code' },
  { name: 'communication', Component: CommunicationLibrary, label: 'Communication' },
  { name: 'cooking', Component: CookingLibrary, label: 'Cooking' },
  { name: 'credit-cards', Component: CreditCardsLibrary, label: 'Credit Cards' },
  { name: 'design', Component: DesignLibrary, label: 'Design' },
  { name: 'devices', Component: DevicesLibrary, label: 'Devices' },
  { name: 'electric', Component: ElectricLibrary, label: 'Electric' },
  { name: 'files', Component: FilesLibrary, label: 'Files' },
  { name: 'food', Component: FoodLibrary, label: 'Food' },
  { name: 'general', Component: GeneralLibrary, label: 'General' },
  { name: 'home', Component: HomeLibrary, label: 'Home' },
  { name: 'layout', Component: LayoutLibrary, label: 'Layout' },
  { name: 'map', Component: MapLibrary, label: 'Map' },
  { name: 'media', Component: MediaLibrary, label: 'Media' },
  { name: 'navigation', Component: NavigationLibrary, label: 'Navigation' },
  { name: 'number', Component: NumberLibrary, label: 'Number' },
  { name: 'people', Component: PeopleLibrary, label: 'People' },
  { name: 'shopping', Component: ShoppingLibrary, label: 'Shopping' },
  { name: 'social', Component: SocialLibrary, label: 'Social' },
  { name: 'task', Component: TaskLibrary, label: 'Task' },
  { name: 'text', Component: TextLibrary, label: 'Text' },
  { name: 'third-party', Component: ThirdPartyLibrary, label: 'Third Party' },
  { name: 'vehicles', Component: VehiclesLibrary, label: 'Vehicles' },
  { name: 'weather', Component: WeatherLibrary, label: 'Weather' },
];

export { libraries };
