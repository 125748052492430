import moment from 'moment'
import { get } from 'lodash';

const logicTest = (logic, data) => {
  data = data || {};
  let compareValue = get(data,get(logic,'compare.field'));

  switch(logic.boolean) {
    case 'eq':
      compareValue = compareValue !== undefined ? compareValue : false;
      return compareValue === logic.value;
    case 'lte':
      compareValue = compareValue !== undefined ? compareValue : 0;
      return compareValue <= logic.value;
    case 'gte':
      compareValue = compareValue !== undefined ? compareValue : 0;
      return compareValue >= logic.value;
    case 'lt':
      compareValue = compareValue !== undefined ? compareValue : 0;
      return compareValue < logic.value;
    case 'gt':
      compareValue = compareValue !== undefined ? compareValue : 0;
      return compareValue > logic.value;
    case 'not':
      compareValue = compareValue !== undefined ? compareValue : false;
      return compareValue !== logic.value;
    case 'in':
      compareValue = compareValue !== undefined ? compareValue : false;
      return logic.value.includes(compareValue);
    case '!in':
      compareValue = compareValue !== undefined ? compareValue : false;
      return !logic.value.includes(compareValue);
    default:
      return false;
  }
};

const formatSaveValue = (field) => {
  switch(field.type) {
    case 'date':
      return moment(field.value).format('YYYY-MM-DD');
    case 'address':
    case 'complete-address':
      return null;
    case 'inventory':
    case 'checkout-item':
      return field.value || 0;
    default:
      return field.value;
  }
};

//Field service functions
export const fieldService = {

  hydrate: ( fields=[], data={} ) => {
    fields.forEach((field, index) => {
      field.value = get(data,field.name,field.default);
    });
    return fields;
  },

  isHidden: (field,data) => {
    if(field.hidden) return true; // support component render based control!
    if(!field.custom || !field.custom.hidden) return false;
    return logicTest(field.custom.hidden,data);
  },

  isDisabled: (field,data) => {
    if(!field.custom || !field.custom.disabled) return false;
    var disable = logicTest(field.custom.disabled,data);
    if(disable && field.value) {
      field.value = null;
      if(field.onChange) field.onChange();
    }
    return disable;
  },

  isReadOnly: (field,data) => {
    if(!field.custom || !field.custom.readOnly) return false;
    const readOnly = logicTest(field.custom.readOnly,data);
    return readOnly;
  },

  isRequired: (field,data) => {
    if(data && (fieldService.isDisabled(field,data) || fieldService.isHidden(field,data))) return false;
    if(!field.custom || !field.custom.required) return field.required;
    return logicTest(field.custom.required,data);
  },

  hasRemoteValidation: (field,data)  => {
    if(data && (fieldService.isDisabled(field,data) || fieldService.isHidden(field,data))) return false;
    if(!field.custom || !field.custom.remoteValidation) return !!field.remoteValidation;
    return logicTest(field.custom.remoteValidation,data);
  },

  addDefaults: (field,data) => {
    if(!field.isRequired) field.isRequired = () => fieldService.isRequired(field,data);
    if(!field.isHidden) field.isHidden = () => fieldService.isHidden(field,data);
    if(!field.isDisabled) field.isDisabled = () => fieldService.isDisabled(field,data);
    if(!field.isReadOnly) field.isReadOnly = () => fieldService.isReadOnly(field,data);
    field.default = field.default || fieldService.getDefault(field, data);
  },

  getDefault: (field,data) => {
    if(field.default) return field.default;
    if(!field.custom || !field.custom.default || !field.custom.default.field) return;
    let options = field.custom.default;
    return data[options.field];
  },

  buildSaveData: (field,data) => {
    switch(field.model) {
      case 'user':
        data.user = data.user || {};
        Object.assign(data.user,{[field.name]:formatSaveValue(field)});
        break;
      default:
        data.task = data.task || {};
        switch(field.type) {
          case 'date':
            data.task.dates = data.task.dates || [];
            data.task.dates.push({name: field.name, date:formatSaveValue(field)});
            break;
          case 'address':
          case 'complete-address':
            data.task.addresses = data.task.addresses || [];
            data.task.addresses.push(formatSaveValue(field));
            break;
          case 'inventory':
            data.task.inventories = data.task.inventories || [];
            data.task.inventories.push({name: field.name, count:formatSaveValue(field)});
            break;
          default:
            data.task.data = data.task.data || {};
            if(field.parent) Object.assign(data.task.data,{[field.parent]:{[field.name]:formatSaveValue(field)}});
            else Object.assign(data.task.data,{[field.name]:formatSaveValue(field)});
            break;
        }
        break;
    }
    return data;
  },

};
