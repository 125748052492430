import React from 'react';
import { Chart as ChartJS, defaults } from 'chart.js/auto';// eslint-disable-line
import { Chart as ReactChart } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import { merge } from 'lodash';

// apply styling defaults
defaults.font = {
  ...defaults.font,
  family: '"circular", "Roboto", sans-serif',
};

export const Chart = ({ data, options }) => {
  // default options
  const chartOptions = merge(
    {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false,
      },
    },
    options
  );
  return <ReactChart options={chartOptions} data={data} />;
};
