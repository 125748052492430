import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopperTooltip } from 'react-popper-tooltip';
import classNames from 'classnames';
import { merge } from 'lodash';
import { useScroller } from '@moved/services';

import popCSS from './PopOver.module.scss';

export const PopOver = (props) => {
  const {
    // Trigger Properties
    children,         // the object to be wrapped in the trigger for the popover
    stopPropagation,  // clicks on the trigger will not propagate to the parents
    hideOnScroll,     // auto hide the tooltip if the parent is scrolled
    onShow,           // callback function when popover is shown
    onHide,           // callback function when popover is hidden
    mountToBody,      // use portal to put the tooltip on the body element
    // Tooltip Properties
    tooltip,          // the component or function to render the popover contents
    hideArrow,        // don't render the arrow element
    customClass,      // add custom class to popover container
    customArrowClass, // add custom class to arrow element
    // dark,             // (deprecated) use sondheim tooltip instead
    // Popper Config Properties
    ...options        // all remaining props are passed through to the Popper Tooltip Library
                      // reference: https://www.npmjs.com/package/react-popper-tooltip
  } = props;

  const { ref } = useScroller();

  const [controlledVisible, setControlledVisible] = useState(false);
  const hide = () => setControlledVisible(false);

  const config = merge({
    placement: 'top',
    interactive: true,
    delayHide: 300,
  }, options);

  // Allow control of visibility
  config.visible = controlledVisible;
  config.onVisibleChange = (isVisible) => {
    if(isVisible && onShow) onShow();
    if(!isVisible && onHide) onHide();
    setControlledVisible(isVisible);
  };

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip(config);


  // use hideOnScroll prop to hide the tooltip when scrolling
  useEffect(() => {
    if (hideOnScroll && ref.current) {
      const scrollParent = ref.current; // needed so the ref still exists on cleanup
      scrollParent.addEventListener('scroll', hide);
      return () => scrollParent.removeEventListener('scroll', hide);
    }
  }, [hideOnScroll, ref]);

  const handlePrevent = (event) => {
    if(stopPropagation) event.stopPropagation();
  };

  const tooltipElement = (
    <div
      ref={setTooltipRef}
      onClick={handlePrevent}
      {...getTooltipProps({ className: classNames(popCSS.container, {[customClass]: customClass})})}
    >
      { typeof tooltip === "function" ? tooltip(hide) : tooltip }
      {!hideArrow && (
        <div {...getArrowProps({ className: classNames(popCSS.arrow, {[customArrowClass]: customArrowClass})})} />
      )}
    </div>
  );

  return (
    <>
      <div onClick={handlePrevent} ref={setTriggerRef} className={classNames(popCSS.trigger,props.className)}>
        { typeof children === "function" ? children(visible,hide) : children }
      </div>
      { visible && (
        mountToBody ? ReactDOM.createPortal(tooltipElement, document.body) : tooltipElement
      )}
    </>
  );
};
