import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '../../../sondheim/components/Icon';
import CSS from './FieldList.module.scss';

const defaultEmptyContent = 'No details provided.';

export const FieldList = ({ list, className, emptyContent=defaultEmptyContent }) => {
  // hide rows where the value is undefined
  const fields = list.filter(field => field.isTitle || field.value != null);
  // if any rows have an icon, all rows should get the icon padding
  const showIcons = fields.find(field => !!field.icon);

  return (
    <div className={classNames(CSS.list, className)}>
      { fields.length === 0  ? (
        // if now rows are visible, return the emptyContent
        emptyContent && (
          <div className={'contentSecondary marginTop-8'}>{emptyContent}</div>
        )
      ) : (
        fields.map((field,index) => (
          /*
          *  All fields should be objects with the following shape:
          *  {
          *     label: Content displayed on the left side of the row,
          *     value: Content to be displayed on the right side of the row,
          *     icon: (optional) object of the icon library and symbol to display before the label,
          *     className: (optional) classname to apply to the field row
          *     isTitle: (optional) when set this row doesn't display a value and spans the full width even if the list is `half`
          *     hideDivider: (optional) when set this row doesn't get styled with extra padding and a bottom border
          *  }
          */
          <dl
            className={classNames(
              CSS.row,
              field.className,
              {
                [CSS.icon]: showIcons,
                [CSS.hide_divider]: field.hideDivider,
              }
            )}
            key={`${field.label}-${index}`}
          >
            <dt className={classNames('contentSecondary',CSS.label)}>
              { showIcons && (
                field?.icon?.symbol ? (
                  <Icon
                    symbol={field.icon.symbol}
                    library={field.icon.library}
                    color={field.icon.color}
                    size={'24px'}
                    className={'mr-15'}
                  />
                ) : (
                  <div className={CSS.icon_padding}/>
                )
              )}
              { field.label }
            </dt>
            { !field.isTitle && (
              <dd className={classNames('contentPrimary',CSS.value)}>
                { field.value }
              </dd>
            )}
          </dl>
        ))
      )}
    </div>
  )
};

FieldList.propTypes = {
  /** The array of fields in the list */
  list: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.node,
    value: PropTypes.node,
    icon: PropTypes.shape({
      symbol: PropTypes.string,
      library: PropTypes.string,
    }),
    className: PropTypes.string,
    isTitle: PropTypes.bool,
    hideDivider: PropTypes.bool,
  })).isRequired,
  /** (Optional) Custom class name to add to the list */
  className: PropTypes.string,
  /** (Optional) Content to render if the list is empty */
  emptyContent: PropTypes.node,
};
