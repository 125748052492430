import canvasSize from 'canvas-size';
import { getBlob } from './getBlob';

// Scale down size if device can't support canvas that large
const getLimitScalar = (width, height, maximumPixels) => {
  const requiredPixels = width * height;
  if (requiredPixels <= maximumPixels) return 1;
  const scalar = Math.sqrt(maximumPixels) / Math.sqrt(requiredPixels);
  return scalar;
};

export const crop = async (image, crop, fileType='image/jpeg') => {
  const canvas = document.createElement("canvas");

  const scaleX = image.naturalWidth / image.width,
        scaleY = image.naturalHeight / image.height,
        pixelRatio = window.devicePixelRatio || 1,
        ctx = canvas.getContext("2d");

  if (!ctx) throw new Error('No 2d context');

  // scaled width and height of the image based on
  // screen pixel ratio and image file vs element rendered
  const scaledWidth = crop.width * pixelRatio * scaleX;
  const scaledHeight = crop.height * pixelRatio * scaleY;

  let maxSize = window.localStorage.getItem('canvasMax');
  // Check max canvas size for browser + device combo
  // if not in local storage
  if(!maxSize) {
    maxSize = await canvasSize.maxArea({
      usePromise: true
    })
    .then(result => result.width * result.height)
    .catch(() => 16777216);

    window.localStorage.setItem('canvasMax', maxSize);
  }

  // Get scalar to scale down to fit max size
  const deviceScalar = getLimitScalar(scaledWidth, scaledHeight, maxSize || 16777216);

  canvas.width = scaledWidth * deviceScalar;
  canvas.height = scaledHeight * deviceScalar;

  // Scale the canvas
  ctx.scale(pixelRatio * deviceScalar, pixelRatio * deviceScalar);
  ctx.imageSmoothingQuality = "high";
  ctx.save();
  ctx.drawImage(
    image,
    //source
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    //destination
    0,
    0,
    crop.width * scaleX,
    crop.height * scaleY
  );
  ctx.restore();
  return await getBlob(canvas, fileType);
};
