/*
* Custom hook for invoking modal instances leveraging the overlay
*
* `useModal` provides the following functions:
*   open, close
*
*   open: (contents, options)
*     contents:           valid jsx
*     options:            configuration object
*       onClose(result):  (function) callback function on modal close that recieves
*                         the result provided by the modal if it sends one (undefined if dismissed)
*       (▼ deprecated on new modals, but still functional)
*       hideCloseButton:  (boolean) to disable the default close button icon
*       customStyles:     (style object) custom styles to apply to the modal container
*/

import React from "react";
import { Modal, SondheimModal, GlassModal } from '@moved/ui';

import { useOverlay, useLayer } from '../contexts/OverlayContext';

export const useModal = () => {
  const Overlay = useOverlay();
  const Layer = useLayer();

  // define the modal wrapper component and then insert it into the next layer
  const open = (contents, options={}) => {
    const ActiveModal = (
      options.sondheim ? SondheimModal :
      options.glass ? GlassModal :
      Modal
    );
    return Overlay.open((
      <ActiveModal
        options={options}
        children={contents}
      />
    ),options);
  };

  // if invoking useModal inside of a modal layer, close will close THIS layer
  // if invoking useModal outside of a modal layer, close will close the TOP layer
  const close = Layer ? Layer.close : Overlay.closeTop;

  return { open, close, layer:Layer };
};
