import moment from 'moment-timezone';

import globals from '../../globals/'

export const date = (date, format = 'date', timezone, parser) => {
  if(!date) return null;
  // if the format is a custom shorthand, get the full moment format from the globals map
  const formatString = globals.format[format] ? globals.format[format] : format;
  // convert the date to a moment object
  let  momentDate = moment(date, parser);
  // if there is a known timezone ensure we are using it
  if(timezone) momentDate = momentDate.tz(timezone);
  // handle special case 'relative' format which uses a different moment function to generate its output
  if(formatString === 'relative') return momentDate.fromNow();
  // apply the formatter and return the result
  return momentDate.format(formatString);
};
