import Paragraph from '@editorjs/paragraph';
import { get } from 'lodash';

import { format } from '@moved/services';

import icon from './icon.svg';

export class Text extends Paragraph {

  constructor({ data, config, api, readOnly, block }) {
    super({ data, config, api, readOnly });
    this.block = block;
    this._placeholder = get(config,'placeholder','Enter text...');

    this.data = {
      text: data.text || '',
      color: data.color || 'secondary',
    };

    this._CSS = { block: 'labelM', wrapper: 'labelM' }; // override to avoid unwanted global styles
  }

  static get toolbox() {
    return {
      title: 'Text',
      icon: `<img src="${icon}" alt="Text" height="16" width="22"/>`,
    };
  }

  // render the tool inside the editor
  render() {
    // this is a custom wrapper that depends on functionality deep inside paragraph
    this._element = this.drawView();
    this._element.classList.add(`content${format.capitalize(this._data.color)}`); // custom code to add the color styles
    return this._element;
  }

  save(wrapper) {
    return {
      ...this.data,
      text: wrapper.innerHTML,
    };
  }

  renderSettings() {
    return [{
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.504 3.2561H12.466L17.956 16.0001H15.364L14.176 13.0841H8.64996L7.49796 16.0001H4.95996L10.504 3.2561ZM13.384 11.1401L11.422 5.9561L9.42396 11.1401H13.384Z" fill="#151618"/>
        <rect x="3" y="19" width="17" height="2" rx="1" fill="#C2C5C9"/>
      </svg>`,
      label: 'Switch color',
      onActivate: () => this._toggleColor(),
    }];
  }

  _toggleColor() {
    this.data = {
      ...this.data,
      color: this.data.color === 'primary' ? 'secondary' : 'primary',
    };
    // update the tool markup because it does not react to the data change
    const tool = this._element;
    if(tool) tool.parentNode.replaceChild(this.render(), tool);
  }

  static get conversionConfig() {
    return {
      export: 'text', // to convert Text to other block, use 'text' property of saved data
      import: 'text', // to covert other block's exported string to Text, fill 'text' property of tool data
    };
  }

}
