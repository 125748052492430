import React from 'react';
import { get } from 'lodash';

import { globals } from '@moved/services';

export const CustomerSupportEmailLink = ({ className }) => {
  const supportEmail = get(globals,'emails.customerSupport');
  return (
    <a
      href={`mailto:${supportEmail}`}
      target='_blank'
      rel='noopener noreferrer'
      className={className}
    >
      {supportEmail}
    </a>
  );
};
