import { FieldProps } from "formik";
import React from "react";
import Select from "react-select";
import { OptionsType, ValueType } from "react-select";

interface Option {
  label: string;
  value: string;
}

interface CustomSelectProps extends FieldProps {
  options: OptionsType<Option>;
  isMulti?: boolean;
}

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    flex: '1 1 auto',
    fontSize: '14px',
  }),
  control: (provided, state) => ({
    ...provided,
    borderWidth: 0,
    backgroundColor: '#f6f7f8',
    cursor: 'pointer',
    boxShadow: '0 0 0 transparent',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0 15px',
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 200,
    marginTop: '1px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
  }),
  input: (provided, state) => ({
    ...provided,
    margin: '0',
    padding: '0',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
  }),
};

const underlineStyles = {
  container: (provided, state) => ({
    ...provided,
    flex: '1 1 auto',
    fontSize: '14px',
  }),
  control: (provided, state) => ({
    ...provided,
    borderWidth: '1px',
    borderColor: 'transparent transparent #c5c9cf transparent',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    boxShadow: '0 0 0 transparent',
    borderRadius: '3px 3px 0 0',
    '&:hover': {
      borderColor: 'transparent transparent #8b949f transparent',
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '0',
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 200,
    marginTop: '1px',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none',
  }),
  input: (provided, state) => ({
    ...provided,
    height: '46px',
    margin: '0',
    padding: '0',
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#8b949f',
  }),
};

export const CustomSelect = ({
  field,
  label,
  form,
  options,
  formStyle,
  className,
  isMulti = false,
  onChange,
  placeholder,
  readOnly,
}: CustomSelectProps) => {
  const selectChange = (option: ValueType<Option | Option[]>) => {
    onChange(option, form.setFieldValue, field.name);
    form.setFieldValue(
      field.name,
      isMulti
        ? option ? option.map((item: Option) => item.value) : []
        : option.value
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(option => field.value.indexOf(option.value) >= 0)
        : options.find(option => option.value === field.value);
    } else {
      return isMulti ? [] : "";
    }
  };
  return (
    <Select
      name={field.name}
      value={getValue()}
      onChange={selectChange}
      options={options}
      isMulti={isMulti}
      isDisabled={readOnly}
      styles={formStyle === 'underline' ? underlineStyles : customStyles}
      className={className}
      inputId={field.name}
      placeholder={placeholder}
    />
  );
};
