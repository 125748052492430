import React from 'react';
export const fileList = files => {
  if(!Array.isArray(files)) return null;
  return files.map((file, index, files) => (
    <span key={file}>
      <a href={file} target="_blank" rel="noopener noreferrer">View file {index+1}</a>
      {index+1 < files.length && (<>,&nbsp;</>)}
    </span>
  ));
};
