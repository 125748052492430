import React from 'react';
import { get } from 'lodash';

export const getRequestFields = request => {
  if(!request) return [];
  return [
    ...request.files.map(file => {
      const symbol = get(file,'file_requirement.icon');
      return {
      label: get(file,'file_requirement.display_name'),
      value: (
        <span>
          <a href={file.file_url} target="_blank" rel="noopener noreferrer">View file</a>
        </span>
      ),
      icon: symbol ? { symbol } : { symbol: 'Uploaded-file', library: 'files' },
    }}),
    {
      label: 'Reason for decline',
      value: request?.notes,
      icon: { symbol: 'File', library: 'files' },
    },
  ]
};
