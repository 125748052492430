import React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import { get } from 'lodash';

import { InputWrapper } from '../InputWrapper';
import { SlideToggle } from '../../SlideToggle';
import formCSS from '../StyledForm.module.scss';


export const FieldSlideToggle = (props) => {

  const { input, validate } = props;

  const selectOption = (field, form, option) => {
    if(input.onChange) input.onChange(option, form.setFieldValue, input.name);
    return form.setFieldValue(field.name, option);
  }

  return (
    <InputWrapper {...props}>
      <Field
        name={input.name}
        validate={validate}
      >
        {(props) => {
          const { field, form } = props;
          return (
            <div className={classNames(formCSS.slide_container, { [formCSS.full]: get(input, 'toggleProps.full') })}>
              <SlideToggle
                active={field.value}
                options={input.options}
                callback={selectOption.bind(this, field, form)}
                {...input.toggleProps}
              />
            </div>
          );
        }}
      </Field>
    </InputWrapper>
  );
}
