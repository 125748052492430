import React from 'react';
import { createRoot } from 'react-dom/client';
import { get, merge } from 'lodash';

import { BaseTool } from '../BaseTool';
import { DefinitionEditor } from './DefinitionEditor';

import CSS from './Definition.module.scss';
import icon from './icon.svg';

export class Definition extends BaseTool {
  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      title: 'Definition',
      icon: `<img src="${icon}" alt="Definition" height="24" width="24"/>`,
    }
  }

  /**
   * Allow Definition to be converted to/from other blocks
   */
  static get conversionConfig() {
    return {
      // To create Definition data from string, simple fill 'content' property
      import: 'content',
      /**
       * To create string from Quote data, concatenate text and caption
       *
       * @param {object} data
       * @returns {string}
       */
      export: function (data) {
        return `<strong>${data.title}</strong> ${data.content}`;
      },
    };
  }

  /**
   * Sanitizer rules
   */
  static get sanitize() {
    return {
      title: {},
      content: { br: true },
    };
  }

  /**
   * Tool`s styles
   *
   * @returns {{baseClass: string, wrapper: string, title: string, content: string}}
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      wrapper: CSS.editor,
      title: CSS.title || 'title',
      content: CSS.content || 'content',
      icon: CSS.icon || 'icon',
    };
  }

  get initialIcon() {
    return { library: 'code', symbol: 'Stop' };
  }

  /**
   * Construct class base data
   *
   * @param {RawData} data — previously saved HTML data
   * @param {object} config - user config for Tool
   * @param {object} api - CodeX Editor API
   * @param {boolean} readOnly - read-only mode flag
   * @param {object} block = Editor Block API
   */
  constructor({ data, config, api, readOnly, block }) {
    super({ api, readOnly });
    this.block = block;

    this.placeholders = merge(
      {
        title: 'Enter title...',
        content: 'Enter content...',
      },
      get(config,'placeholders',{}),
    );

    this._elements = {};

    this._data = {
      showHeading: true,
      title: '',
      content: '',
      icon: this.initialIcon,
    }

    this.data = data;

  }

  // render the tool inside the editor
  render() {
    this._elements.wrapper = document.createElement('div');
    const root = createRoot(this._elements.wrapper);
    root.render(<DefinitionEditor tool={this}/>);
    return this._elements.wrapper;
  }

  // format the data when saving
  save() {
    if(this._data.showHeading) {
      this._data.title = this._elements.wrapper.querySelector(`.${CSS.title}`).innerHTML;
    }
    this._data.content = this._elements.wrapper.querySelector(`.${CSS.content}`).innerHTML;
    return this._data;
  }

  renderSettings() {
    return [
      {
        name: 'heading',
        label: 'With heading',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="0">
          <rect x="4" y="5" width="16" height="3" rx="1.5" fill="#151618"/>
          <path fill-rule="evenodd" clip-rule="evenodd" fill="#C2C5C9" d="M5.5 10C4.67157 10 4 10.6716 4 11.5C4 12.3284 4.67157 13 5.5 13H12.5C13.3284 13 14 12.3284 14 11.5C14 10.6716 13.3284 10 12.5 10H5.5ZM5.5 15C4.67157 15 4 15.6716 4 16.5C4 17.3284 4.67157 18 5.5 18H18.5C19.3284 18 20 17.3284 20 16.5C20 15.6716 19.3284 15 18.5 15H5.5Z"/>
        </svg>`,
        isActive: this._data.showHeading,
        closeOnActivate: true,
        onActivate: () => this.toggleTune({ showHeading: !this._data.showHeading }),
      },
    ]
  }

  /**
   * Update the data, trigger an onchange
   */
  toggleTune(data) {
    this.save();
    this.data = data;
    this.block.dispatchChange();
  }

  /**
   * data setter
   */
  set data(newData) {
    // save the data internally (persist unchanged values)
    this._data = {
      ...this._data,
      ...newData,
    };
    // trigger a re-render
    const wrapper = this._elements.wrapper;
    if(wrapper) wrapper.parentNode.replaceChild(this.render(), wrapper);
  }

  /**
   * data getter
   */
  get data() {
    return this._data;
  }


}
