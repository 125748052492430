
import React, { useState } from 'react';
import classNames from 'classnames';

import CSS from './List.module.scss';

export const ListEditor = ({ tool }) => {
  const { items: initialItems, heading, style, columns, showHeading } = tool.data;
  const [items] = useState(initialItems); // decouple the rendering from the parent state
  const WrapperTag = style === 'unordered' ? 'ul' : 'ol';
  const handleKeyDown = (event) => {
    if(['ArrowUp','ArrowDown','ArrowLeft','ArrowRight'].includes(event.key) || tool.readOnly) return;
    event.stopPropagation(); // stops editorjs from interfering
    switch (event.key) {
      case 'Enter':
        tool.handleEnter(event);
        break;
      case 'Backspace':
        tool.handleBackspace(event);
        break;
      default:
        return;
    }
  };

  const handleOnChange = (event) => tool.save();

  return (
    <>
      { showHeading && (
        <div
          className={CSS.heading}
          dangerouslySetInnerHTML={{ __html: heading }}
          data-placeholder='Enter list heading...'
          contentEditable={!tool.readOnly}
          suppressContentEditableWarning={true}
          tabIndex={0}
        />
      )}
      <WrapperTag
        className={classNames(CSS.wrapper,CSS[`columns-${columns}`])}
        contentEditable={!tool.readOnly}
        suppressContentEditableWarning={true}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onChange={handleOnChange}
      >
        { items.length > 0 ?
          items.map((item,index) => (
            <li
              key={index}
              className={CSS.item}
              dangerouslySetInnerHTML={{ __html: item }} />
          )) : (
            <li className={CSS.item}/>
          )
        }
      </WrapperTag>
    </>
  );
};
