import React, { useEffect, useMemo } from 'react';
import { get } from 'lodash';

import { useUser, useBreadcrumbs } from '@moved/services';

import { RenderRoutes } from './RenderRoutes';

const formatRouteTree = (routeTree=[], defaultDomains, hasDomain) => {
  return routeTree
    // handle route defaults and conveniences
    .map(route => {
      if(!route.restrict) route.restrict = [...defaultDomains];
      if(route.allowGuest === true && !route.restrict.includes('guest')) route.restrict.push('guest');
      return route;
    })
    // remove invalid routes
    .filter(route => route.component || route.redirect || (route.children && route.children.length > 0))
    // remove routes that can't be accessed
    .filter(route => get(route,'restrict',[]).find(domain => hasDomain(domain)))
    // recurse to filter children routes
    .map(route => {
      if(route.children) route.children = formatRouteTree(route.children, defaultDomains, hasDomain);
      return route;
    });
};

export const RouteTree = ({ routeTree, defaultDomains=[], basePath=''}) => {
  const { hasDomain } = useUser();
  const { setRoutes } = useBreadcrumbs();
  const routes = useMemo(
    () => formatRouteTree(routeTree, defaultDomains, hasDomain),
    [routeTree, defaultDomains.join(','), hasDomain]
  );

  useEffect(
    () => setRoutes(routes),
    [routes]
  );

  return (
    <RenderRoutes routes={routes} basePath={basePath} />
  );
}
