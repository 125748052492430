import React, { useState, useEffect, Fragment } from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import { get } from 'lodash';

import CSS from '../StyledForm.module.scss';
import { InputWrapper } from '../InputWrapper';

// Render component for each item
const Pill = ({ option, selected, itemChange }) => {
  return (
    <div
      className={classNames(CSS.pill, { [CSS.disabled_item]: option.disabled, [CSS.pill_active]: selected })}
    >
      <div
        id={`item_list_${option.value}`}
        onClick={e => !option.disabled ? itemChange(option) : false}
        className={classNames(CSS.pill_inner)}
      >
        <span className={CSS.item_label}>{option.label}</span>
      </div>
    </div>
  )
};

// Item List Component (container component and maintains active state/field value)
const ItemList = ({
  field,
  input,
  form,
  options,
  isMulti = false,
  isNumeric = false,
  customItemClass,
  onChange,
  white,
}) => {

  // Get the initial set of values to use for the initial active list
  const getActiveList = () => {
    let list = {};
    options.forEach(opt => {
      list[opt.value] = isMulti
        ? field.value.indexOf(opt.value) >= 0
        : field.value === opt.value;
    });
    return list;
  }

  // Set the initial active list
  const [activeList, setActiveList] = useState(getActiveList());

  useEffect(() => {
    // Update initial active list if additional options are added
    setActiveList(getActiveList());
  // eslint-disable-next-line
  },[options.length]); // run when new options are added

  //function to toggle active list value and return a field value to be set
  const toggleListItem = (option) => {
    let newList = Object.assign({}, activeList);
    const oldValue = newList[option.value];

    //Set everything to false if single select
    if(!isMulti) {
      for(let opt in newList) {
        newList[opt] = false;
      }
    }

    // Reverse previous value
    newList[option.value] = !oldValue;

    setActiveList(newList);

    // Return array if multiselect
    if(isMulti) {
      let arr = [];
      for(let opt in newList) {
        if(newList[opt]) arr.push(isNumeric ? parseInt(opt) : opt);
      }
      return arr;
    }

    // Return value if single select (empty string if none selected)
    for(let opt in newList) {
      if(newList[opt]){
        // If value is "true" or "false" convert to boolean
        if(opt === "true" || opt === "false") {
          return opt === "true";
        }
        return opt;
      }
    }
    return '';
  };

  //change handler
  const itemChange = (option) => {
    onChange(option, form.setFieldValue, field.name);

    return form.setFieldValue(field.name, toggleListItem(option));
  };

  const { errors, touched } = form;
  return (
    <div
      className={classNames(
        CSS.pills,
        {[CSS.has_error]: get(errors, field.name) && get(touched, field.name)}
      )}
    >
      {options.map((option, index) => {
        return (
          <Fragment key={JSON.stringify(option.value)}>
            {option.divide && (<div className={CSS.item_divider} key={"divide" + JSON.stringify(option.value)} />)}
            <Pill
              key={JSON.stringify(option.value)}
              selected={activeList[option.value]}
              option={option}
              itemChange={itemChange}
              isMulti={isMulti}
              customItemClass={customItemClass}
              white={white}
            />
          </Fragment>
        );
      })}
      <input type="hidden" value={field.value} name={field.name} />
    </div>
  );
};

// Field wrapper component
export const FieldPills = (props) => {
  const { input, validate } = props;

  const onChange = (option, setFieldValue, name) => {
    const { onChange } = input;
    //Passing up the onchange event for use in parent
    onChange && onChange(option, setFieldValue, name);
    return;
  };

  return (
    <InputWrapper {...props}>
      <div className={CSS.pill_field}>
        <Field
          name={input.name}
          validate={validate}
          component={ItemList}
          options={input.options}
          isMulti={input.isMulti}
          isNumeric={input.isNumeric}
          onChange={onChange}
          readOnly={input.readOnly}
          customItemClass={input.customItemClass}
          white={input.white}
        />
      </div>
    </InputWrapper>
  );
}
