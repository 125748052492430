import React, { useEffect } from 'react';
import CSS from './StyledForm.module.scss';
import { Formik } from 'formik';
import classNames from 'classnames';
import { isFunction, noop } from 'lodash';
import { usePrevious } from 'react-use';

const FormikEffect = ({ onChange = noop, form }) => {
  const { values } = form;
  const prevValues = usePrevious(values);
  useEffect(() => {
    if (prevValues) onChange(values,form); // Don't run effect on form init
  // eslint-disable-next-line
  }, [values]); // only run when values change

  return null;
};

export const StyledForm = (props) => {
  const {id, formStyle, className, children} = props;
  return (
    <Formik
      initialValues={props.initialValues}
      initialTouched={props.initialTouched}
      onSubmit={props.onSubmit}
      validate={props.validate}
      validationSchema={props.validation}
      enableReinitialize={props.reinitialize}
    >
      {formik => {
        return (
          <form
            id={id}
            onSubmit={formik.handleSubmit}
            autoComplete={props.autocomplete}
            className={classNames(
              CSS.form,
              CSS[`style_${formStyle}`],
              className,
            )}
          >
            <FormikEffect onChange={props.onChange} form={formik} />
            <div className={CSS.fields}>
              { isFunction(children) ? children(formik) : children }
            </div>
            <input type="submit" id={`${id}-submit`} style={{ display: 'none'}} disabled={props.disabled} />
          </form>
        );
      }}
    </Formik>
  );
};

StyledForm.defaultProps = {
  formStyle: 'default',
};
