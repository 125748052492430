import React from 'react';
import { Field } from 'formik';
import moment from 'moment'

import formCSS from '../StyledForm.module.scss';
import { InputWrapper } from '../InputWrapper';
import { MonthlyCalendar } from '../../MonthlyCalendar';

// Calendar Component (maintains active state/field value)
const DateSelect = ({
  field,
  form,
  options,
  calProps = {
    boxStyle: true,
    disablePast: true,
  },
  onChange,
}) => {

  //change handler
  const dateChange = (date) => {
    onChange(date, form.setFieldValue, field.name);
    return form.setFieldValue(field.name, moment(date).format('YYYY-MM-DD'), true);
  };

  return (
    <div className={formCSS.date_calendar}>
      <MonthlyCalendar onSelect={dateChange} moment={field.value? moment(field.value) : moment()} selected={field.value} {...calProps} />
      <input type="hidden" value={moment(field.value).format('YYYY-MM-DD')} name={field.name} />
    </div>
  );
};

// Field wrapper component
export const FieldDate = ({ input={}, ...props }) => {
  const onChange = (date, setFieldValue, name) => {
    return input.onChange && input.onChange(date, setFieldValue, name);
  }
  return (
    <InputWrapper input={input} {...props}>
      <Field
        name={input.name}
        component={DateSelect}
        onChange={onChange}
        validate={input.validate}
        readOnly={input.readOnly}
        calProps={input.calProps}
      />
    </InputWrapper>
  );
}
