import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { AtomSpinner } from '../AtomSpinner/AtomSpinner';

import CSS from './LoaderOverlay.module.scss';

export const LoaderOverlay = ({ size=60, darken }) => (
  ReactDOM.createPortal(
    <div className={[CSS.overlay, darken ? CSS.darken : null].join(' ')}>
      <AtomSpinner size={size} white={darken} />
    </div>,
    document.body
  )
);

LoaderOverlay.propTypes = {
  /** Number that determines size of spinner in pixels */
  size: PropTypes.number,
  /** Boolean to determine if background is light or dark */
  darken: PropTypes.bool,
};
