import React, { useState, useEffect, Fragment } from 'react';
import { Field } from 'formik';
import { get } from 'lodash';
import classNames from 'classnames';

import CSS from '../StyledForm.module.scss';
import { InputWrapper } from '../InputWrapper';
import { Icon } from '../../../../sondheim/components/Icon';
import { Collapsible } from '../../Collapsible';

// Render component for each item
const Item = ({ option, selected, itemChange, isMulti, customItemClass, white }) => {
  return (
    <div
      className={classNames(CSS.item_wrapper, { [CSS.disabled_item]: option.disabled })}
    >
      <div
        id={`item_list_${option.value}`}
        onClick={e => !option.disabled ? itemChange(option) : false}
        className={classNames(CSS.item, customItemClass, { [CSS.selected_item]: selected, [CSS.item_large]: option.large, [CSS.white]: white })}
      >
        <div className={CSS.item_content}>
          <div className={CSS.item_labels}>
            { option.icon && (
              <Icon size={option.large ? '40px' : '24px'} symbol={get(option,'icon.symbol')} library={get(option,'icon.library')} className={CSS.item_icon} />
            )}
            { option.customIcon && (
              <div className={CSS.custom_icon}>
                {option.customIcon}
              </div>
            )}
            <div className={CSS.label_wrapper}>
              <span className={CSS.item_label}>{option.label}</span>
              {option.sublabel && (
                <span className={CSS.item_sublabel}>{option.sublabel}</span>
              )}
            </div>
            {option.rightLabel && (
              <span className={CSS.item_rightLabel}>{option.rightLabel}</span>
            )}
          </div>
          {!option.disabled && (
            <span className={CSS.item_check}>
              <Icon size='24px' symbol={isMulti ? 'Checkbox-off' : 'Radio-off'} library={'code'} className={CSS.off} />
              <Icon size='24px' symbol={isMulti ? 'Checkbox-on' : 'Radio-on'} library={'code'} className={CSS.on} />
            </span>
          )}
        </div>
        {option.content && (
          <div className={CSS.item_hidden}>
            {option.content}
          </div>
        )}
        {option.hiddenContent && (
          <Collapsible open={selected}>
            <div className={CSS.item_hidden}>
              {option.hiddenContent}
            </div>
          </Collapsible>
        )}
      </div>
    </div>
  )
};

// Render component for each item
const Square = ({ option, selected, itemChange, customItemClass, white }) => {
  return (
    <div className={classNames(CSS.square_wrapper, customItemClass,)}>
      <div
        onClick={e => itemChange(option)}
        className={classNames(CSS.square, { [CSS.selected_square]: selected, [CSS.square_large]: option.large, [CSS.white]: white })}
      >
        { option.icon && (
          <Icon size='24px' symbol={get(option,'icon.symbol')} library={get(option,'icon.library')} className={CSS.item_icon} />
        )}
        <span className={CSS.square_label}>{option.label}</span>
      </div>
    </div>
  )
};

// Item List Component (container component and maintains active state/field value)
const ItemList = ({
  field,
  form,
  options,
  isMulti = false,
  isTwoCol = false,
  isSquare = false,
  isNumeric = false,
  customItemClass,
  onChange,
  white,
}) => {

  // Get the initial set of values to use for the initial active list
  const getActiveList = () => {
    let list = {};
    options.forEach(opt => {
      list[opt.value] = isMulti
        ? field.value.indexOf(opt.value) >= 0
        : field.value === opt.value;
    });
    return list;
  }

  // Set the initial active list
  const [activeList, setActiveList] = useState(getActiveList());

  useEffect(() => {
    // Update initial active list if additional options are added
    setActiveList(getActiveList());
  // eslint-disable-next-line
  },[options.length]); // run when new options are added

  //function to toggle active list value and return a field value to be set
  const toggleListItem = (option) => {
    let newList = Object.assign({}, activeList);
    const oldValue = newList[option.value];

    //Set everything to false if single select
    if(!isMulti) {
      for(let opt in newList) {
        newList[opt] = false;
      }
    }

    // Reverse previous value
    newList[option.value] = !oldValue;

    setActiveList(newList);

    // Return array if multiselect
    if(isMulti) {
      let arr = [];
      for(let opt in newList) {
        if(newList[opt]) arr.push(isNumeric ? parseInt(opt) : opt);
      }
      return arr;
    }

    // Return value if single select (empty string if none selected)
    for(let opt in newList) {
      if(newList[opt]){
        // If value is "true" or "false" convert to boolean
        if(opt === "true" || opt === "false") {
          return opt === "true";
        }
        return opt;
      }
    }
    return '';
  };

  //change handler
  const itemChange = (option) => {
    onChange(option, form.setFieldValue, field.name);

    return form.setFieldValue(field.name, toggleListItem(option));
  };

  const Element = isSquare ? Square : Item;

  return (
    <div className={classNames(CSS.item_list, { [CSS.two_col]: isTwoCol, [CSS.item_list_square]: isSquare })}>
      {options.map((option, index) => {
        return (
          <Fragment key={JSON.stringify(option.value)}>
            {option.divide && (<div className={CSS.item_divider} key={"divide" + JSON.stringify(option.value)} />)}
            <Element
              key={JSON.stringify(option.value)}
              selected={activeList[option.value]}
              option={option}
              itemChange={itemChange}
              isMulti={isMulti}
              customItemClass={customItemClass}
              white={white}
            />
          </Fragment>
        );
      })}
      <input type="hidden" value={field.value} name={field.name} />
    </div>
  );
};

// Field wrapper component
const FieldItemList = (props) => {
  const { input, validate } = props;

  const [ term, setTerm ] = useState('');
  const [ termList, setTermList ] = useState(input.options);

  const onChange = (option, setFieldValue, name) => {
    const { onChange } = input;
    //Passing up the onchange event for use in parent
    onChange && onChange(option, setFieldValue, name);
    return;
  };

  useEffect(() => {
    setTermList(input.options.filter(item => (item.term || '').toLowerCase().indexOf(term.toLowerCase()) !== -1));
  // eslint-disable-next-line
  },[term, input.options]);

  return (
    <InputWrapper {...props}>
      <div className={CSS.item_list}>
        {input.searchable && (
          <div className={CSS.search_bar}>
            <input className={CSS.search_input} id="popSearch" type="text" placeholder="Search&hellip;" autoComplete="off" value={term} onChange={(e,input) => setTerm(e.target.value)} />
            <label className={CSS.input_icon} htmlFor="popSearch">
              <Icon symbol={'Search'} library={'general'} size={'24px'} />
            </label>
            {term.length > 0 && (
              <div className={CSS.input_clear}  onClick={e => setTerm('')}>
                <Icon symbol={'Close'} library={'navigation'} size={'24px'} />
              </div>
            )}
          </div>
        )}
        <Field
          name={input.name}
          validate={validate}
          component={ItemList}
          options={input.searchable ? termList : input.options}
          isMulti={input.isMulti}
          isTwoCol={input.isTwoCol}
          isSquare={input.isSquare}
          isNumeric={input.isNumeric}
          onChange={onChange}
          readOnly={input.readOnly}
          customItemClass={input.customItemClass}
          white={input.white}
        />
      </div>
    </InputWrapper>
  );
}

export default FieldItemList;
