import React, { useState, useEffect } from 'react';
import { useField } from 'formik';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import classNames from 'classnames';
import { get } from 'lodash';

import { format, useGoogleMaps } from '@moved/services';

import formCSS from '../StyledForm.module.scss';
import { InputWrapper } from '../InputWrapper';
import { AtomSpinner } from '../../AtomSpinner';

export const FieldGooglePlace = (props) => {

  const { input } = props;

  const {
    getPlaceDetails,
  } = useGoogleMaps();

  const [field, { value, error, touched }, { setValue }] = useField(input);

  const [visibleValue, setVisibleValue] = useState(format.address(input.value, { unit: false }));
  const [fetchingDetails, setFetchingDetails] = useState(false);

  useEffect(() => {
    if(get(input,'value.google_place_id')) _handleSelectSuggest({place_id:get(input,'value.google_place_id')});
  },[]); // eslint-disable-line

  const _handleSelectSuggest = async (suggest) => {
    setFetchingDetails(true);
    const address = await getPlaceDetails(suggest.place_id)
    // Set the value to display in the input
    setVisibleValue(format.address(address));
    // Set the address object as the value of the field
    setValue(address, true);
    setFetchingDetails(false);
  };

  const _onChange = (e, googlePlacesChange) => {
    const { onChange } = input;
    setVisibleValue(e.target.value);
    onChange && onChange(e, setValue);
    googlePlacesChange(e);
  }

  const _onKeyDown = (e, pluginKeyDown) => {
    if(e.key === 'Tab') {
      // if user tabs out of the input, issue an 'Enter'
      // event to the plugin to select the option
      return pluginKeyDown({
        key: 'Enter',
        preventDefault: () => false,
      })
    }
    else {
      return pluginKeyDown(e);
    }

  };

  const _onBlur = (e) => {
    // on blur, set the input value to the most recently selected address
    setVisibleValue(format.address(field.value, { unit: false }));
  }

  const classes =  classNames({
    [formCSS.has_error]: error && touched,
    [formCSS.has_value]: value,
  });

  return (
    <InputWrapper {...props}>
      <div className={formCSS.address_item}>
        <div className={formCSS.address_content}>
          {input.stopIcon && (<span className={formCSS.address_icon} />)}
          <div className={formCSS.google_place_wrapper}>
            {fetchingDetails && (
              <div className={formCSS.google_place_loader}>
                <AtomSpinner size={16} />
                <span>Fetching details&hellip;</span>
              </div>
            )}

            <GooglePlacesAutocomplete
              autocompletionRequest={{
                country: 'us',
                types: ['address'],
              }}

              idPrefix={input.name}

              debounce={300}

              onSelect={suggest => _handleSelectSuggest(suggest)}

              loader={(
                <div className={formCSS.google_autocomplete_loader}>
                  <AtomSpinner size={16} />
                </div>
              )}

              suggestionsClassNames={{
                container: formCSS.suggest_container,
                suggestion: formCSS.suggest_suggestion,
                suggestionActive: formCSS.suggest_suggestion_active,
              }}

              renderInput={(props) => (
                <input
                  {...field}
                  {...props}
                  value={visibleValue}
                  className={classes}
                  placeholder={input.placeholder}
                  type="text"
                  onChange={e => _onChange(e, props.onChange)}
                  onKeyDown={e => _onKeyDown(e, props.onKeyDown)}
                  onBlur={e => _onBlur(e)}
                  autoComplete="off"
                  aria-invalid="false"
                />
              )}
            />
          </div>
        </div>
      </div>
    </InputWrapper>
  );
}

export default FieldGooglePlace;
