import Image from '@editorjs/image';
import { get } from 'lodash';

import { makeDOMElement } from '../shared/helpers';
import CSS from './TitleBar.module.scss';
import icon from './icon.svg';

export class TitleBar extends Image {
  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      title: 'Title Bar',
      icon: `<img src="${icon}" alt="TitleBar" height="16" width="22"/>`,
    }
  }

  static get status() {
    return {
      EMPTY: 'empty',
      UPLOADING: 'loading',
      FILLED: 'filled',
    };
  }

  set image(file) {
    this._data.file = file || {};

    if (file && file.url && this.nodes) {
      this.fillImage(file.url);
    }
  }

  constructor({ data, config, api, readOnly }) {
    super({ data, config, api, readOnly });

    this.nodes = {
      wrapper: makeDOMElement('div', [this.CSS.baseClass, this.CSS.wrapper, this.CSS.flexWrapper]),
      imageContainer: makeDOMElement('div', [ this.CSS.imageContainer, CSS.image_container ]),
      fileButton: this.createFileButton(),
      imageEl: undefined,
      imagePreloader: makeDOMElement('div', [this.CSS.imagePreloader, this.CSS.preloader]),
      caption: makeDOMElement('div', [this.CSS.input, this.CSS.caption, this.CSS.flexCaption], {
        contentEditable: !this.readOnly,
        innerHTML: get(this,'data.caption',''),
      }),
    };

    this.nodes.caption.dataset.placeholder = this.config.captionPlaceholder;
    this.nodes.imageContainer.appendChild(this.nodes.imagePreloader);
    this.nodes.wrapper.appendChild(this.nodes.imageContainer);
    this.nodes.wrapper.appendChild(this.nodes.fileButton);
    this.nodes.wrapper.appendChild(this.nodes.caption);

    this._data = {};
    this.data = data;
  }

  get CSS() {
    return {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      input: this.api.styles.input,
      button: this.api.styles.button,

      /**
       * Tool's classes
       */

      wrapper: 'image-tool',
      flexWrapper: CSS.wrapper || 'wrapper',
      imageContainer: 'image-tool__image',
      flexImageContainer: CSS.image_container || 'image_container',
      imagePreloader: 'image-tool__image-preloader',
      imageEl: 'image-tool__image-picture',
      flexImage: CSS.image || 'image',
      caption: 'image-tool__caption',
      flexCaption: CSS.caption || 'caption',
      uploadButton: CSS.uploader || 'uploader',
      preloader: CSS.preloader || 'preloader',
    };
  }

  render() {
    if (!this.data.file || Object.keys(this.data.file).length === 0) {
      this.toggleStatus(TitleBar.status.EMPTY);
    } else {
      this.toggleStatus(TitleBar.status.UPLOADING);
    }

    return this.nodes.wrapper;
  }

  renderSettings() {
    return [];
  }

  createFileButton() {
    const button = makeDOMElement('div', [ this.CSS.button, CSS.uploader ]);

    button.innerHTML = this.config.buttonContent || `${this.api.i18n.t('Click to select title image...')}`;

    button.addEventListener('click', () => {
      this.onSelectFile();
    });

    return button;
  }

  showPreloader(src) {
    this.nodes.imagePreloader.style.backgroundImage = `url(${src})`;

    this.toggleStatus(TitleBar.status.UPLOADING);
  }

  onSelectFile() {
    this.uploader.uploadSelectedFile({
      onPreview: (src) => {
        this.showPreloader(src);
      },
    });
  }

  fillImage(url) {
    /**
     * Check for a source extension to compose element correctly: video tag for mp4, img — for others
     */
    const tag = /\.mp4$/.test(url) ? 'VIDEO' : 'IMG';
    const attributes = {
      src: url,
    };

    /**
     * We use eventName variable because IMG and VIDEO tags have different event to be called on source load
     * - IMG: load
     * - VIDEO: loadeddata
     *
     * @type {string}
     */
    let eventName = 'load';

    /**
     * Update attributes and eventName if source is a mp4 video
     */
    if (tag === 'VIDEO') {
      /**
       * Add attributes for playing muted mp4 as a gif
       *
       * @type {boolean}
       */
      attributes.autoplay = true;
      attributes.loop = true;
      attributes.muted = true;
      attributes.playsinline = true;

      /**
       * Change event to be listened
       *
       * @type {string}
       */
      eventName = 'loadeddata';
    }

    /**
     * Compose tag with defined attributes
     *
     * @type {Element}
     */
    this.nodes.imageEl = makeDOMElement(tag, [this.CSS.imageEl, this.CSS.flexImage], attributes);

    /**
     * Add load event listener
     */
    this.nodes.imageEl.addEventListener(eventName, () => {
      this.toggleStatus(TitleBar.status.FILLED);

      /**
       * Preloader does not exists on first rendering with presaved data
       */
      if (this.nodes.imagePreloader) {
        this.nodes.imagePreloader.style.backgroundImage = '';
      }
    });


    this.nodes.imageContainer.appendChild(this.nodes.imageEl);
  }

  toggleStatus(status) {
    for (const statusType in TitleBar.status) {
      if (Object.prototype.hasOwnProperty.call(TitleBar.status, statusType)) {
        this.nodes.wrapper.classList.toggle(`${this.CSS.wrapper}--${TitleBar.status[statusType]}`, status === TitleBar.status[statusType]);
      }
    }
  }

  save() {
    const caption = this.nodes.caption;
    this._data.caption = caption.innerHTML;
    return this.data;
  }
};
