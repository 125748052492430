import React from 'react';
import { Field } from 'formik';
import { InputWrapper } from '../InputWrapper';
import { get } from 'lodash';
import classNames from 'classnames';

import formCSS from '../StyledForm.module.scss';
import checkmark from '../../../../images/checkmark.svg';

export const FieldCheckbox = (props) => {
  const { input, validate } = props;

  const _onChange = (e, field) => {
    const { onChange } = input;

    //Passing up the onchange event for use in parent
    field.onChange(e);
    return onChange && onChange(!field.value, field.name);
  }

  return (
    <InputWrapper {...props}>
      <Field
        name={input.name}
        validate={validate}
      >
        {(renderProps) => {
          const { field, form: { errors, touched }} = renderProps;
          const classes = classNames(formCSS.checkbox,{
            [formCSS.has_error]: get(errors, input.name) && get(touched, input.name),
            [formCSS.has_value]: field.value,
          });
          return (
            <input
              {...field}
              className={classes}
              onChange={e => _onChange(e, field)}
              type="checkbox"
              checked={field.value}
              id={input.id || input.name}
              disabled={input.disabled}
            />
          );
        }}
      </Field>
      <label htmlFor={input.id || input.name} className={formCSS.checker} style={input.disabled ? { opacity: '0.6'} : {}}>
        <span className={[formCSS.checker_check].join(' ')} style={{backgroundImage: `url(${checkmark})`}} />
      </label>
    </InputWrapper>
  );
};
