import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from '../Icon';
import { Popover, usePopover } from '../Popover';

import CSS from './PopoverMenu.module.scss';

// Wrapper for Popover that applies menu-specific defaults and constraints
export const PopoverMenu = ({
  children,
  placement='bottom-end',
  initialOpen,
  closeOnOutsideClick=true,
}) => (
  <Popover
    placement={placement}
    initialOpen={initialOpen}
    closeOnOutsideClick={closeOnOutsideClick}
    offset={[0,12]}
  >
    { children }
  </Popover>
);
PopoverMenu.propTypes = {
  /** the content to be wrapped in the context for the popover menu */
  children: PropTypes.node.isRequired,
  /** override the default placement direction of the menu */
  placement: PropTypes.oneOf(['top-start', 'top-end', 'bottom-start', 'bottom-end']),
  /** boolean value to load the menu in the open state */
  initialOpen: PropTypes.bool,
  /** (advanced) override whether clicking outside the menu closes the menu */
  closeOnOutsideClick: PropTypes.bool,
};

const Content = ({ children, className, mountToBody, large }) => {
  const classes = classNames(CSS.menu, className, { [CSS.large]: large });
  return (
    <Popover.Content mountToBody={mountToBody} showArrow={false} className={classes}>
      { children }
    </Popover.Content>
  );
};
Content.propTypes = {
  /** the content to be rendered inside the popover menu */
  children: PropTypes.node.isRequired,
  /** render menu with larger typography and more padding */
  large: PropTypes.bool,
  /** className to add to the menu wrapper */
  className: PropTypes.string,
  /** (advanced) use portal to put the menu on the body element instead of sibling of trigger */
  mountToBody: PropTypes.bool,
};

const Divider = ({ className }) => (
  <div className={classNames(CSS.divider, className)} />
);
Divider.propTypes = {
  /** className to add to the divider element */
  className: PropTypes.string,
};

const Item = ({ label, icon, onClick, disabled, className }) => {
  const { hide } = usePopover();
  const handleClick = (e) => {
    if(disabled) return;
    onClick?.(e);
    hide(); // successful clicks inside the menu should close the menu
  };
  return (
    <div
      className={classNames(CSS.item, {[CSS.disabled]:disabled}, className)}
      onClick={handleClick}
    >
      { icon && (<Icon library={icon.library} symbol={icon.symbol} />)}
      <span className={CSS.itemLabel}>{ label }</span>
    </div>
  );
};
Item.propTypes = {
  /** the label for the menu item */
  label: PropTypes.string.isRequired,
  /** the icon to render on the menu item */
  icon: PropTypes.shape(Icon.propTypes),
  /** the label for the menu item */
  onClick: PropTypes.func,
  /** flag to disable functionally and visually */
  disabled: PropTypes.bool,
  /** className to add to the menu item element */
  className: PropTypes.string,
};

/* Append the subcomponents as properties of the main component for convenience */
PopoverMenu.Trigger = Popover.Trigger;
PopoverMenu.Content = Content;
PopoverMenu.Item = Item;
PopoverMenu.Divider = Divider;
