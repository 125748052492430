import React from 'react';
import { Field } from 'formik';
import moment from 'moment';

import { InputWrapper } from '../InputWrapper';
import { CustomSelect } from '../CustomSelect';

import formCSS from '../StyledForm.module.scss';

export const FieldTimePicker = ({ input, validate, ...props }) => {

  const onChange = (option, setFieldValue, name) => {
    input.onChange && input.onChange(option, setFieldValue, name);
  };

  const setTimeOptions = (min, max) => {
    const allTimes = [];
    const interval = input.interval || ['00','15','30','45'];
    ['am','pm'].forEach(a => {
      Array.from({length: 12}, (v, i) => i).forEach(h => {
        interval.forEach(m => {
          allTimes.push(`${h||'12'}:${m}${a}`);
        });
      });
    });

    let filteredTimes = allTimes.filter(time => {
      if((min && moment(time,'h:mma') < moment(min,'h:mma')) ||
       (max && moment(time,'h:mma') > moment(max,'h:mma'))) return false;
      return true;
    });

    return filteredTimes.map(t => ({value:t,label:t}));
  }

  return (
    <InputWrapper input={input} {...props}>
      <Field
        name={input.name}
        validate={validate}
        component={CustomSelect}
        options={setTimeOptions(input.min, input.max)}
        onChange={onChange}
        className={formCSS.select_container}
        readOnly={input.readOnly}
        placeholder={input.placeholder}
      />
    </InputWrapper>
  );
}
