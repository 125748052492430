import axios from 'axios';
import moment from 'moment';
import { filename as cleanFilename } from '../utils/formatters/filename';

// needs to use axios directly instead of 'request' service to avoid instance specifics that auto append
// user auth tokens and override error responses.
const putFile = (file, url) => axios.request({
  url: url,
  method: 'PUT',
  headers: { 'Content-Type': file.type },
  data: file,
});

const getUniqueFilename = (filename, user) => {
  return `${user.id.toString().padStart(7,'0')}-${moment().format('YYYYMMDDHHmmssSSS')}.${filename.split('.').pop().toLowerCase()}`;
};

export const s3 = {
  putFile,
  getUniqueFilename,
  cleanFilename,
};
