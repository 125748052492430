import React from 'react';
import classNames from 'classnames';
import { format } from '@moved/services';

import CSS from './StatusPill.module.scss';

const statusMap = {
  'complete':               { color: CSS.green,   label: 'Complete' },
  'approved':               { color: CSS.green,   label: 'Approved' },
  'cancelled':              { color: CSS.red,     label: 'Canceled' },
  'rejection-acknowledged': { color: CSS.red,     label: 'Declined' },
  'rejected':               { color: CSS.red,     label: 'Declined' },
  'action-required':        { color: CSS.red,     label: 'Declined' },
  'pending':                { color: CSS.orange,  label: 'Pending' },
  'pending-approval':       { color: CSS.orange,  label: 'Pending Approval' },
  'on-hold':                { color: CSS.orange,  label: 'On hold' },
  'submitted':              { color: CSS.blue,    label: 'Submitted' },
  'in-progress':            { color: CSS.blue,    label: 'In progress' },
  'not-started':            { color: CSS.gray,    label: 'Not started' },
  'locked':                 { color: CSS.white,   label: 'Locked' },
};

export const StatusPill = ({ status, className }) => {
  const variant = statusMap[status] || { color: CSS.white, label: format.dashToSpace(status) };
  return (
    <div className={classNames(CSS.pill,variant.color,className)}>
      { variant.label }
    </div>
  );
};
