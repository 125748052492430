import { useSelector } from 'react-redux';
import { createSelector as memoizedSelector } from '@reduxjs/toolkit';

// use default selector to re-fetch on every render (99% of use cases)
const createSelector = (selector) => (...params) => useSelector(state => selector(state, ...params));

// use memoized selector to fetch only if the evaluated dependecies have updated (uncommon complex use cases)
// examples:
//    - if the selector depends on multiple resources,
//    - if the selector does operations that always return a new entity such as Array.map()
const createMemoizedSelector = (dependencies=[], selector) => createSelector(memoizedSelector(dependencies, selector));

const createPendingSelector = (namespace) => () => useSelector(state => state?.requests?.[`${namespace}_PENDING`]);

export { createSelector, createPendingSelector, createMemoizedSelector };
