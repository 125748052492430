import React from 'react';
import { Field } from 'formik';
import moment from 'moment'
import classNames from 'classnames';
import DatePicker from "react-datepicker";

import dateCSS from '../styles/FieldDatePicker.module.scss';
import formCSS from '../StyledForm.module.scss';

import { InputWrapper } from '../InputWrapper';

// Calendar Component (maintains active state/field value)
const DateSelect = ({
  field,
  form,
  format,
  formatServer,
  placeholder,
  onChange,
  readOnly,
  minDate,
  maxDate,
}) => {

  //change handler
  const dateChange = (date) => {
    onChange(date, form.setFieldValue, field.name);
    return form.setFieldValue(field.name, date ? moment(date).format(formatServer) : '', true);
  };

  if(minDate && moment(field.value).isBefore(minDate)) {
    form.setFieldValue(field.name, moment(minDate).format(formatServer), true);
  }

  return (
    <DatePicker
      selected={field.value ? moment(field.value).toDate() : ''}
      onChange={dateChange}
      name={field.name}
      id={field.name}
      className={dateCSS.date_input}
      calendarClassName={dateCSS.calzone}
      wrapperClassName={classNames(dateCSS.wrapper,formCSS.date_picker)}
      placeholderText={placeholder}
      dateFormat={format}
      readOnly={readOnly}
      showDisabledMonthNavigation
      minDate={minDate ? moment(minDate).toDate() : null}
      maxDate={maxDate ? moment(maxDate).toDate() : null}
      autoComplete="off"
      aria-autocomplete="list"
    />
  );
};

const FieldDatePicker = (props) => {

  const { input, validate,  } = props ;

  const { format = 'MM/dd/yyyy', formatServer = 'YYYY-MM-DD', } = input;

  const onChange = (option, setFieldValue, name) => {
    const { onChange } = input;
    //Passing up the onchange event for use in parent
    onChange && onChange(option, setFieldValue, name);
    return;
  };

  return (
    <InputWrapper {...props}>
      <Field
        id={input.name}
        name={input.name}
        validate={validate}
        component={DateSelect}
        onChange={onChange}
        readOnly={input.readOnly}
        label={input.label}
        placeholder={input.placeholder}
        format={format}
        formatServer={formatServer}
        minDate={input.minDate}
        maxDate={input.maxDate}
      />
    </InputWrapper>
  );
};

export default FieldDatePicker;
