
import React from 'react';
import classNames from 'classnames';

import { EditorIconSelector } from '../shared/EditorIconSelector';
import CSS from './Definition.module.scss';

export const DefinitionEditor = ({ tool }) => {
  const { icon, title, content, showHeading } = tool.data;

  const onIconSelect = (icon) => {
    tool.data = { icon };
  };

  return (
    <div className={CSS.editor}>
      <div className={CSS.icon}>
        <EditorIconSelector initial={icon} size='32px' onSelect={onIconSelect} />
      </div>
      <div className='flex-auto'>
        { showHeading && (
          <div
            className={classNames('headingS contentPrimary',CSS.title)}
            dangerouslySetInnerHTML={{ __html: title }}
            data-placeholder={tool.placeholders.title}
            contentEditable={!tool.readOnly}
            suppressContentEditableWarning={true}
            tabIndex={0}
          />
        )}
        <div
          className={classNames('labelM contentSecondary',CSS.content)}
          dangerouslySetInnerHTML={{ __html: content }}
          data-placeholder={tool.placeholders.content}
          contentEditable={!tool.readOnly}
          suppressContentEditableWarning={true}
          tabIndex={0}
        />
      </div>
    </div>
  );
};
