import React, { useState, useRef } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { noop } from 'lodash';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { helpers } from '@moved/services';

import 'react-image-crop/dist/ReactCrop.css';
import CSS from './ImageCrop.module.scss';

export const ImageCrop = ({
  file,
  className,
  onCrop=noop,
  initialCrop,
  aspect,
  minHeight=0,
  minWidth=0,
  maxHeight,
  maxWidth,
}) => {

  const [renderScale, setRenderScale] = useState({width:1,height:1});
  const [activeCrop, setActiveCrop] = useState(initialCrop);

  const imgRef = useRef(null);
  const preview = helpers.canvas.usePreview(file);

  const initializeCrop = () => {
    // in order to implement min height/width, we need to know what to scale
    // those pixel values to. This calculates the render scale factor for each
    // dimension (could be different if intentionally distorting).
    const widthScale = imgRef.current.width / imgRef.current.naturalWidth;
    const heightScale = imgRef.current.height / imgRef.current.naturalHeight;
    setRenderScale({
      width: widthScale,
      height: heightScale,
    });

    if(initialCrop) return;

    // upon image load, set the initial crop in two steps:
    // 1) determine the aspect ratio based initial crop size (or default)
    // 2) center the initial crop for pleasantness
    const defaultCrop = aspect ? makeAspectCrop(
      {
        unit: 'px',
        width: imgRef.current.width,
        height: imgRef.current.height,
      },
      aspect,
      imgRef.current.width,
      imgRef.current.height,
    ) : {
      unit: 'px',
      width: Math.max(imgRef.current.width*.8, minWidth*widthScale), // default crop is 80% selected
      height: Math.max(imgRef.current.height*.8, minHeight*heightScale), // default crop is 80% selected
    };
    const centeredCrop = centerCrop(
      defaultCrop,
      imgRef.current.clientWidth,
      imgRef.current.clientHeight,
    );
    setActiveCrop(centeredCrop);
  };

  const updateCrop = async (crop) => {
    if(!imgRef || !crop) return;
    if(crop.width > 0) {
      const cropped = await helpers.canvas.crop(
        imgRef.current,
        crop,
        file.type,
      );
      const scaled = await helpers.canvas.downscale(
        cropped,
        { maxWidth, maxHeight }
      );
      scaled.name ??= file.name;
      onCrop({ file: scaled, crop });
    }
    else onCrop({ file, crop });
  };

  return (
    <div className={classNames(CSS.cropzone,className)}>
      <ReactCrop
        crop={activeCrop}
        onChange={setActiveCrop}
        onComplete={updateCrop}
        aspect={aspect}
        minHeight={minHeight && minHeight*renderScale.height}
        minWidth={minWidth && minWidth*renderScale.width}
        keepSelection={true}
      >
        <img
          ref={imgRef}
          src={preview}
          alt={file.name}
          crossOrigin={'anonymous'}
          onLoad={initializeCrop}
        />
      </ReactCrop>
    </div>
  );

};

ImageCrop.propTypes = {
  /** The image file to be cropped */
  file: PropTypes.oneOfType([
    PropTypes.instanceOf(File),
    PropTypes.object,
  ]),
  /** Classname to append to the component wrapper */
  className: PropTypes.string,
  /** Callback function that includes the cropped file and the crop measurement object */
  onCrop: PropTypes.func,
  /** Initial crop dimensions to be applied on load */
  initialCrop: PropTypes.object,
  /** Optional fixed aspect ratio for the crop selector (Width/Height)*/
  aspect: PropTypes.number,
  /** Minimum height of cropped area */
  minHeight: PropTypes.number,
  /** Mimimum width of cropped area */
  minWidth: PropTypes.number,
  /** Maximum output height of the cropped file (will downscale if necessary) */
  maxHeight: PropTypes.number,
  /** Maximum output width of the cropped file (will downscale if necessary) */
  maxWidth: PropTypes.number,
};


const Preview = ({ file, className }) => {
  const preview = helpers.canvas.usePreview(file);
  return preview && (
    <img src={preview} alt="Cropped Preview" className={className} />
  );
};

ImageCrop.Preview = Preview;
