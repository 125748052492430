/*
* Custom service wrapper for Google Maps Platform API:
* https://visgl.github.io/react-google-maps/docs
* Please refer to ^ documentation for all available options. This service
* is a wrapper that exposes the same api with custom defaults for our apps.
*
* Use context provider component for wrapping the global application
* (not needed more than once per app, usually in the Root component)
* example:
*    import { GoogleMapsProvider } from '@moved/services';
*    render (
*      <GoogleMapsProvider> ... </GoogleMapsProvider>
*    )
*/
import React, { createContext, useContext, useEffect, useState } from 'react';
import { APIProvider, useApiIsLoaded, useMapsLibrary } from '@vis.gl/react-google-maps';

const placeDetailsMap = {
  street_number: 'short_name',
  route: 'short_name',
  locality: 'short_name',
  sublocality_level_1: 'short_name',
  administrative_area_level_3: 'short_name',
  neighborhood: 'short_name',
  administrative_area_level_1: 'short_name',
  postal_code: 'short_name'
};

const formatPlaceComponentDetails = (place) => {
  const mappedAddress = {};
  (place?.address_components ?? []).forEach(component => {
    const type = component?.types[0];
    if(placeDetailsMap[type]) mappedAddress[type] = component[placeDetailsMap[type]];
  });
  return {
    street: [mappedAddress.street_number, mappedAddress.route].filter(v=>v).join(' ') ?? '',
    city: mappedAddress.locality || mappedAddress.sublocality_level_1 || mappedAddress.administrative_area_level_3 || mappedAddress.neighborhood || '',
    state:mappedAddress.administrative_area_level_1 || '',
    zipcode:mappedAddress.postal_code ?? '',
    google_place_id: place?.place_id ?? '',
  };
}

const GoogleMapsContext = createContext();
export const useGoogleMaps = () => useContext(GoogleMapsContext);

// load the google maps API provider context as long as there is a defined API Key
const withGoogleMapAPIs = (Wrapped) => (props) => (
  process.env.REACT_APP_GOOGLE_API_KEY ? (
    <APIProvider
      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      region='US'
      solutionChannel=''
    >
      <Wrapped {...props} />
    </APIProvider>
  ) : (
    <Wrapped {...props} />
  )
);

// helper to handle state management of loading an instance of a place service
const useMapsService = (serviceName, args) => {
  const ready = useApiIsLoaded();
  const placesLibrary = useMapsLibrary('places');
  const [service, setService] = useState();
  useEffect(() => {
    if(!ready || !placesLibrary) return;
    setService(new placesLibrary[serviceName](args));
  }, [ready, placesLibrary]);
  return service;
};

// encapsulated Moved provider for all google maps API services
export const GoogleMapsProvider = withGoogleMapAPIs(({ children }) => {
  if(!process.env.REACT_APP_GOOGLE_API_KEY) return children;
  const PlacesService = useMapsService('PlacesService', document.createElement('div'));
  const AutocompleteService = useMapsService('AutocompleteService');
  const { PlacesServiceStatus } = useMapsLibrary('places') ?? {};
  const initialized = useApiIsLoaded() &&
    PlacesService != null &&
    AutocompleteService != null;

  const getPlaceDetails = async (placeId) => {
    if(!placeId) return;
    const address = await new Promise((resolve,reject) => PlacesService.getDetails(
      { placeId, fields: ['place_id', 'address_components'] },
      (place, status) => {
        status === PlacesServiceStatus.OK ?
          resolve(formatPlaceComponentDetails(place)) :
          reject(new Error(status));
      },
    ));
    return address;
  };

  const refreshPlaceId = async (placeId) => {
    const freshPlaceId = await new Promise((resolve, reject) => PlacesService.getDetails(
      { placeId, fields: ['place_id'] },
      (place, status) => {
        status === PlacesServiceStatus.OK ?
          resolve(place?.place_id) :
          reject(new Error(status));
      },
    ));
    return freshPlaceId;
  };

  const getAutocompletePredictions = async (queryString) => {
    const predictions = await new Promise((resolve, reject) => AutocompleteService.getPlacePredictions(
      {
        input: queryString,
        componentRestrictions: { country: 'us' },
        types: ['address'],
      },
      (places, status) => {
        return status === PlacesServiceStatus.OK ? resolve(places) :
          status === PlacesServiceStatus.ZERO_RESULTS ? resolve([]) :
          reject(new Error(status));
      },
    ));
    return predictions;
  };

  const context = {
    getPlaceDetails,
    getAutocompletePredictions,
    refreshPlaceId,
  };

  // TODO: can this not be a blocking load dependency but instead queue any requests until finished loading?
  return initialized && (
    <GoogleMapsContext.Provider value={context}>{ children }</GoogleMapsContext.Provider>
  );
});
