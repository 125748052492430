import React, { useRef, useEffect } from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import { get } from 'lodash';

import formCSS from '../StyledForm.module.scss';
import { InputWrapper } from '../InputWrapper';

export const FieldPercentage  = (props) => {
  const { input } = props;
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.addEventListener('keypress', numbersOnly);
    return ((e) => () => e.removeEventListener('keypress', numbersOnly))(inputRef.current);
  // eslint-disable-next-line
  },[]); // only run on mount

  const numbersOnly = (e) => {
    if(e.which === 45 || e.which === 13) return;
    if (e.which < 48 || e.which > 57) e.preventDefault();
    return;
  };

  const formatPercent = (e, setFieldValue, fieldName) => {
    const target = e.target;
    let number = target.value;
    let negative = false;

    if(!number || number === '') return setFieldValue(fieldName, '', true);

    if(number.charAt(0) === '-') negative = true;

    let formattedNumber = parseInt(String(number).replace(/[^0-9.]+/g, ''));
    if (negative) formattedNumber *= -1;

    else if(input.maximum && formattedNumber > input.maximum) formattedNumber = input.maximum;

    const fieldValue = (formattedNumber/100);
    //Passing up the onchange event for use in parent
    input.onChange && input.onChange(e, setFieldValue, fieldName, fieldValue);

    return setFieldValue(fieldName, fieldValue, true);
  };

  const decrementValue = (e, setFieldValue, field) => {
    e.preventDefault();
    let number = field.value*1000;
    return setFieldValue(field.name, (number-50)/1000, true);
  };

  const incrementValue = (e, setFieldValue, field) => {
    e.preventDefault();
    let number = field.value*1000;
    return setFieldValue(field.name, (number+50)/1000, true);
  };

  return (
    <InputWrapper {...props}>
      <Field
        name={input.name}
      >
        {(renderProps) => {
          const { field, form: { errors, touched, setFieldValue } } = renderProps;
          const classes = classNames(formCSS.percentage,{
            [formCSS.has_error]: get(errors, input.name) && get(touched, input.name)
          });
          return (
            <>
              <input
                onChange={e => {
                  formatPercent(e, setFieldValue, field.name);
                  input.onChange && input.onChange(e);
                }}
                onBlur={field.onBlur}
                className={classes}
                placeholder={input.placeholder}
                type='text'
                id={input.name}
                name={field.name}
                value={Math.floor(field.value * 100)}
                ref={inputRef}
                readOnly={input.readOnly}
              />
              <span
                className={formCSS.decrement}
                onClick={e => {decrementValue(e, setFieldValue, field);}}
              >
                &minus;
              </span>
              <span
                className={formCSS.increment}
                onClick={e => {incrementValue(e, setFieldValue, field);}}
              >
                &#43;
              </span>
              <label className={formCSS.percent_sign} htmlFor={input.name}>&#37;</label>
            </>
          );
        }}
      </Field>
    </InputWrapper>
  );
}
