import { get } from 'lodash';
import { format } from '@moved/services';
export const getRequestFields = (request) => {
  return [
    get(request,'due_date') && {
      label: 'Due Date',
      value: format.date(get(request,'due_date')),
      icon: { symbol: 'Date', library: 'code' },
    },
    get(request,'completed_date') && {
      label: 'Date of completion',
      value: format.date(get(request,'completed_date')),
      icon: { symbol: 'Done-circle', library: 'code' },
    },
    {
      label: 'Status',
      value: get(request,'status'),
      icon: { symbol: 'Info-circle', library: 'code', },
    },
  ].filter(v=>v);
};
