import React from 'react';
import PropTypes from 'prop-types';

import CSS from './AnimatedCheckmark.module.scss';

export const AnimatedCheckmark = (props) => {
  return (
    <svg className={CSS.checkmark + ' ' + (props.isChecked ? CSS.checked : null) + ' ' + (props.fast ? CSS.fast : null)}
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle className={CSS.circle} cx="26" cy="26" r="25" fill="none" />
      <path className={CSS.check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  )
};

AnimatedCheckmark.propTypes = {
  isChecked: PropTypes.bool,
  fast: PropTypes.bool,
};

AnimatedCheckmark.defaultProps = {
  isChecked: false,
  fast: false,
};
