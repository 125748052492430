import { merge } from 'lodash';
import Image from '@editorjs/image';

import { generateUploader } from '../shared/helpers';

const generateImageConfig = (customConfig = {}) => {
  const baseConfig = {
    // Base configuration goes here
  };

  if(customConfig.customUrl) {
    customConfig = {
      ...customConfig,
      ...{ ...generateUploader(customConfig.customUrl) },
    };
  }

  return merge(baseConfig, customConfig);
};

export { Image, generateImageConfig };
