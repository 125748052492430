import React from 'react';
import { FieldArray as FormikArray } from 'formik';
import classNames from 'classnames';
import { get } from 'lodash';

import { DynamicField } from '../DynamicField';
import { Icon } from '../../../../sondheim/components/Icon';

import formCSS from '../StyledForm.module.scss';

export const FieldArray = ({input, ...props}) => {
  const arrayValues = get(props,`form.values[${input.name}]`);

  const blankRow = {};
  Object.keys(input.fieldInfo).forEach(fieldName => blankRow[fieldName] = '');

  return (
    <div className={formCSS.array_row}>
      <FormikArray name={input.name}>
        {({ remove, push }) => {
          return (
            <div className={formCSS.array_block}>
              <div className={formCSS.array_label}>
                <label htmlFor={input.name}>
                  <span>{input.label}</span>
                </label>
                <div className={formCSS.array_add} onClick={() => push(blankRow)}><span>&#43;</span></div>
              </div>
              { arrayValues.map((rowValues, index) => (
                <div className={formCSS.array_item_row} key={`${input.name}.${index}`}>
                  { Object.keys(rowValues).map(fieldName => {
                    let field = {
                      ...input.fieldInfo[fieldName],
                      name: `${input.name}.${index}.${fieldName}`,
                      value: rowValues[fieldName],
                    };
                    return (<DynamicField input={field} key={field.name} {...props} />);
                  })}
                  {((arrayValues.length > input.minimum) || !input.minimum) && (
                    <div className={formCSS.array_remove} onClick={() => remove(index)}>
                      <Icon symbol={'Trash'} library={'general'} className={classNames(formCSS.array_remove_icon)} size={'24px'} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          );
        }}
      </FormikArray>
    </div>
  );
};
