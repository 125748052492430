import React from 'react';
import { get } from 'lodash';
import { Icon } from '@moved/ui';

export const getRequestFields = request => {
  if(!request) return [];
  return [
    get(request,'image_url') && {
      label: 'Photo',
      value: (
        <>
          <Icon symbol={'Done'} library={'code'} color={'green'} />
          Uploaded
          (<a href={get(request,'image_url')} target="_blank" rel="noopener noreferrer">view</a>)
        </>
      ),
      icon: { symbol: 'Home-heart', library: 'home' },
    },
  ].filter(v=>v);
};
