import React, { useRef } from 'react';
import EditorJS from 'react-editor-js';
import { get, noop, cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import { tools } from './tools/config';

import CSS from './ContentEditor.module.scss';

export const ContentEditor = ({ onChange=noop, onReady=noop, customConfig={}, data, ...props }) => {
  const editor = useRef(null);

  // the only way to remove the built-in paragraph tool
  const activeTools = { ...tools(customConfig) };
  activeTools.paragraph = false;

  // temporary "forwarding" of paragraph blocks to text blocks
  // this is mutating state when the chapters come from redux, hence the clone
  const updatedData = cloneDeep(data);
  get(updatedData,'blocks',[]).forEach(block => {
    if(block.type === 'paragraph') block.type = 'text';
  });

  // if no uploadUrl is specified hide blocks that would upload a file
  for(const [name, tool] of Object.entries(activeTools)) {
    if(get(tool,'config.uploader') && !customConfig.uploadUrl) activeTools[name] = false;
  }

  return (
    <div className={CSS.editor}>
      <EditorJS
        data={updatedData}
        tools={activeTools}
        defaultBlock='text'
        instanceRef={ref => editor.current = ref}
        onChange={(api,data) => onChange(data)}
        onReady={() => onReady()}
        {...props}
      />
    </div>
  );
};

ContentEditor.propTypes = {
  onChange: PropTypes.func,
  onReady: PropTypes.func,
};
