import { getActiveRequest as defaultActiveRequest } from '../base/getActiveRequest';

export const getActiveRequest = (taskable) => {
  const activeRequest = defaultActiveRequest(taskable);
  return taskable && activeRequest && {
    // the requests don't have the content or progress so append it from the taskable
    content: taskable?.content ?? [],
    progress: taskable?.progress ?? {},
    ...activeRequest,
  };
};
