import { get, isNil } from 'lodash';

import { format } from '@moved/services';

export const getRequestFields = request => {
  if(!request) return [];
  if(!isNil(request.has_vehicles) && !request.has_vehicles) {
    return [
      {
        label: 'Do you have vehicles?',
        value: 'No',
        icon: {
          symbol: 'Car',
          library: 'vehicles',
        },
      },
    ];
  }
  return [
    ...get(request,'vehicles',[]).reduce((fields,vehicle) => ([
      ...fields,
      {
        key: `${vehicle.model}_${vehicle.id}`,
        label: (<h3><strong style={{color: '#151618'}}>{[vehicle.year, vehicle.make, vehicle.model].join(' ')}</strong></h3>),
        value: (<>&nbsp;</>),
        icon: { symbol: format.capitalize(vehicle.vehicle_type), library: 'vehicles'},
        isTitle: true,
      },
      {
        key: `color_${vehicle.model}_${vehicle.id}`,
        label: 'Color',
        value: vehicle.color,
      },
      {
        key: `license_${vehicle.model}_${vehicle.id}`,
        label: 'License plate number',
        value: vehicle.license_plate_number,
      },
      {
        key: `state_${vehicle.model}_${vehicle.id}`,
        label: 'State',
        value: vehicle.state,
      },
      {
        key: `owner_${vehicle.model}_${vehicle.id}`,
        label: 'Owner',
        value: format.fullname(vehicle.owner),
      },
    ]), []),
    {
      label: 'Reason for decline',
      value: request?.notes,
      icon: { symbol: 'File', library: 'files' },
    },
  ];
};
