import { generate, request } from '@moved/services';

const creator = {
  namespace: 'JOBS_GET_JOBS',
  request: (params={}) => request.get(`/vendors/jobs`,{params}).then(r => r.data),
  selector: (state) => state?.jobs ?? {},
  reducers: {
    success: (state, { response, params }) => {
      return {
        ...state,
        jobs: {
          params: params,
          totalResults: response.pagination.total_items,
          activeSet: response.data.vendor_jobs,
        },
      }
    }
  }
};

export const {
  action: getJobs,
  reducer,
  useResource: useJobs,
  usePending: useJobsPending,
} = generate.redux(creator);

export const initialState = {
  jobs: {
    params: {},
    totalResults: 0,
    activeSet: [],
  },
};
