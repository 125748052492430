import icon from './icon.svg';

export class Quote {

  /**
   * Notify core that read-only mode is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   */
  static get toolbox() {
    return {
      title: 'Quote',
      icon: `<img src="${icon}" alt="Quote" height="16" width="16"/>`,
    };
  }

  /**
   * Empty Quote is not empty Block
   */
  static get contentless() {
    return true;
  }

  /**
   * Allow to press Enter inside the Quote
   */
  static get enableLineBreaks() {
    return true;
  }


  /**
   * Allow Quote to be converted to/from other blocks
   */
  static get conversionConfig() {
    return {
      import: 'text',
      export: function (quoteData) {
        return quoteData.text;
      },
    };
  }

  /**
   * Tool styles
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      block: 'ce-quote',
    };
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {{data: QuoteData, config: QuoteConfig, api: object, readOnly: bool}}
   *   data — previously saved data
   *   config - user config for Tool
   *   api - Editor.js API
   *   readOnly - read-only mode flag
   */
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.placeholder = config.placeholder || api.i18n.t('Enter a quote');
    this.data = {
      text: data.text || '',
    };
  }

  /**
   * Create Quote Tool container with inputs
   *
   * @returns {Element}
   */
  render() {
    const block = document.createElement('blockquote');
    block.contentEditable = !this.readOnly;
    block.innerHTML = this.data.text;
    block.classList.add(this.CSS.baseClass,this.CSS.block);
    block.dataset.placeholder = this.placeholder;
    return block;
  }

  /**
   * Save the block data
   */
  save(block) {
    return {
      text: block.innerHTML,
    }
  }

  /**
   * Validator rules
   */
   validate(data) {
     if(!data.text.trim()) {
       return false;
     }
     return true;
   }


  /**
   * Sanitizer rules
   */
  static get sanitize() {
    return {
      text: {
        br: true,
      },
    };
  }

}
