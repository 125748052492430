import React, { useEffect, useRef } from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import { get, isNil } from 'lodash';

import { InputWrapper } from '../InputWrapper';
import CSS from '../styles/FieldScore.module.scss';

export const FieldScore = (props) => {
  const { input, validate } = props;
  const { max = 10 } = input;

  const selectOption = (field, form, option) => {
    if(input.onChange) input.onChange(option, form.setFieldValue, input.name);
    return form.setFieldValue(field.name, option);
  };

  const options = [...Array(max).keys()].map(o => {
    return { label: (o+1).toString(), value: o+1 };
  });

  return (
    <InputWrapper {...props}>
      <Field
        name={input.name}
        validate={validate}
      >
        {(props) => {
          const { field, form } = props;
          return (
              <Score
                active={field.value}
                options={options}
                onSelect={selectOption.bind(this, field, form)}
                max={max}
                form={form}
                input={input}
              />
          );
        }}
      </Field>
    </InputWrapper>
  );
};

const Score = ({ options, active, className, onSelect, max, form, input }) => {
  const pillRef = useRef();
  const activeRef = useRef();

  const { errors, touched } = form;

  useEffect(() => {
    movePill();
  },[active]); // eslint-disable-line

  const movePill = () => {
    if(!isNil(active) && activeRef.current) {
      pillRef.current.style.left = activeRef.current.offsetLeft + 'px';
      pillRef.current.style.width = activeRef.current.offsetWidth + 'px';
    }
  };

  const clickHandler = (e, selected) => {
    e.preventDefault();
    onSelect(selected);
  };

  const renderOptions = options.map((opt, index) => {
    return (
      <li
        id={`toggle_${opt.value}`}
        key={opt.value}
        ref={active === opt.value ? activeRef : null}
        className={classNames(
          CSS.option,
          {
            [CSS.active]: active === opt.value,
            [CSS.has_error]: get(errors, input.name) && get(touched, input.name),
          },

        )}
        onClick={(e) => clickHandler(e,opt.value)}
      >
        {opt.label}
      </li>
    );
  });

  return (
    <>
    <ul className={classNames('lib--SlideToggle',CSS.toggle,className)}>
      {renderOptions}
      <span className={CSS.pill} ref={pillRef}>
        <span className={CSS.pill_green} style={ active ? { opacity: `${active/max}` } : {} } />
      </span>
    </ul>
    </>
  );
};
