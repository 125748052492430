import { merge } from 'lodash';
// Block Tools
import { AppStoreLink } from './AppStoreLink/AppStoreLink';
import { Attaches } from './Attaches/Attaches';
import { Button } from './Button/Button';
import { CheckList } from './CheckList/CheckList';
import { Definition } from './Definition/Definition';
import { Delimiter } from './Delimiter/Delimiter';
import { Heading } from './Heading/Heading';
import { Image } from './Image/Image';
import { Legacy } from './Legacy/Legacy';
import { List } from './List/List';
import { Quote } from './Quote/Quote';
import { Spacing } from './Spacing/Spacing';
import { Text } from './Text/Text';
import { TitleBar } from './TitleBar/TitleBar';

// Inline Tools
import { InlineCode } from './InlineCode/InlineCode';

// Helpers
import { generateUploader } from './shared/helpers';

export const tools = ({ uploadUrl, tools={} }={}) => {
  return merge({
    appStoreLink: {
      class: AppStoreLink,
      inlineToolbar: false,
      className: 'cdx-app-store-link',
      config: {},
    },
    attaches: {
      class: Attaches,
      inlineToolbar: true,
      className: 'cdx-attaches',
      config: generateUploader(uploadUrl),
    },
    button: {
      class: Button,
      inlineToolbar: false,
      className: 'cdx-button-link',
    },
    checklist: {
      class: CheckList,
      inlineToolbar: true,
      className: 'cdx-checklist',
      config: {},
    },
    definition: {
      class: Definition,
      inlineToolbar: true,
      className: 'cdx-definition',
      config: {},
    },
    delimiter: {
      class: Delimiter,
      className: 'ce-delimiter'
    },
    heading: {
      class: Heading,
      inlineToolbar: true,
    },
    image: {
      class: Image,
      inlineToolbar: true,
      className: 'cdx-image',
      config: generateUploader(uploadUrl),
    },
    legacy: {
      class: Legacy,
      inlineToolbar: false,
      className: 'cdx-legacy',
      config: {
        watermark: 'legacy',
      }
    },
    // external is an extention of legacy (with a different watermark)
    external: {
      class: Legacy,
      inlineToolbar: false,
      className: 'cdx-legacy',
      config: {
        watermark: 'external',
      }
    },
    list: {
      class: List,
      inlineToolbar: true,
      className: 'cdx-list',
    },
    quote: {
      class: Quote,
      inlineToolbar: true,
      className: 'ce-quote',
      config: {
        placeholder: 'Start typing...',
      },
    },
    inlineCode: {
      class: InlineCode,
    },
    spacing: {
      class: Spacing,
    },
    text: {
      class: Text,
      inlineToolbar: true,
    },
    // DEPRECATED IN FAVOR OF "TEXT" (need to leave in for rendering outdated blocks)
    paragraph: {
      class: Text,
      inlineToolbar: true,
    },
    titleBar: {
      class: TitleBar,
      inlineToolbar: true,
      className: 'cdx-title-bar',
      config: {
        // this tool inherits from the Image tool class (it has an image upload)
        ...generateUploader(uploadUrl),
        captionPlaceholder: 'Enter title...',
      }
    },
  }, tools);
};
