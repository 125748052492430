/*
*   Error message utility to format serverside errors into a single displayable
*   error message to show the user.
*   Usage: error(Error,codeMap)
*   Params:
*     Error - the error response from the serverside api
*     codeMap - manually defined hash map of serverside response codes as keys
*         where the value is the message to display for that code.
*/
import React from 'react';
import { get } from 'lodash';

export const error = (err={}, codeMap={}, customHandler) => {
  if(get(err,'response.data.errors'))
    return get(err,'response.data.errors')
      .map(e => error(e,codeMap,customHandler)) // get individual error messages
      .filter(v => v); // remove empty messages
  if(customHandler) return customHandler(err);
  else return getMessage(err, codeMap);
};

const getMessage = (error={}, codeMap={}) => {
  if(error.code && get(codeMap,error.code)) return codeMap[error.code]; // if matches code map, use map value
  else if(error.status && get(codeMap,error.status)) return codeMap[error.status]; // support status code in map as well
  else if(error.message) return error.message; // else use serverside message
  return ''; // else use no message
}

export const stripeError = (error) => {
  if(error.details) return stripeError(error.details); // if nested in moved-error
  if(error.code === 'card_declined') {
    const overrideCodes = [
      'call_issuer',
      'do_not_honor',
      'do_not_try_again',
      'generic_decline',
      'lost_card',
      'no_action_taken',
      'processing_error',
      'reenter_transaction',
      'revocation_of_all_authorizations',
      'revocation_of_authorization',
      'security_violation',
      'service_not_allowed',
      'stolen_card',
      'stop_payment_order',
      'try_again_later',
    ];
    if(overrideCodes.includes(error.decline_code)) return (
      <div key={'stripe-card-declined-error'}>
        Your card has been declined.<br/>
        Please try again, try a different card, or reach out to your bank for more information.
      </div>
    );
  }
  return error.message;
}
