import React, { useState, useRef, useEffect } from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import { get } from 'lodash';
import formCSS from '../StyledForm.module.scss';
import { InputWrapper } from '../InputWrapper';

export const FieldTypeAhead = (props) => {
  const { input } = props;
  const [displayValue, setDisplayValue] = useState('');
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [blurValue, setBlurValue] = useState('');
  const inputRef = useRef();

  const handleClickOutside = e => {
    if (inputRef && !inputRef.current.contains(e.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    input.action().then(res => { setOptions(res) });
    document.addEventListener('mousedown', handleClickOutside);
    return (() => document.removeEventListener('mousedown', handleClickOutside));
  // eslint-disable-next-line
  },[]); // only run on mount

  const onBlur = (e, field) => {
    setDisplayValue(blurValue);
    return field.onBlur(e);
  };

  const getOptions = (value = '') => {
    setOpen(true);
    setDisplayValue(value);
    input.action(value).then(res => { setOptions(res) });
  };

  const formatLabel = (label, term = '') => {
    const lowTerm = term.toLowerCase();
    const lowLabel = label.toLowerCase();
    const idx = lowLabel.indexOf(lowTerm);
    if (idx >= 0) {
      return (
        <>
          {label.substring(0, idx)}
          <span className={formCSS.term}>{label.substring(idx, idx+term.length)}</span>
          {label.substring(idx + term.length)}
        </>
      );
    } else {
      return (label);
    }
  }

  const selectOption = (option, setFieldValue) => {
    setFieldValue(input.name, option.id, true);
    setDisplayValue(option.label);
    setBlurValue(option.label);
    input.onChange && input.onChange(input, option, setFieldValue);
    setOpen(false);
    return setOptions([]);
  };

  const handleInputClick = e => setOpen(!open);

  return (
    <div className={formCSS.input_wrapper} ref={inputRef}>
      <InputWrapper {...props}>
        <Field
          name={input.name}
          >
          {(renderProps) => {
            const { field, form: { errors, touched, setFieldValue } } = renderProps;
            const classes = classNames(formCSS.type_ahead, {
              [formCSS.has_error]: get(errors, input.name) && get(touched, input.name),
              [formCSS.has_value]: field.value,
            });

            return (
              <>
                <input
                  name={field.name}
                  value={field.value}
                  type="hidden"
                  readOnly
                  style={{display: 'none'}}
                />
                <input
                  onChange={e => getOptions(e.target.value)}
                  onClick={handleInputClick}
                  onBlur={e => onBlur(e, field)}
                  className={classes}
                  placeholder={input.placeholder}
                  type='text'
                  id={`${field.name}_display`}
                  readOnly={input.readOnly}
                  name={`${field.name}_display`}
                  value={displayValue}
                  autoComplete="off"
                  aria-autocomplete="list"
                />
                {(open && options.length > 0) && (
                  <div className={formCSS.menu}>
                    {options.map(option => (
                      <div
                        className={formCSS.menu_option}
                        key={option.id}
                        onClick={e => {
                            e.preventDefault();
                            return selectOption(option,setFieldValue)
                          }
                        }
                      >
                        <div className={formCSS.labels}>
                          <span>{formatLabel(option.label, displayValue)}</span>
                          {option.label_right && (
                            <span className={formCSS.right}>
                              {option.label_icon}
                              {formatLabel(option.label_right, displayValue)}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            );
          }}
        </Field>
      </InputWrapper>
    </div>
  );
}
