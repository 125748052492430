import { getBlob } from './getBlob';

const unlimitedSize=99999;

const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return { width: srcWidth*ratio, height: srcHeight*ratio };
};

export const downscale = async (image, { maxWidth=unlimitedSize, maxHeight=unlimitedSize }, quality=0.7) => {

  // if not specifying at least one limiting dimension, return original
  if(maxHeight === unlimitedSize && maxWidth === unlimitedSize) return image;

  const bitmap = await createImageBitmap(image);
  const { width, height } = bitmap;

  // if image is already smaller than max dimensions, return original
  if(width <= maxWidth && height <= maxHeight) return image;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const { width: newWidth, height: newHeight } = calculateAspectRatioFit(width, height, maxWidth, maxHeight);

  canvas.width = newWidth;
  canvas.height = newHeight;

  ctx.drawImage(bitmap, 0, 0, newWidth, newHeight);

  return getBlob(canvas, 'image/jpeg', quality);

};
