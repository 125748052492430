import React from 'react';

import { useUser } from '@moved/services';
import { Icon } from '@moved/ui';

import CSS from './ProxyModeBadge.module.scss';

export const ProxyModeBadge = () => {
  const { isProxy, exitProxy } = useUser();

  if(!isProxy) return null;
  return (
    <div className={CSS.proxy_mode_holder}>
      <div id="proxy-mode" className={CSS.proxy_mode} onClick={exitProxy}>
        <Icon library="design" symbol="Disguise" color='white' size='25px' />
        Proxy Mode
        <div className={CSS.exit}>
          click to exit
        </div>
      </div>
    </div>
  );
};
