import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import CSS from './AppStoreLink.module.scss';

import AmazonBlack from './assets/amazon-black.svg';
import AmazonWhite from './assets/amazon-white.svg';
import AmazonColor from './assets/amazon-color.svg';

import AppleBlack from './assets/apple-black.svg';
import AppleWhite from './assets/apple-white.svg';
import AppleColor from './assets/apple-color.svg';

import FDroidBlack from './assets/fdroid-black.svg';
import FDroidWhite from './assets/fdroid-white.svg';
import FDroidColor from './assets/fdroid-color.svg';

import GalaxyBlack from './assets/galaxy-black.svg';
import GalaxyWhite from './assets/galaxy-white.svg';
import GalaxyColor from './assets/galaxy-color.svg';

import GoogleBlack from './assets/google-black.svg';
import GoogleWhite from './assets/google-white.svg';
import GoogleColor from './assets/google-color.svg';

import HuaweiBlack from './assets/huawei-black.svg';
import HuaweiWhite from './assets/huawei-white.svg';
import HuaweiColor from './assets/huawei-color.svg';

import MicrosoftBlack from './assets/microsoft-black.svg';
import MicrosoftWhite from './assets/microsoft-white.svg';
import MicrosoftColor from './assets/microsoft-color.svg';

const assetMap = {
  amazon: {
    black: AmazonBlack,
    white: AmazonWhite,
    color: AmazonColor,
    aspectRatio: 137/40,
  },
  apple: {
    black: AppleBlack,
    white: AppleWhite,
    color: AppleColor,
    aspectRatio: 120/40,
  },
  fdroid: {
    black: FDroidBlack,
    white: FDroidWhite,
    color: FDroidColor,
    aspectRatio: 112/40,
  },
  galaxy: {
    black: GalaxyBlack,
    white: GalaxyWhite,
    color: GalaxyColor,
    aspectRatio: 147/40,
  },
  google: {
    black: GoogleBlack,
    white: GoogleWhite,
    color: GoogleColor,
    aspectRatio: 135/40,
  },
  huawei: {
    black: HuaweiBlack,
    white: HuaweiWhite,
    color: HuaweiColor,
    aspectRatio: 133/40,
  },
  microsoft: {
    black: MicrosoftBlack,
    white: MicrosoftWhite,
    color: MicrosoftColor,
    aspectRatio: 111/40,
  },
};

export const AppStoreLink = ({ type='apple', color='color', link, height=40, className, onClick=noop }) => {
  const { aspectRatio, ...assetOptions } = assetMap[type];
  const asset = assetOptions[color];
  const width = height * aspectRatio;
  return (
    <a href={link} target='_blank' rel='noreferrer' onClick={onClick} className={CSS.link}>
      <div className={classNames(CSS.button,className)} style={{
        backgroundImage: `url(${asset})`,
        width,
        height,
      }} />
    </a>
  );
};

AppStoreLink.propTypes = {
  /** Which app store to display */
  type: PropTypes.oneOf(['amazon','apple','fdroid','galaxy','google','huawei','microsoft']),
  /** Which color variant to render the button */
  color: PropTypes.oneOf(['black','white','color']),
  /** Full url to the app store app screen */
  link: PropTypes.string,
  /** Custom height to render the button (in pixels) */
  height: PropTypes.number,
  /** (Optional) Custom class name to add to the button */
  className: PropTypes.string,
  /** (Optional) Custom handler fired on button click */
  onClick: PropTypes.func,
};
