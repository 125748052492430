import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { useModal, useNotify } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

import { JobModal } from './JobModal';
import { getJobDetails, useJobDetailsPending } from '../actions/getJobDetails';

export const ActiveJob = ({ parent }) => {
  const { active } = useParams();
  const history = useHistory();
  const Modal = useModal();
  const Notify = useNotify();
  const dispatch = useDispatch();

  // redux
  const pending = useJobDetailsPending();

  useEffect(() => {
    dispatch(getJobDetails(active))
      .then(job =>
        Modal.open(<JobModal job={job} />, {
          onClose: () => history.push(parent),
        })
      )
      .catch(() => Notify.error(`We're sorry, we were unable to fetch information about that job.`));
  // eslint-disable-next-line
  },[active]);

  return pending ? (<LoaderOverlay/>) : null;
};
