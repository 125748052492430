/*
*   CardSetupForm
*     Use this component if using this as a stand alone form for collecting a stripe payment method.
*     Params
*       onSuccess(paymentIntent): callback function fired after payment method recieved from stripe
*       onError(error): callback function fired after an error from the stripe sdk
*/

import React, { useState, useRef } from 'react';
import * as Yup from 'yup';
import classNames from 'classnames';

import { CardSetupField } from './CardSetupField';
import { StyledForm } from '../DynamicForm/StyledForm';

const validation = Yup.object().shape({
  nameOnCard: Yup.string().required('Required')
});

// Stripe Card Field with add default form wrapper
export const CardSetupForm = ({ onSubmit, onSuccess, onError, formStyle='underline', submitLabel='Save Card', formId='stripe-card-form', className }) => {
  const [pending, setPending] = useState(false);
  const stripeCard = useRef(); // to be assigned to CardSetupField component
  const handleSubmit = (values) => {
    if(pending || !stripeCard.current) return;
    if(onSubmit) onSubmit();
    setPending(true);
    stripeCard.current.confirmCard(values).then(result => {
      if(!result) return;
      if(onSuccess) return onSuccess(result);
    }).catch(error => {
      if(onError) onError(error);
    }).finally(() => setPending(false));
  }
  return (
    <>
      <StyledForm
        id={formId}
        onSubmit={handleSubmit}
        formStyle={formStyle}
        initialValues={{nameOnCard:''}}
        validation={validation}
        className={className}>
        { form => (<CardSetupField form={form} ref={stripeCard} />) }
      </StyledForm>
      { submitLabel && (
        <button className={classNames('btn-primary',{loading:pending})} disabled={pending}>{ submitLabel }</button>
      )}
    </>
  );
};
