import React from 'react';

import { Header } from '../Header';
import { Footer } from '../Footer';

import layoutCSS from './LayoutSimple.module.scss';

export const LayoutSimple = ({ children, activeMoveStep={} }) => {
  return (
    <div className={layoutCSS.template}>
      <Header
        activeMoveStep={activeMoveStep}
        tight={true}
      />
      {children}
      <Footer />
    </div>
  );
};
