import React from 'react';
import { Field, ErrorMessage } from 'formik';
import classNames from 'classnames';
import { get } from 'lodash';

import { useModal } from '@moved/services';

import { ContentEditor } from '../../ContentEditor';

import formCSS from '../StyledForm.module.scss';

export const FieldEditor = ({ input, validate, formStyle }) => {
  const Modal = useModal();

  const onChange = (content, setFieldValue, name) => {
    //Passing up the onchange event for use in parent
    input.onChange && input.onChange({ blocks: content.blocks }, setFieldValue, name);
    return setFieldValue(name, { blocks: content.blocks }, true);
  }

  const _showModal = (e,renderer,value) => {
    e.preventDefault();
    Modal.open(
      <div className={CSS.editor_preview}>
       {renderer(value)}
      </div>
    );
  };

  return (
    <div className={classNames(formCSS.input_row, formCSS[formStyle], formCSS[`type_${input.type}`], {[formCSS.half_input]: input.half}, input.className)}>
      <Field
        name={input.name}
        validate={validate}
      >
        {({ field, form, disabled }) => {
          const { errors, touched, setFieldValue } = form;
          const classes =  classNames(formCSS.base_textarea, {
            [formCSS.has_error]: get(errors, input.name) && get(touched, input.name),
            [formCSS.has_value]: field.value,
          });
          return (
            <>
              { input.label && !input.hiddenLabel && (
                <div className={formCSS.editor_label}>
                  <h3>{input.label}</h3>
                  {input.renderer && (
                    <div className='btn-ghost btn--small' onClick={(e) => _showModal(e,input.renderer,field.value)}>
                      Preview content
                    </div>
                  )}
                </div>
              )}
              { input.flavor && (
                <span className={classNames(formCSS.flavor,'mb-20')}>{input.flavor}</span>
              )}
              <div className={formCSS.editor_container}>
                <ContentEditor
                  {...field}
                  id={input.name}
                  readOnly={input.readOnly}
                  disabled={disabled ? 'disabled' : null }
                  uploadUrl={input.uploadUrl}
                  className={classes}
                  placeholder={input.placeholder}
                  data={field.value}
                  onChange={data => {
                    return onChange(data, setFieldValue, field.name)
                  }}
                  minHeight={100}
                  autofocus={false}
                  logLevel={'ERROR'}
                  customConfig={{
                    uploadUrl: input.uploadUrl,
                  }}
                  onBlur={e => input.onBlur && input.onBlur(form)}
                />
              </div>
              <ErrorMessage name={input.name} htmlFor={input.name} component="label" className={formCSS.error_msg} />
            </>
          );
        }}
      </Field>
    </div>
  );
};
