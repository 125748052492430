import React from 'react';
import { Field } from 'formik';
import { get } from 'lodash';
import classNames from 'classnames';

import { InputWrapper } from '../InputWrapper';
import formCSS from '../StyledForm.module.scss';

export const FieldToggle = (props) => {
  const { input, validate } = props;
  return (
    <InputWrapper {...props}>
      <Field
        name={input.name}
        validate={validate}
      >
        {(renderProps) => {
          const { field, form:{ errors, touched } } = renderProps;
          const classes = classNames(formCSS.toggle_checkbox,{
            [formCSS.has_error]: get(errors, input.name) && get(touched, input.name),
            [formCSS.has_value]: field.value,
          });
          return (
            <input
              {...field}
              className={classes}
              placeholder={input.placeholder}
              disabled={input.disabled}
              type="checkbox"
              checked={field.value}
              id={input.name}
            />
          );
        }}
      </Field>
      <label htmlFor={input.name} className={[formCSS.toggle, props.mini ? formCSS.mini: ''].join(' ')} />
    </InputWrapper>
  );
};
