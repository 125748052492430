/*
* Custom service wrapper for loading content on a stack in the foreground of the application
*
* The hook for this context `useOverlay` provides the following functions:
*   open, closeAll
*
*   open: (contents, options) => {}
*     contents: (element) the content to render in the overlay
*     options:
*       onClose(result):  (function) callback function on layer close that recieves
*                         the result provided by the layer if it sends one (undefined if dismissed)
*
*   closeAll: () => {}
*
* Additionally a second context hook `useLayer` is available to access the 'current'
* layer within the overlay stack:
*    layer: { id, contents, options, close }
*/

import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import nextId from 'react-id-generator';

const OverlayContext = createContext();
const useOverlay = () => useContext(OverlayContext);

const OverlayProvider = ({ children, ...props}) => {
  const [activeLayers, setActiveLayers] = useState([]);

  // legacy use of Modal.close() outside of the context means that it is not
  // kept in sync with activeLayers state changes. This workaround uses ref
  // to keep a list of active layers up-to-date for this legacy use.
  const layersRef = useRef(activeLayers); // experimental
  useEffect(() => {
    layersRef.current = activeLayers;
  },[activeLayers]);

  const open = (contents, options) => {
    const id = nextId('overlay-');
    setActiveLayers(activeLayers => ([
      ...activeLayers,
      {
        id,
        contents,
        options,
      },
    ]));
    return id;
  };

  const closeById = (id, ...result) => {
    const match = activeLayers.find(layer => layer.id === id);
    match?.options?.onClose?.(...result);
    setActiveLayers(activeLayers.filter(layer => layer.id !== id));
  };

  const closeAll = (...result) => {
    const layers = layersRef.current;
    // one by one close all open layers from bottom up (top from the UI perspective)
    layers.reverse().forEach(({ id }) => closeById(id, ...result));
  };

  // maintaining close top for now to support legacy Modal use of Modal.close()
  const closeTop = (...result) => {
    const layers = layersRef.current;
    layers.length > 0 && closeById(layers[layers.length - 1].id, ...result);
  };

  const context = { open, closeById, closeAll, closeTop, activeLayers };
  return (
    <OverlayContext.Provider value={context} {...props}>
      { children }
    </OverlayContext.Provider>
  );
};

const LayerContext = createContext();
const useLayer = () => useContext(LayerContext);

// define the layer markup as component to be rendered downstream (inside the ServicesProvider)
const OverlayInstance = () => {
  const { activeLayers=[], closeById } = useOverlay();
  const createLayerContext = (layer) => ({
    ...layer,
    close: (...params) => closeById(layer.id, ...params),
  });
  return (
    <TransitionGroup className={CSS.group} component={null}>
      { activeLayers.map(layer => (
        <CSSTransition key={layer.id} timeout={450} unmountOnExit>
          <LayerContext.Provider value={createLayerContext(layer)}>
            { layer.contents }
          </LayerContext.Provider>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export {
  OverlayProvider, // used in the Services Context or to add Overlays manually to an application
  OverlayInstance, // used to render the Overlays context markup at desired location without portals
  useOverlay, // hook to use the Overlay api
  useLayer, //hook to interact with the Overlay api in context of the 'current' layer
};
