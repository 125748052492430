import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CSS from './Icon.module.scss';

export const Icon = ({
  size='24px',
  symbol,
  library='stockholm',
  color,
  onClick,
  className,
}) => {

  const iconSvgs = require.context('./libraries/', true, /\.svg$/ );

  const svgs = iconSvgs
    .keys()
    .reduce((images, path) => {
      images[path] = iconSvgs(path);
      return images;
    }, {});

    return (
      <svg
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        alt={symbol}
        className={classNames(CSS.icon, {[CSS[color]]:color}, className)}
        style={{ height: size, width: size }}
        onClick={onClick}
      >
        <use xlinkHref={svgs[`./${library.replace('moved-','')}.svg`]+`#${symbol}`}></use>
      </svg>
    );
};

Icon.propTypes = {
  /** Number that determines size of svg in pixels */
  size: PropTypes.string,
  /** Which icon to use */
  symbol: PropTypes.string.isRequired,
  /** Which library to use */
  library: PropTypes.oneOf([
    'stockholm', // hopefully temporary
    'animals',
    'clothes',
    'code',
    'communication',
    'cooking',
    'credit-cards',
    'design',
    'devices',
    'electric',
    'files',
    'food',
    'general',
    'home',
    'layout',
    'map',
    'media',
    'navigation',
    'number',
    'people',
    'shopping',
    'social',
    'task',
    'text',
    'third-party',
    'vehicles',
    'weather',
  ]),
  /** Color of icon */
  color: PropTypes.oneOf([
    null,
    'black',
    'blue',
    'brown',
    'gray',
    'green',
    'inherit',
    'orange',
    'red',
    'white',
    'yellow',
  ]),
  /** Optional onClick handler */
  onClick: PropTypes.func,
  /** Optional class name */
  className: PropTypes.string,
};
