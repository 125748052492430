import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';

import CSS from './SlideToggle.module.scss';

export const SlideToggle = ({ active, options, callback, className, rectangular, full, disabled }) => {
  const pillRef = useRef();
  const activeRef = useRef();

  useEffect(() => {
    movePill();
  });

  const movePill = () => {
    if(!isNil(active) && activeRef.current) {
      pillRef.current.style.left = activeRef.current.offsetLeft + 'px';
      pillRef.current.style.width = activeRef.current.offsetWidth + 'px';
    }
  };

  const callbackHandler = (e, selected) => {
    e.preventDefault();
    if(!disabled && callback) callback(selected);
  }

  return (
    <ul
      className={classNames(
        'lib--SlideToggle',
        CSS.toggle,
        className,
        {
          [CSS.rectangular]: rectangular,
          [CSS.full]: full,
          [CSS.disabled]: disabled,
        },
      )}
    >
      { options.map((option) => (
          <li
            id={`toggle_${option.value}`}
            key={option.value}
            ref={active === option.value ? activeRef : null}
            className={classNames(
              CSS.option,
              {
                [CSS.active]: active === option.value
              },
            )}
            onClick={(e) => callbackHandler(e,option.value)}
            style={ full ? { width: `${100/options.length}%` } : {} }
          >
            { option.label }
          </li>
      ))}
      <span className={CSS.pill} ref={pillRef} />
    </ul>
  );


};
