import React, { useEffect, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useOptionalControl } from '@moved/services';

import { Icon } from '../../Icon';
import CSS from './Text.module.scss';

export const Text = forwardRef(({
  name,
  type='text',
  value,
  isControlled,
  label,
  hint,
  icon,
  iconPosition='left',
  disabled,
  readOnly,
  error,
  onBlur,
  onChange,
  onClick,
  onFocus,
  className,
  inputClassName,
  iconClassName,
  labelClassName,
}, parentRef) => {
  const [text, setText] = useOptionalControl(value ?? '', isControlled);
  const localRef = useRef();
  const inputRef = parentRef ?? localRef; // use parent ref if one exists, else use local ref
  const caretPosition = useRef();

  const handleChange = (e) => {
    if(disabled) return;
    const newValue = e.target.value ?? '';
    setText(newValue);
    caretPosition.current = e.target.selectionStart;
    isControlled && e.preventDefault();
    onChange?.({[name]:newValue});
  };

  // caret position persistence
  useEffect(() => {
    if(isControlled && type === 'text') inputRef.current.setSelectionRange(caretPosition.current,caretPosition.current);
  },[isControlled, type, text, inputRef]);

  const InputIcon = icon && (
    <Icon className={classNames(CSS.icon,iconClassName)} library={icon.library} symbol={icon.symbol} size='24px' />
  );

  const classes = [
    CSS.container,
    className,
  ];

  return (
    <div className={classNames(...classes)}>
      <input
        className={classNames(
          CSS.input,
          {
            [CSS.leadingIcon]: icon && iconPosition === 'left',
            [CSS.trailingIcon]: icon && iconPosition === 'right',
            [CSS.hasValue]: isControlled ? value : text,
            [CSS.hasError]: error,
          },
          inputClassName,
          'sondheim', // added to differentiate from legacy inputs and use `:not(:global(.sondheim))` on balazs CSS selectors
        )}
        value={text}
        name={name}
        id={name}
        type={type}
        disabled={disabled}
        readOnly={readOnly}
        onChange={handleChange}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={inputRef}
        inputMode={['tel','number'].includes(type) ? 'numeric' : 'text'}
        autoComplete='off'
      />
      { InputIcon }
      <label className={classNames(CSS.label,'contentSecondary',labelClassName)} htmlFor={name}>{ label }</label>
      { error ? (
        <div className='labelS contentError marginTop-4'>{ error }</div>
      ) : hint && (
        <div className={classNames('labelS marginTop-4',disabled?'contentTertiary':'contentSecondary')}>{ hint }</div>
      )}
    </div>
  );
});

Text.propTypes = {
  /** Name to use for the form input */
  name: PropTypes.string.isRequired,
  /** Type to use for the form input */
  type: PropTypes.string,
  /** Text value to use for this input (only initial value if not controlled) */
  value: PropTypes.string,
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** Label text for the input */
  label: PropTypes.string,
  /** Second line of text */
  hint: PropTypes.string,
  /** Icon to display in the input */
  icon: PropTypes.shape({
    symbol: PropTypes.string,
    library: PropTypes.string,
  }),
  /** Icon position relative to text */
  iconPosition: PropTypes.oneOf(['left','right']),
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** Flag to readonly the input */
  readOnly: PropTypes.bool,
  /** Error message to display for this input */
  error: PropTypes.string,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** **(advanced)** onFocus handler function */
  onFocus: PropTypes.func,
  /** **(advanced)** onBlur handler function */
  onBlur: PropTypes.func,
  /** Class name to add to the container */
  className: PropTypes.string,
  /** **(advanced)** Class name to add to the input element */
  inputClassName: PropTypes.string,
  /** **(advanced)** Class name to add to the icon element */
  iconClassName: PropTypes.string,
  /** **(advanced)** Class name to add to the label element */
  labelClassName: PropTypes.string,
};
