import React from 'react';
import Moment from 'react-moment';
import moment from 'moment'
import classNames from 'classnames';

import { Icon } from '@moved/ui';

import { AvailabilitySlot } from './AvailabilitySlot';
import weekCSS from '../../styles/WeeklyCalendar.module.scss';

const dayStrings = {
  lastDay : 'ddd',
  sameDay : '[Today]',
  nextDay : 'ddd',
  lastWeek : 'ddd',
  nextWeek : 'ddd',
  sameElse : 'ddd'
};

export const AvailabilityDay = (props) => {

  const { date, appointments, active, view, jobs, modalEdit, navigation, selectDate } = props;

  const navigate = (direction) => {
    let newDate = moment(date).clone();
    if(direction === 'forward') newDate.add(1,'days');
    else if(direction === 'back') newDate.subtract(1,'days');
    selectDate(newDate);
  }

  const isToday = moment(date).isSame(moment(), 'd');

  return (
    <div className={classNames(weekCSS.day_column, {[weekCSS.active_day]: active, [weekCSS.today]: isToday})}>

      <div className={weekCSS.day_header}>
        {navigation && (
          <div onClick={() => navigate('back')} className={weekCSS.navigation}>
            <Icon symbol='Chevron-left' library='navigation' className={weekCSS.arrow} />
          </div>
        )}
        <div className='flex-auto'>
          <p className={weekCSS.day_of_week}>
            <Moment date={date} calendar={dayStrings} />
          </p>
          <p className={weekCSS.day_of_month}>
            <span><Moment date={date} format="D" /></span>
          </p>
        </div>
        {navigation && (
          <div onClick={() => navigate('forward')} className={weekCSS.navigation}>
            <Icon symbol="Chevron-right" library='navigation' className={weekCSS.arrow} />
          </div>
        )}
      </div>

      { appointments.map(slot => (
        <AvailabilitySlot
          key={slot.position}
          slot={{...slot, date: date}}
          view={view}
          jobs={jobs && jobs[slot.position]}
          modalEdit={modalEdit}
        />
      ))}

    </div>
  );
};
