import React, { createContext, useContext, useEffect, useState } from 'react';
import { useUser } from './UserContext';

/*
*  Mixpanel Removed, but keeping context so we can replace with a different tracking solution
*/

const TrackContext = createContext();
const useTrack = () => useContext(TrackContext);

const TrackProvider = ({children, ...props}) => {
  const { user } = useUser();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    // load sdk and run any initialization
    setLoaded(true);
  },[]);
  useEffect(() => {
    // use sdk to update current user identification
  },[user]);

  const event = (name, data, options, callback) => {
    // trigger a single event track
  };

  const context = { event };

  if(!loaded) return null;
  return (
    <TrackContext.Provider value={context} {...props}>
      { children }
    </TrackContext.Provider>
  );
};

export {TrackContext, useTrack, TrackProvider};
