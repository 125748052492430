import React from 'react';
import { get } from 'lodash';

import { format, fieldService } from '@moved/services';

import * as Fields from './Fields';

export const DynamicField = (props) => {
  const { input, formStyle, form: { values } = {} } = props;
  // can not extend `props` so add additional props here
  const additionalProps = {};

  // non-stacked formStyles require placeholder to be defined (for psuedo selector :placeholder-shown)
  if(formStyle !== 'stacked' && !input.placeholder) input.placeholder = ' ';

  // add custom props for special field types
  switch (input.type) {
    case 'email':
      additionalProps.validate = (value) => {
        let error;
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i.test(value) && value !== '') {
          error = 'Must be a valid email address';
        }
        return error;
      };
      break;
    default:
      break;
  }

  const componentName = `Field${format.capitalize(input.type)}`;
  const FieldComponent = Fields[componentName];

  if(get(input,'custom.readOnly')) {
    props.input.readOnly = fieldService.isReadOnly(input, values);
  }
  if(fieldService.isHidden(input, values)) {
    if(['title','content'].includes(input.type)) return null;
    // This input type hides the field and sets value to ''
    return (<Fields.FieldHiddenDisabled {...props} {...additionalProps} />);
  }
  if(FieldComponent) {
    return (<FieldComponent {...props} {...additionalProps} />);
  }
  return (<Fields.FieldDefault {...props} {...additionalProps} />)

}
