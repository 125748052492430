import React from 'react';

import { DynamicField } from './DynamicField';

export const RenderFields = ({fields=[], form, formStyle='underline'}) => {
  return fields.map(field => {
    if(!field) return null;
    const fieldProps = {
      input:field,
      form,
      key: field.name ? field.name : field.label,
      formStyle,
    };
    return (<DynamicField {...fieldProps} />);
  });
};
