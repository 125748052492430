import React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import { get } from 'lodash';

import { InputWrapper } from '../InputWrapper';
import formCSS from '../StyledForm.module.scss';

export const FieldPassword = (props) => {
  const { input, validate } = props;

  const onChange = (e, setFieldValue, name) => {
    //Passing up the onchange event for use in parent
    input.onChange && input.onChange(e, setFieldValue, name);
    return setFieldValue(name, e.target.value, true);
  }

  return (
    <InputWrapper {...props}>
      <Field
        name={input.name}
        validate={validate}
      >
        {({ field, form, disabled }) => {
          const { errors, touched, setFieldValue } = form;
          const classes = classNames(formCSS.password, {
            [formCSS.has_error]: get(errors, input.name) && get(touched, input.name),
            [formCSS.has_value]: field.value,
          });
          return (
            <>
            <input
              {...field}
              className={classes}
              placeholder={input.placeholder}
              type="password"
              id={input.name}
              readOnly={input.readOnly}
              onChange={e => {onChange(e, setFieldValue, field.name);}}
              disabled={disabled ? 'disabled' : null }
            />
            {input.forgotPassword && <span className={formCSS.forgot_password} onClick={() => input.forgotPassword(get(form,'values'))}>Forgot?</span>}
            </>
          );
        }}
      </Field>
    </InputWrapper>
  );
}
