import React from 'react';
import classNames from 'classnames';
import { get } from 'lodash';

import { OptionalLink } from '@moved/ui';

import { ReactComponent as MovedIcon } from './mvd-icon.svg';
import { ReactComponent as MovedLogo } from './mvd-logo.svg';

import CSS from './LogoBlock.module.scss';

export const LogoBlock = ({ logo, name, icon, invert, redirect, horizontal, className }) => {
  const classes = classNames(CSS.logo_block,{
    [CSS.inverted]: invert,
    [CSS.small]: icon,
    [CSS.horizontal]: horizontal,
    [className]: className,
  });
  return (
    <div className={classes}>
      <OptionalLink to={redirect}>
        { icon ? <MovedIcon className={CSS.mvd_icon} /> : <MovedLogo className={CSS.mvd_logo} /> }
      </OptionalLink>
      { logo && (
        <>
          <span className={CSS.divider} />
          <OptionalLink to={redirect}>
            <img src={logo} alt={name} className={CSS.building_logo} />
          </OptionalLink>
        </>
      )}
    </div>
  );
};

export const BuildingLogoBlock = ({ building={}, ...props }) => {
  const name = get(building, 'settings.display_name') || building.name;
  return <LogoBlock logo={building.logo_url} name={name} {...props} />;
}
