import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { UserProvider, ServicesProvider } from '../../contexts';

export const Providers = ({children, store, exclude}) => {
  return (
    <ReduxProvider store={store}>
      <UserProvider>
        <BrowserRouter>
          <ServicesProvider exclude={exclude}>
            { children }
          </ServicesProvider>
        </BrowserRouter>
      </UserProvider>
    </ReduxProvider>
  );
};
