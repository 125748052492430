import React, { useRef, useEffect } from 'react';
import { Field } from 'formik';
import { get } from 'lodash';
import classNames from 'classnames';

import formCSS from '../StyledForm.module.scss';
import { InputWrapper } from '../InputWrapper';

export const FieldDifference = (props) => {
  const { input } = props;
  const inputRef = useRef();
  useEffect(() => {
    inputRef.current.addEventListener('keypress', numbersOnly);
    return ((e) => () => e.removeEventListener('keypress', numbersOnly))(inputRef.current);
  // eslint-disable-next-line
  },[]); // only run on mount

  const numbersOnly = (e) => {
    if(e.which === 13) return;
    if (e.which < 48 || e.which > 57) e.preventDefault();
    return;
  };

  const formatInteger = (e, setFieldValue, fieldName) => {
    const number = e.target.value;

    let formattedNumber = parseInt(number);
    if(isNaN(formattedNumber)) formattedNumber = 0;

    const fieldValue = formattedNumber + input.difference;

    //Passing up the onchange event for use in parent
    input.onChange && input.onChange(e, setFieldValue, fieldName, fieldValue);

    return setFieldValue(fieldName, parseInt(fieldValue), true);
  };

  const decrementValue = (e, setFieldValue, field) => {
    e.preventDefault();
    if((field.value - input.difference) <= 0) return;
    return setFieldValue(field.name, (field.value - 1), true);
  };

  const incrementValue = (e, setFieldValue, field) => {
    e.preventDefault();
    return setFieldValue(field.name, (field.value + 1), true);
  };

  const validateDifference = (value) => {
    let error;
    if((value - input.difference) < 0) error = 'May not be less than 0';
    return error;
  };

  return (
    <InputWrapper {...props}>
      <Field
        name={input.name}
        validate={validateDifference}
        >
        {(renderProps) => {
          const { field, form: { errors, touched, setFieldValue } } = renderProps;
          const classes = classNames(formCSS.integer,{
            [formCSS.has_error]: get(errors, input.name) && get(touched, input.name)
          });
          return (
            <>
              <input
                onChange={e => {
                  field.onChange(e);
                  return formatInteger(e, setFieldValue, field.name);
                }}
                onBlur={field.onBlur}
                className={classes}
                placeholder={input.placeholder}
                type='text'
                id={input.name}
                name={input.name}
                value={parseInt(field.value - input.difference)}
                ref={inputRef}
                readOnly={input.readOnly}
              />
              <span
                className={formCSS.decrement}
                onClick={e => {decrementValue(e, setFieldValue, field);}}
              >
                &minus;
              </span>
              <span
                className={formCSS.increment}
                onClick={e => {incrementValue(e, setFieldValue, field);}}
              >
                &#43;
              </span>
            </>
          );
        }}
      </Field>
    </InputWrapper>
  );
}

export default FieldDifference;
