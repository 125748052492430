import { useState, useEffect } from 'react';

export const usePreview = (file) => {
  const [preview, setPreview] = useState(file?.src);

  useEffect(() => {
    if(file?.src || !file) return;
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  },[file]);

  return preview;
};
