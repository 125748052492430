import { get } from 'lodash';
import { Icon } from '@moved/ui';

export const getRequestFields = request => {
  return [
    ...get(request, 'content', []).map((chapter) => ({
      icon: { symbol: 'File', library: 'files' },
      label: chapter.label || chapter.title, // two apis provide different keys
      value: (!request?.progress || request?.progress?.[chapter?.slug]) ? (
        <Icon symbol='Done-circle' library='code' size='24px' />
      ) : '-',
    })),
    {
      label: 'Reason for decline',
      value: request?.notes,
      icon: { symbol: 'File', library: 'files' },
    },
  ];
};
