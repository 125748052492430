import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { range, chunk } from 'lodash';

import { Icon } from '../../../sondheim/components/Icon';

import calOldCSS from './MonthlyCalendar.module.scss';
import calBoxCSS from './MonthlyCalendarBoxed.module.scss';

export const MonthlyCalendar = ({
  moment: dayView = moment(),
  selected = false,
  highlightWeek = false,
  highlightDay = true,
  hoverWeek = false,
  disablePast = false,
  onSelect = () => { return false; },
  boxStyle = false,
  isDayDisabled = false,
}) => {

  // set initial state
  const [currentDateView, setCurrentDateView] = useState(dayView);

  // bind changes to the dayView prop
  useEffect(() => {
    if(!dayView.isSame(currentDateView,'day')) setCurrentDateView(dayView);
  },[dayView]); // eslint-disable-line

  // map the style variant
  const CSS = boxStyle ? calBoxCSS : calOldCSS;

  // helpers
  const changeMonth = (dir) => {
    const _updated = currentDateView.clone();
    setCurrentDateView(_updated[dir === 'prev' ? 'subtract' : 'add'](1, 'month'))
  };

  const select = (day, isSelected, isPrevMonth, isNextMonth) => {
    const _updated = currentDateView.clone();

    if (isPrevMonth) _updated.subtract(1, 'month');
    if (isNextMonth) _updated.add(1, 'month');
    _updated.date(day);

    setCurrentDateView(_updated);
    onSelect(_updated);

  };

  const _renderDayOfWeek = (dow, index) => {
    return (
      <th className={CSS.dow} key={index}>
        <span>{dow}</span>
      </th>
    );
  };


  const _renderDay = (week, day) => {
    const _current = currentDateView.clone();

    const isPrevMonth = week === 0 && day > 7;
    const isNextMonth = week >= 4 && day <= 14;
    if(isNextMonth) _current.add(1, 'month');
    else if(isPrevMonth) _current.subtract(1, 'month');
    _current.set('date', day);

    const isSelected = selected ? _current.isSame(selected, 'day') : false;
    const isToday = moment().isSame(_current, 'day');
    const isPast = moment().isAfter(_current, 'day');
    const isDisabled = (isPast && disablePast) || (isDayDisabled && isDayDisabled(_current))

    const handleClick = () => {
      if(isDisabled) return false;
      select(day, isSelected, isPrevMonth, isNextMonth);
    };

    const dayClasses = classNames(
      CSS.day,
      { [CSS.day_selected]: highlightDay && isSelected },
      { [CSS.day_today]: isToday },
      { [CSS.day_prev]: isPrevMonth },
      { [CSS.day_next]: isNextMonth },
      { [CSS.day_disabled]: isDisabled },
    );

    return (
      <td
        key={day}
        className={dayClasses}
        onClick={handleClick}>
        <span>{day}</span>
      </td>
    );
  }

  // Convenience calculations for rendering
  const firstDay = currentDateView.clone().date(1).day();
  const endOfThisMonth = currentDateView.clone().endOf('month').date();
  const endOfLastMonth = currentDateView.clone().subtract(1, 'month').endOf('month').date();
  const days = [
    ...range(endOfLastMonth - firstDay + 1, endOfLastMonth + 1),
    ...range(1, endOfThisMonth + 1),
    ...range(1, 42 - endOfThisMonth - firstDay + 1)
  ];

  const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  const weekOfMonth = (input = moment()) => {
    const firstDayOfMonth = input.clone().startOf('month');
    const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');
    const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');
    return Math.ceil((input.date() + offset) / 7);
  };

  const weekNum = (weekOfMonth(moment(selected).startOf('day')) - 1);

  return (
    <div className={CSS.container}>
      <div className={CSS.header}>
        <div className={CSS.header_month}>
          { currentDateView.format('MMMM YYYY') }
        </div>
        <div className={CSS.header_nav}>
          <span className={[CSS.month_nav, CSS.month_prev].join(' ')} onClick={() => changeMonth('prev')}>
            <Icon symbol='Chevron-left' library='navigation' className={CSS.arrow} />
          </span>
          <span className={[CSS.month_nav, CSS.month_next].join(' ')} onClick={() => changeMonth('next')}>
            <Icon symbol='Chevron-right' library='navigation' className={CSS.arrow} />
          </span>
        </div>
      </div>
      <table className={classNames(CSS.calendar, hoverWeek ? CSS.hover_week : CSS.hover_day)}>
        <thead>
          <tr>{daysOfWeek.map((dow, index) => _renderDayOfWeek(dow, index))}</tr>
        </thead>
        <tbody>
          {chunk(days, 7).map((week, index) => {
            return (
              <tr
                key={index}
                className={classNames(
                  {[CSS.active_week]: highlightWeek && weekNum === index && moment(selected).isSame(currentDateView,'month')},
                  CSS.week,
                )}
              >
                { week.map((day) => _renderDay(index,day)) }
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
