import React from 'react';
import { get, isEqual } from 'lodash';
import { createRoot } from 'react-dom/client';

import { BaseTool } from '../BaseTool';
import { AppStoreLinkEditor } from './AppStoreLinkEditor';

import CSS from './AppStoreLink.module.scss';
import icon from './icon.svg';

export class AppStoreLink extends BaseTool {
  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      title: 'App Store Link',
      icon: `<img src="${icon}" alt="Button" height="24" width="24"/>`,
    }
  }

  /**
   * Sanitizer rules
   */
  static get sanitize() {
    return {
      stores: false, // disallow HTML
      appleUrl: false, // disallow HTML
      googleUrl: false, // disallow HTML
    };
  }

  /**
   * Construct class base data
   *
   * @param {RawData} data — previously saved HTML data
   * @param {object} config - user config for Tool
   * @param {object} api - CodeX Editor API
   * @param {object} block - CodeX Editor Block API
   * @param {boolean} readOnly - read-only mode flag
   */
  constructor({ data, config, api, block, readOnly }) {
    super({ api, block, readOnly });
    this.placeholder = get(config,'placeholder') || 'Enter url...';
    this.wrapper = undefined;
    this.settings = [
      {
        icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M7.34009 9.62804C7.29609 9.43004 7.27409 9.23204 7.27409 8.99004C7.27409 8.37404 7.38409 7.78004 7.62609 7.23004C7.84609 6.68004 8.17609 6.19604 8.61609 5.77804C9.03409 5.36004 9.56209 5.03003 10.1781 4.78804C10.7721 4.54604 11.4541 4.43604 12.2241 4.43604C12.9501 4.43604 13.6101 4.54604 14.2041 4.76604C14.7981 5.00804 15.2821 5.31604 15.7001 5.71204C16.0961 6.10804 16.4041 6.57004 16.6241 7.07604C16.8221 7.60404 16.9321 8.15404 16.9321 8.74804C16.9321 9.69404 16.6681 10.53 16.1621 11.256C15.6341 11.982 14.9521 12.62 14.1381 13.126L11.2121 14.996C10.7941 15.282 10.3981 15.612 10.0461 15.986C9.69409 16.36 9.47409 16.822 9.38609 17.328H17.0641V19H7.14209C7.16409 17.9 7.42809 16.91 7.91209 16.008C8.39609 15.128 9.21009 14.314 10.3541 13.566L12.9061 11.916C13.6101 11.454 14.1601 10.97 14.5341 10.464C14.9081 9.95804 15.0841 9.40804 15.0841 8.77004C15.0841 8.41804 15.0181 8.08804 14.9081 7.75804C14.7761 7.42804 14.6001 7.14204 14.3581 6.87804C14.1161 6.63604 13.8081 6.43804 13.4341 6.28404C13.0601 6.13004 12.6421 6.06404 12.1801 6.06404C11.6961 6.06404 11.2561 6.15204 10.8821 6.30604C10.5081 6.46004 10.1781 6.68004 9.91409 6.94404C9.62809 7.23004 9.43009 7.56004 9.29809 7.93404C9.14409 8.30804 9.07809 8.72604 9.07809 9.16604C9.07809 9.45204 9.10009 9.67204 9.14409 9.84804L7.34009 9.62804Z" fill="#151618"/>
        </svg>
        `,
        label: `Apple + Google Play`,
        value: ['apple','google'],
      },
      {
        icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M11.4982 8.8139H7.4502V7.6039C8.7042 7.6039 9.6942 7.3399 10.4642 6.8119C11.2342 6.3059 11.6302 5.6019 11.6742 4.7439H13.3022V17.3499H16.9542V18.9999H7.7362V17.3499H11.4982V8.8139Z" fill="#151618"/>
        </svg>
        `,
        label: `Apple App Store only`,
        value: ['apple'],
      },
      {
        icon: `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M11.4982 8.8139H7.4502V7.6039C8.7042 7.6039 9.6942 7.3399 10.4642 6.8119C11.2342 6.3059 11.6302 5.6019 11.6742 4.7439H13.3022V17.3499H16.9542V18.9999H7.7362V17.3499H11.4982V8.8139Z" fill="#151618"/>
        </svg>
        `,
        label: `Google Play Store only`,
        value: ['google'],
      },
    ];
    this.data = {
      stores: data.stores || this.settings[0].value,
      appleUrl: data.appleUrl,
      googleUrl: data.googleUrl,
    };


  }

  // render the tool inside the editor
  render() {
    this.wrapper = this.makeDOMElement('div',[CSS.editor]);
    const root = createRoot(this.wrapper);
    root.render(<AppStoreLinkEditor appStoreLink={this} data={this.data}/>);
    return this.wrapper;
  }

  // format the data when saving
  save(wrapper) {
    return Object.assign(this.data, {
      appleUrl: wrapper.querySelector(`[name="appleUrlValue"]`).value || '',
      googleUrl: wrapper.querySelector(`[name="googleUrlValue"]`).value || '',
    });
  }

  /**
   * Settings
   *
   * @public
   * @returns {Element}
   */
  renderSettings() {
    return this.settings.map(setting => ({
      icon: setting.icon,
      label: setting.label,
      onActivate: () => this._toggleTune(setting),
      isActive: isEqual(this.data.stores, setting.value),
      toggle: 'stores', // treats these as a radio group
    }));
  }

  /**
   * update the data for the active tune and force rerenders as needed
   * @private
   * @param {object} tune - specific tune configuration from settings
   */
  _toggleTune(tune) {
    this.data.stores = tune.value;
    // manually force an onchange event to save the data
    this.block.dispatchChange();
    // update the tool markup because it does not react to the data change
    const tool = this.wrapper;
    if(tool) tool.parentNode.replaceChild(this.render(), tool);
  }

};
