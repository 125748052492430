import Attaches from '@editorjs/attaches';
import { merge } from 'lodash';

import { generateUploader } from '../shared/helpers';

const generateAttachesConfig = (customConfig) => {
  const baseConfig = {
    // Base configuration goes here
  };

  if(!customConfig) return baseConfig;

  if(customConfig.customUrl) {
    customConfig = {
      ...customConfig,
      ...{ ...generateUploader(customConfig.customUrl) },
    };
  }

  const config = merge(baseConfig, customConfig);

  return config;
};

export { Attaches, generateAttachesConfig };
