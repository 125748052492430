/*
* Custom service wrapper for Stripe SDK Integration:
* https://stripe.com/docs/js
* Please refer to ^ documentation for all available options. This service
* is a wrapper that exposes the same api with custom defaults for our apps.
*
* Use context provider component for wrapping the global application
* (not needed more than once per app, usually in the Root component)
* example:
*    import { StripeProvider } from '@moved/services';
*    render (
*      <StripeProvider options={options}> ... </StripeProvider>
*    )
*/
import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const defaultOptions = {
  fonts: [{
    family: 'circular',
    src: 'url(https://my.moved.com/fonts/circularstd-book.woff)',
    weight: '400',
  }]
};

// define NotifyContextProvider as functional component
export const StripeProvider = ({ options, children }) => {
  if(!process.env.REACT_APP_STRIPE_KEY) return children;
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  return (
    <Elements
      stripe={stripePromise}
      options={{...defaultOptions,...options}}>
      { children }
    </Elements>
  );
};
