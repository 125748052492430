import React, { Component, Fragment } from 'react';
import { Field, ErrorMessage } from 'formik';
import { get } from 'lodash';
import classNames from 'classnames';

import formCSS from '../StyledForm.module.scss';

class FieldHidden extends Component {

  render() {
    const { input, validate } = this.props;
    return (
      <Fragment>
        <Field
          name={input.name}
          validate={validate}
        >
          {(props) => {
            const { field } = props;
            const { errors, touched } = props.form;
            const classes = classNames({
              [formCSS.has_error]: get(errors, input.name) && get(touched, input.name),
              [formCSS.has_value]: field.value,
            });
            return (
              <input
                {...field}
                className={classes}
                type={input.type}
                id={input.name}
              />
            );
          }}
        </Field>
        <ErrorMessage name={input.name} htmlFor={input.name} component="label" className={formCSS.error_msg} />
      </Fragment>
    );
  }
}

export default FieldHidden;
