import React, { useState, useMemo } from 'react';
import { useField, useFormikContext } from 'formik';
import { useMount } from 'react-use';

/**
 * HOC to convert a stand-alone control component to a form connected input
 * - requires the control to have the following interface:
 *    name        - to use as the form key
 *    value       - to use as a controlled input
 *    onChange    - to update the value through the form
 *    error       - to display form validation errors
 * - `options` second arguement (optional) is used to specify:
 *    type        - type is used in formik field hook
 *                  only needed if the control doesn't already have a type prop
 *    defaultValue - in order to be a controlled input the initial value must be defined
 *                  this value is the fallback for this type of control
 **/

export const withFormControl = (Control, options={}) => ({
  name,
  value: initialValue,
  onChange,
  ...props
}) => {
  const [field, meta, helpers] = useField({
    name,
    type: props.type || options.type,
  });
  const { value } = field;
  const { error } = meta;
  const { setValue } = helpers;
  const [initialized, setInitialized] = useState();

  const { submitCount } = useFormikContext();
  const showErrors = submitCount > 0; // show errors only after initial submit

  // cascade through fallbacks for best initial value
  const defaultValue = useMemo(() => (
    options.defaultValue != null ? options.defaultValue :
    options.type === 'text' ? '' :
    options.type === 'checkbox' ? false :
    options.type === 'select' && props.allowMultiple ? [] :
    null
  ),[props.allowMultiple]);

  // support declaration of initialValues on inputs
  useMount(() => {
    if(initialValue != null && initialValue !== value) setValue(initialValue);
    else if(value == null) setValue(defaultValue);
    setInitialized(true);
  });

  const handleChange = ({[name]:newValue}) => {
    onChange?.({[name]:newValue});
    setValue(newValue);
  };

  return initialized && (
    <Control
      {...props}
      name={name}
      value={value != null ? value : defaultValue}
      isControlled={true}
      onChange={handleChange}
      error={showErrors ? error : undefined}
    />
  );
};
