import React, { useState, useRef, useEffect } from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import { get } from 'lodash';
import formCSS from '../StyledForm.module.scss';
import { InputWrapper } from '../InputWrapper';

const numbersOnly = (e) => {
  if(e.which === 46) return; // period
  if(e.which === 13) return e.target.blur(); // enter
  if (e.which < 48 || e.which > 57) e.preventDefault(); // any non-numbers
  return;
};

const getFormattedString = (value, blur) => {
  if(blur) return value ? String(Number(value).toFixed(2)) : '';
  if(value === '.') return '0.';
  if(value === '' || isNaN(String(value).charAt(0))) return '';
  let segments = value.toString().replace(/[^0-9.]+/g, '').split('.');
  while(segments.length > 2) segments.pop();
  if(segments.length === 2 && segments[1].length > 2) segments[1] = segments[1].substring(0,2);
  return segments.join('.');
}

export const FieldCurrency = (props) => {
  const { input } = props;
  const [displayValue, setDisplayValue] = useState(getFormattedString((input.value || 0)/100, true));
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.addEventListener('keypress', numbersOnly);
    return ((e) => () => e.removeEventListener('keypress', numbersOnly))(inputRef.current);
  // eslint-disable-next-line
  },[]); // only run on mount

  const onBlur = (e, field) => {
    setDisplayValue(getFormattedString(e.target.value,true));
    field.onBlur(e);
  };

  const onChange = (inputValue, setFieldValue) => {
    const formatted = updateDisplay(inputValue);
    const value = parseInt((formatted || 0)*10*10);
    input.onChange && input.onChange(value, setFieldValue);
    return setFieldValue(input.name, value, true);
  };

  const updateDisplay = (value, blur) => {
    const formatted = getFormattedString(value, blur);
    if(formatted !== displayValue) setDisplayValue(formatted);
    return formatted;
  };

  return (
    <InputWrapper {...props}>
      <Field
        name={input.name}
        >
        {(renderProps) => {
          const { field, form: { errors, touched, setFieldValue } } = renderProps;
          const classes = classNames(formCSS.currency,{
            [formCSS.has_error]: get(errors, input.name) && get(touched, input.name),
            [formCSS.has_value]: field.value,
          });
          // if this field's value has changed from another component (using setFieldValue)
          // we need to recognize that the display value doesn't match the internal value
          // and force an update of the display value. Timeout needed so child doesn't call a parent
          // component to rerender during it's own render cycle.
          const hasFocus = inputRef.current === document.activeElement;
          if(!hasFocus) setTimeout(() => updateDisplay(field.value/100,true),0);
          return (
            <>
              <input
                name={field.name}
                value={field.value}
                type="hidden"
                readOnly
                style={{display: 'none'}}
              />
              <input
                onBlur={e => onBlur(e, field)}
                onChange={e => onChange(e.target.value, setFieldValue)}
                className={classes}
                placeholder={input.placeholder}
                type='text'
                id={`${field.name}_display`}
                readOnly={input.readOnly}
                ref={inputRef}
                name={`${field.name}_display`}
                value={displayValue}
              />
              <label className={formCSS.currency_dollar} htmlFor={input.name}>&#36;</label>
            </>
          );
        }}
      </Field>
    </InputWrapper>
  );
}
