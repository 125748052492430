
import React from 'react'
import { format } from '@moved/services';

import { PetDetails } from './PetDetails';

export const getRequestFields = request => {
  if(!request) return [];
  return [
    ...request.pets
      .filter(pet => pet?.pet_type != null) // hide pets that were never started
      .map(pet => ({
        label: pet.name,
        value: (<PetDetails pet={pet} />),
        icon: { symbol: format.capitalize(pet.pet_type), library: 'animals'}
      })),
    {
      label: 'Reason for decline',
      value: request?.notes,
      icon: { symbol: 'File', library: 'files' },
    },
  ];
};
