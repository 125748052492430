import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import { useOptionalControl } from '@moved/services';
import { Text } from '../Text';
import { Options } from '../Select';

import CSS from './TimeSelect.module.scss';

// Generates time options in 15-minute increments (predefined options)
const TIME_OPTIONS = (() => {
  const times = [];
  const startOfDay = moment().startOf('day');

  for (let i = 0; i < 96; i++) {
    times.push({
      value: startOfDay.clone().add(i * 15, 'minutes').format('HH:mm'), // 24-hour format for value
      label: startOfDay.clone().add(i * 15, 'minutes').format('hh:mm A') // 12-hour format with AM/PM for label
    });
  }
  return times;
})();

const parseTimeValue = (inputTime) => {
  if (!inputTime) return;

  let formattedInput = inputTime.trim().toLowerCase();

  // Check for and remove 'am', 'pm', 'a', or 'p' from the end of the string
  let suffix = '';
  const ampmRegex = /(am|pm|a|p)$/;
  const match = formattedInput.match(ampmRegex);
  if (match) {
    suffix = match[0].startsWith('a') ? ' AM' : ' PM';
    formattedInput = formattedInput.replace(ampmRegex, '').trim();  // Remove the suffix from the time string
  }

  // Handle numeric input (1-4 digits) like '154' -> '1:54 AM', '1433' -> '2:33 PM'
  if (/^\d{1,4}$/.test(formattedInput)) {
    if (formattedInput.length === 1 || formattedInput.length === 2) {
      // If input is 1 or 2 digits, treat it as hour only (e.g., '1' -> '01:00')
      formattedInput = `${formattedInput.padStart(2, '0')}:00`;
    } else if (formattedInput.length === 3) {
      // If input is 3 digits, treat like '423' -> '4:23'
      formattedInput = `${formattedInput[0]}:${formattedInput.slice(1)}`;
    } else if (formattedInput.length === 4) {
      // If input is 4 digits, treat like '1521' -> '15:21'
      formattedInput = `${formattedInput.slice(0, 2)}:${formattedInput.slice(2)}`;
    }
  }

  // Append AM/PM suffix if it was present, otherwise try parsing directly
  let parsedTime = moment(formattedInput + suffix, ['HH:mm A', 'HH:mmA',  'h:mm A', 'h:mmA', 'HH:mm', 'h:mm'], true);

  // If valid, return the time in 'HH:mm' format
  if (parsedTime.isValid()) {
    return parsedTime.format('HH:mm');
  }

  // If invalid time, return null
  return null;
};

const convertTimeValueToLabel = (value) => {
  const parsedTime = moment(value, 'HH:mm', true);
  return parsedTime.isValid() ? parsedTime.format('hh:mm A') : '';
};

export const TimeSelect = ({
  name,
  value,
  isControlled,
  label,
  hint,
  icon,
  iconPosition,
  disabled,
  readOnly,
  error,
  onChange,
  className
}) => {
  const [selected, setSelected] = useOptionalControl(parseTimeValue(value), isControlled);
	const [query, setQuery] = useState(selected ? convertTimeValueToLabel(selected) : '');
  const [inputError, setInputError] = useState('');
  const [hasFocus, setHasFocus] = useState(false);

  const elementRef = useRef();

  useEffect(() => {
    if (isControlled && selected) {
      setQuery(convertTimeValueToLabel(selected));
    }
  }, [isControlled, selected]);

  const updateQuery = (queryString) => {
    setQuery(queryString);

    if (selected) {
      setSelected(); // Clear previous selection
    }
  };

  const setInput = () => {
    if (!query) {
      setSelected(null);
      setInputError('');
      onChange?.({ [name]: null });
      return;
    }

    const timeValue = parseTimeValue(query);
    if (!timeValue && query) {
      setSelected(null);
      setInputError('Invalid time. Use formats like 1:23 PM or 14:31');
      onChange?.({ [name]: null });
    } else if (timeValue !== selected) {
      if(!isControlled) setQuery(convertTimeValueToLabel(timeValue));  // Update the input field with formatted time label
      setSelected(timeValue); // Set selected to the time value
      setInputError('');  // Clear any error
      onChange?.({ [name]: timeValue });
    }
  };


  const handleSelect = (option) => {
    if (disabled || readOnly) return;
    if(option.value === selected) return;

    if(!isControlled) setQuery(option.label);
    setSelected(option.value);
    onChange?.({ [name]: option.value });
    setInputError('');

    setHasFocus(false); // Close dropdown after selection
  };


  useEffect(() => {
    if (!hasFocus) return;
    const handleClickOutside = (e) => {
      if (elementRef?.current && !elementRef.current.contains(e.target)) {
        setHasFocus(false);  // Close the dropdow
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [hasFocus]);

  return (
    <div className={classNames(CSS.container, className)}>
      <Text
        name={name}
        label={label}
        hint={hint}
        error={inputError || error}
        value={query}
        isControlled={true}
        icon={icon}
        iconPosition={iconPosition}
        onChange={({ [name]: queryString }) => updateQuery(queryString)}
        onFocus={() => !readOnly && setHasFocus(true)}
        onBlur={setInput}
        disabled={disabled}
        readOnly={readOnly}
        ref={elementRef}
      />

      <Options
        options={TIME_OPTIONS} // Always show all options
        isOpen={hasFocus}
        onSelect={handleSelect}
        className={CSS.dropdown}
      />
    </div>
  );
};

TimeSelect.propTypes = {
  /** Name to use for the form input */
  name: PropTypes.string.isRequired,
  /** Value to use for this input (only initial value if not controlled) */
  value: PropTypes.any,
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** Label text for the input */
  label: PropTypes.string,
  /** Second line of text */
  hint: PropTypes.string,
  /** Icon to display in the input */
  icon: PropTypes.shape({
    symbol: PropTypes.string,
    library: PropTypes.string,
  }),
  /** Icon position relative to text */
  iconPosition: PropTypes.oneOf(['left', 'right']),
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** Flag to readonly the input */
  readOnly: PropTypes.bool,
  /** Error message to display for this input */
  error: PropTypes.string,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** Class name to add to the input container */
  className: PropTypes.string,
};
