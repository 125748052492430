import React from 'react';
import { get } from 'lodash';

import { format } from '@moved/services';

export const getLedgerFields = (taskable) => {
  if(!taskable) return [];
  const { costs_by_category=[], payments=[] } = taskable;
  const categoriesToDisplay = costs_by_category.filter(({ costs }) => (costs ?? []).length)
  const totalOwed = get(taskable,'total_amount_owed');
  const totalPaid = get(taskable,'total_amount_paid');
  const remainingOwed = get(taskable,'remaining_unpaid_amount');
  // if no costs or payments quick exit and show nothing
  if(costs_by_category.length === 0 && payments.length === 0) return [
    {
      label: 'No move-in costs or payments found.',
      isTitle: true,
    }
  ];
  return [
    {
      label: (<div className='headingS contentPrimary'>Costs</div>),
      isTitle: true,
      hideDivider: true,
    },
    categoriesToDisplay.length === 0 && {
      label: 'No move-in costs specified.',
      isTitle: true,
    },
    ...categoriesToDisplay.reduce((fields, { category, costs }) => (
      costs.length === 1 ? fields.concat([{
        label: (
          <div className='stackHorizontal gap-8 items-center'>
            <span>{ costs.at(0)?.description }</span>
            { category.subtitle && (
              <span className='labelS'>({ category.subtitle })</span>
            )}
          </div>
        ),
        value: format.currency(costs.at(0)?.amount),
      }]) : fields.concat([
        {
          label: (
            <div className='stackHorizontal gap-8 items-center'>
              <span className='labelM contentPrimary'>{ category.display_name }</span>
              { category.subtitle && (
                <span className='labelS contentSecondary'>({ category.subtitle })</span>
              )}
            </div>
          ),
          isTitle: true,
          hideDivider: true,
        },
        ...costs.map((cost, index) => ({
          label: (<div className='paddingLeft-18'>{ cost.description }</div>),
          value: format.currency(cost.amount),
          hideDivider: (index < (costs.length - 1)),
        }))
      ])
    ),[]),
    totalOwed && {
      label: (<div className='contentPrimary'>Total due at move-in</div>),
      value: (<strong>{ format.currency(totalOwed) }</strong>),
      hideDivider: true,
    },
    {
      label: (<div className='headingS contentPrimary marginTop-12'>Payments</div>),
      isTitle: true,
      hideDivider: true,
    },
    payments.length === 0 && {
      label: 'No payments found.',
      isTitle: true,
    },
    ...payments.map((payment,i) => ({
      label: (<>{payment.description} ({format.date(payment.transaction_date,'date')})</>),
      value: format.currency(-payment.amount),
      hideDivider: (i < (payments.length - 1)),
    })),
    totalPaid && {
      label: 'Total Paid',
      value: format.currency(-totalPaid),
    },
    {
      label: (<div className='contentPrimary'>Remaining Balance</div>),
      value: (<strong>{ format.currency(remainingOwed) }</strong>),
      hideDivider: true,
    },
  ].filter(v=>v);
};

export const getRequestFields = request => {
  return [
    ...getLedgerFields(request),
    {
      label: 'Reason for decline',
      value: request?.notes,
    },
  ]
}
