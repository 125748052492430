import React, { useMemo } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { usePrevious } from 'react-use';
import { Transitions } from '@moved/ui';
import { ChildRoutes } from './ChildRoutes';

export const RenderRoutes = ({ routes, basePath, ...passProps }) => {
  const location = useLocation();

  let activeRoute = {};
  let params = {};

  routes.some(route => {
    let routeFullPath = `${basePath}${route.path}`.replace(/\/+/g, '/');
    const match = matchPath(location.pathname, { path: routeFullPath, exact: route.exact });
    if(match) {
      activeRoute = route;
      params = match.params;
    }
    return match != null;
  });

  let pageKey = (activeRoute.viewKey && activeRoute.viewKey(params)) || location.key;

  const previousRoute = usePrevious(activeRoute);
  const activeTransition = useMemo(
    () => activeRoute?.transitions?.in ?? previousRoute?.transitions?.out ?? activeRoute?.transition,
    [activeRoute]
  );

  return (
    <Transitions
      pageKey={pageKey}
      {...location.state}
      transition={activeTransition}
    >
      { transitionState => (
        <ChildRoutes
          routes={routes}
          basePath={basePath}
          location={location}
          { ...passProps }
          transitionState={transitionState}
        />
      )}
    </Transitions>
  )
};
