import { matchPath } from 'react-router-dom';
import { cloneDeep } from 'lodash';

const DEFAULT_MATCH_OPTIONS = { exact: true };
const NO_BREADCRUMB = 'NO_BREADCRUMB';

/**
 * Takes a route array and recursively flattens it IF there are
 * nested routes in the config.
*/
const flattenRoutes = (routes) => {

  const flat = (routes)
    .reduce((arr, route) => {
      if (route.children) {
        const children = cloneDeep(route.children);
        const neat = children.map(child => ({...child, path: route.path+child.path}));
        return arr.concat([route, ...flattenRoutes(neat)]);
      }
      return arr.concat(route);
    }, []);
    return flat;
};

/**
 * Loops through the route array (if provided) and returns a
 * user-provided breadcrumb
*/
const getBreadcrumbMatch = ({
  location,
  pathSection,
  routes,
}) => {
  let breadcrumb;

  // Loop through the route array and see if the user has provided a custom breadcrumb.
  routes.some(({ breadcrumb: userProvidedBreadcrumb, matchOptions, path, ...rest }) => {
    if (!path) {
      throw new Error('withBreadcrumbs: `path` must be provided in every route object');
    }

    const match = matchPath(pathSection, { ...(matchOptions || DEFAULT_MATCH_OPTIONS), path });

    // If user passed breadcrumb: null OR custom match options to suppress a breadcrumb
    // we need to know NOT to add it to the matches array
    if ((match && userProvidedBreadcrumb === null) || (!match && matchOptions)) {
      breadcrumb = NO_BREADCRUMB;
      return true;
    }

    if (match) {
      // If no breadcrumb provided, set NO_BREADCRUMB
      if (!userProvidedBreadcrumb) {
        breadcrumb = NO_BREADCRUMB;
        return true;
      }

      breadcrumb = {
        breadcrumb: userProvidedBreadcrumb,
        componentProps: { match, location, key: match.url, ...rest },
      };

      return true;
    }
    return false;
  });

  // User provided a breadcrumb prop, or we generated one above.
  if (breadcrumb) {
    return breadcrumb;
  }

  // If there was no breadcrumb provided
  return NO_BREADCRUMB;
};

/**
 * Splits the pathname into sections, then search for matches in the routes
*/
export const getBreadcrumbs = ({ routes, location, options = {} }) => {
  const matches = [];
  const { pathname } = location;

  const flatRoutes = flattenRoutes(routes);

  pathname
    .split('?')[0]
    // Split pathname into sections.
    .split('/')
    // Reduce over the sections and call `getBreadcrumbMatch()` for each section.
    .reduce((previousSection, currentSection, index) => {
      // Combine the last route section with the currentSection.
      const pathSection = !currentSection ? '/' : `${previousSection}/${currentSection}`;

      // Ignore trailing slash or double slashes in the URL
      if (pathSection === '/' && index !== 0) {
        return '';
      }

      const breadcrumb = getBreadcrumbMatch({
        location,
        pathSection,
        routes: flatRoutes,
        ...options,
      });

      // Add the breadcrumb to the matches array
      // unless the user has explicitly passed.
      // { path: x, breadcrumb: null } to disable.
      if (breadcrumb !== NO_BREADCRUMB) {
        matches.push(breadcrumb);
      }

      return pathSection === '/' ? '' : pathSection;
    }, '');

  return matches;
};
