import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'

import { useUser, userService } from '@moved/services';
import { LoaderOverlay } from '@moved/ui';

export const DashboardRedirect = () => {
  const { user } = useUser();
  const history = useHistory();
  // trigger redirect
  useEffect(() => {
    if(!user) return;
    userService.redirect(user,history);
  },[user,history]);

  return <LoaderOverlay/>;
};
