import React from 'react';
import classNames from 'classnames';

import { useModal } from '@moved/services';

import { Button } from '../controls/Button';
import CSS from './Modal.module.scss';

// Temporarily prefixed 'Sondheim' to avoid name collision
// will replace Modal once we have bandwidth to migrate existing modals
export const SondheimModal = ({ children, id, className }) => {
  return (
    <div id={id} className={classNames(CSS.sondheim,className)}>
      <div className={CSS.modal}>
        { children }
      </div>
    </div>
  );
};

export const GlassModal = ({ children, id, className }) => {
  return (
    <div id={id} className={classNames(CSS.glass,className)}>
      <div className={CSS.modal}>
        { children }
      </div>
    </div>
  );
};

export const ModalTitle = ({ className, children }) => {
  const { close, layer } = useModal();
  return (
    <div className={classNames(CSS.titleBar, className)}>
      <h3 className={CSS.title}>{ children }</h3>
      <Button
        size={layer?.options?.glass ? 'large' : 'small'}
        color='secondary'
        icon={{ library: 'navigation', symbol: 'Close' }}
        className={CSS.close}
        onClick={() => close(false)}
      />
    </div>
  );
};

export const ModalContent = ({ className, children }) => (
  <div className={classNames(CSS.content, className)}>{ children }</div>
);

export const ModalActions = ({ className, children }) => (
  <div className={classNames(CSS.actions, className)}>
    { children }
  </div>
);
