import React from 'react';

import { CustomerSupportEmailLink } from '../CustomerSupportEmailLink';
// TODO: fix this to not import a direct style sheet from deep inside moved/ui lib
import CSS from '@moved/ui/src/balazs/components/Modal/Modal.module.scss';

export const AboutUsModal = () => {
  return (
    <>
      <h2 className={CSS.title}>About Us</h2>
      <div className={CSS.content}>
        <p>
          Moving is exciting. But planning and coordinating it is frustrating.
          For this, we created Moved. We pair you up with a personal assistant
          who handles the frustrating parts of moving for you.
        </p>
        <p>
          From booking a mover and packing your stuff, to buying a mattress and
          home supplies... your assistant has you covered.
        </p>
        <p>So sit back, relax, and get moved.</p>
        <p>
          Check out our <a href="https://moved.com/chapter-by-moved" target="_blank" rel="noopener noreferrer">blog</a>{' '}
          for moving tips and tricks. Keep up with us on{' '}
          <a href="https://facebook.com/movedapp" target="_blank" rel="noopener noreferrer">Facebook</a>,{' '}
          <a href="https://twitter.com/movedapp" target="_blank" rel="noopener noreferrer">Twitter</a>, and{' '}
          <a href="https://instagram.com/movedapp" target="_blank" rel="noopener noreferrer">Instagram</a>.{' '}
          Contact us via email anytime at <CustomerSupportEmailLink/>.
        </p>
      </div>
    </>
  )
};
