import { BaseTool } from '../BaseTool';
import CSS from './Spacing.module.scss';
import icon from './icon.svg';

export class Spacing extends BaseTool {

  /**
   * Allow Tool to have no content
   * @return {boolean}
   */
  static get contentless() {
    return true;
  }

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      title: 'Spacing',
      icon: `<img src="${icon}" alt="Button" height="24" width="24"/>`,
    };
  }

  constructor({ data, config, api, readOnly, block }) {
    super({ data, config, api, readOnly });

    this.block = block;

    /**
     * Internal data (set as defaults on construction)
     */
    this._data = {
      size: config.defaultSize || 'small',
    };

    // overrides internal data with initialization data
    this.data = data;
  }

  // render the tool inside the editor
  render() {
    const block = document.createElement('div');
    block.classList.add(CSS.spacer,CSS.editor,CSS[this.data.size]);
    this.element = block;
    return block;
  }

  renderSettings() {
    return [
      {
        name: 'small',
        label: 'Small',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="0">
          <path d="M2 11.5C2 10.6716 2.67157 10 3.5 10H20.5C21.3284 10 22 10.6716 22 11.5V12.5C22 13.3284 21.3284 14 20.5 14H3.5C2.67157 14 2 13.3284 2 12.5V11.5Z" fill="#151618"/>
          <path d="M2 5C2 4.44772 2.44772 4 3 4H21C21.5523 4 22 4.44772 22 5C22 5.55228 21.5523 6 21 6H3C2.44772 6 2 5.55228 2 5Z" fill="#C2C5C9"/>
          <path d="M2 19C2 18.4477 2.44772 18 3 18H21C21.5523 18 22 18.4477 22 19C22 19.5523 21.5523 20 21 20H3C2.44772 20 2 19.5523 2 19Z" fill="#C2C5C9"/>
        </svg>`,
        isActive: this.data.size === 'small',
        closeOnActivate: true,
        toggle: 'size',
        onActivate: () => this.toggleTune({ size: 'small' }),
      },
      {
        name: 'medium',
        label: 'Medium',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="0">
          <path d="M2 10.5C2 9.67157 2.67157 9 3.5 9H20.5C21.3284 9 22 9.67157 22 10.5V13.5C22 14.3284 21.3284 15 20.5 15H3.5C2.67157 15 2 14.3284 2 13.5V10.5Z" fill="#151618"/>
          <path d="M2 5C2 4.44772 2.44772 4 3 4H21C21.5523 4 22 4.44772 22 5C22 5.55228 21.5523 6 21 6H3C2.44772 6 2 5.55228 2 5Z" fill="#C2C5C9"/>
          <path d="M2 19C2 18.4477 2.44772 18 3 18H21C21.5523 18 22 18.4477 22 19C22 19.5523 21.5523 20 21 20H3C2.44772 20 2 19.5523 2 19Z" fill="#C2C5C9"/>
        </svg>`,
        isActive: this.data.size === 'medium',
        closeOnActivate: true,
        toggle: 'size',
        onActivate: () => this.toggleTune({ size: 'medium' }),
      },
      {
        name: 'large',
        label: 'Large',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="0">
          <path d="M2 9.5C2 8.67157 2.67157 8 3.5 8H20.5C21.3284 8 22 8.67157 22 9.5V14.5C22 15.3284 21.3284 16 20.5 16H3.5C2.67157 16 2 15.3284 2 14.5V9.5Z" fill="#151618"/>
          <path d="M2 5C2 4.44772 2.44772 4 3 4H21C21.5523 4 22 4.44772 22 5C22 5.55228 21.5523 6 21 6H3C2.44772 6 2 5.55228 2 5Z" fill="#C2C5C9"/>
          <path d="M2 19C2 18.4477 2.44772 18 3 18H21C21.5523 18 22 18.4477 22 19C22 19.5523 21.5523 20 21 20H3C2.44772 20 2 19.5523 2 19Z" fill="#C2C5C9"/>
        </svg>`,
        isActive: this.data.size === 'large',
        closeOnActivate: true,
        toggle: 'size',
        onActivate: () => this.toggleTune({ size: 'large' }),
      },
    ]
  }

  /**
   * Update the data, trigger an onchange
   */
  toggleTune(data) {
    this.data = data;
    this.block.dispatchChange();
  }

  /**
   * List data setter
   */
  set data(newData) {
    this._data = {
      ...this._data,
      ...newData,
    };
    const existing = this.element;
    if(existing) existing.parentNode.replaceChild(this.render(),existing);
  }

  /**
   * Return List data
   */
  get data() {
    return this._data;
  }

  /**
   * Read the latest data from the UI and save it to the block data
   */
  save() {
    return this._data;
  }

}
