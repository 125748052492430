import { format } from '@moved/services';

export const getRequestFields = (request) => {
  return [
    request?.profile_type && {
      label: 'Profile Type',
      value: format.title(format.words(request?.profile_type)),
      icon: { symbol: 'User', library: 'general' },
    },
    request?.due_date && {
      label: 'Due Date',
      value: format.date(request?.due_date),
      icon: { symbol: 'Date', library: 'code' },
    },
    request?.completed_at && {
      label: 'Date of completion',
      value: format.date(request?.completed_at),
      icon: { symbol: 'Done-circle', library: 'code' },
    },
    {
      label: 'Status',
      value: request?.status === 'in-review' ? 'In Review by PetScreening' : format.title(format.words(request?.status)),
      icon: { symbol: 'Loading', library: 'code', },
    },
  ].filter(v=>v);
};
