import React, { useEffect, useRef } from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import { get } from 'lodash';

import { format } from '@moved/services';

import formCSS from '../StyledForm.module.scss';
import { InputWrapper } from '../InputWrapper';

const numbersOnly = (e) => {
  if(e.which === 13) return e.target.blur(); // enter
  if(e.which < 48 || e.which > 57) e.preventDefault(); // any non-numbers
  return;
};

const validatePhone = (value) => {
  if (value === '') return null;
  const minLength = value[0] === '1' ? 11 : 10;
  if(value.length < minLength) return 'Must be a valid US phone number';
  return;
};

export const FieldTel = (props) => {
  const { input } = props;

  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.addEventListener('keypress', numbersOnly);
    return ((e) => () => e.removeEventListener('keypress', numbersOnly))(inputRef.current);
  // eslint-disable-next-line
  },[]); // only run on mount

  const onBlur = (e, setFieldTouched) => {
    return setFieldTouched(input.name, true, true);
  };

  const onChange = (inputValue, setFieldValue) => {
    const formatted = format.phone(inputValue);
    const value = String(formatted).replace(/[^0-9]+/g, '');
    input.onChange && input.onChange(value, setFieldValue);
    return setFieldValue(input.name, value, true);
  };

  return (
    <InputWrapper {...props}>
      <Field
        name={input.name}
        validate={validatePhone}
        >
        {(renderProps) => {
          const { field, form: { errors, touched, setFieldValue, setFieldTouched } } = renderProps;
          const classes =  classNames({
            [formCSS.has_error]: get(errors, input.name) && get(touched, input.name),
            [formCSS.has_value]: field.value,
          });
          return (
            <>
              <input
                name={field.name}
                value={field.value}
                type="hidden"
                readOnly
                style={{display: 'none'}}
              />
              <input
                onBlur={e => onBlur(e, setFieldTouched)}
                onChange={e => onChange(e.target.value, setFieldValue)}
                className={classes}
                placeholder={input.placeholder}
                type='text'
                id={`${field.name}_display`}
                readOnly={input.readOnly}
                ref={inputRef}
                name={`${field.name}_display`}
                value={format.phone(field.value)}
              />
            </>
          );
        }}
      </Field>
    </InputWrapper>
  );
};
