import React from 'react';
import { createRoot } from 'react-dom/client';
import { ie11Support } from '@moved/services';

import { Root } from './core';

const root = createRoot(document.getElementById('app'));
root.render(<Root />);

ie11Support.svg();
