import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { get, set, toPath, noop, isEmpty } from 'lodash';

import { fieldService, request } from '@moved/services';

import { StyledForm } from './StyledForm';
import { RenderFields } from './RenderFields';

// Helper functions
function setShape(current, part, schema) {
  if ( current.shape || current.of ){
    return current.shape
        ? current.shape({ [part]: schema })
        : current.of(schema);
  }
  throw new Error('cannot deep set a schema value on a primitive schema')
}

function joinSegment(segments) {
  const DIGIT_REGEX = /^\d+$/;
  return segments.reduce(function(path, part) {
    return (
      path +
      (isQuoted(part) || DIGIT_REGEX.test(part)
        ? '[' + part + ']'
        : (path ? '.' : '') + part)
    )
  }, '')
}

function isQuoted(str) {
  return (
    typeof str === 'string' && str && ["'", '"'].indexOf(str.charAt(0)) !== -1
  )
}

// Register custom method for Yup to "deep set" validations
Yup.addMethod(Yup.mixed, 'setIn', function setIn(path, setSchema) {
  var parts =  toPath(path)
    , idx = parts.length - 1
    , newSchema, current
    , part, maybeNext, next;

  let getNextCurrent = () => { return idx === 0 ? this : Yup.reach(this, joinSegment(parts.slice(0, idx)));};

  for (; idx >= 0; idx--) {
    part = parts[idx];

    current = getNextCurrent();

    maybeNext = newSchema;
    next = maybeNext;

    if (!maybeNext) {
      next = typeof setSchema === 'function' ? setSchema(Yup.reach(current, part)) : setSchema
    }

    newSchema = setShape(current, part, next)
  }

  return newSchema;
});

// Debounce function to prevent hitting server on each keystroke
const asyncDebouncer = (fn, wait, callFirst) => {
  var timeout;
  return function() {
    return new Promise(async (resolve) => {
      if (!wait) {
        const result = await fn.apply(this, arguments);
        resolve(result);
      }

      var context = this;
      var args = arguments;
      var callNow = callFirst && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(async function() {
        timeout = null;
        if (!callNow) {
          const result = await fn.apply(context, args);
          resolve(result);
        }
      }, wait);

      if (callNow) {
        const result = await fn.apply(this, arguments);
        resolve(result);
      }
    });
  };
};
// Cache previous value and only hit server if there's been a change
const cacheTest = (asyncValidate) => {
  let _valid = false;
  let _value = '';

  return async (value, url, input, createError) => {
    if (value !== _value) {
      const response = await asyncValidate(value, url, input, createError);
      _value = value;
      _valid = response;
      return response;
    }
    input.pending = false;
    return _valid;
  };
};
// Function to handle hitting url and checking for valid value
const remoteValidationFunction = asyncDebouncer(async (data, url, input, createError) => {
  if(!data) {
    input.pending = false;
    return false;
  }
  const response = await request.post(url,{ [input.name]: data })
    .then(res => {
        input.pending = false;
        return true;
    }).catch(error => {
        const errorMsg = get(error,'response.data.errors[0].message');
        input.pending = false;
        if(errorMsg) return createError({ message: errorMsg });
        else return false;
    });
  return response;
}, 800);


const DynamicForm = (props) => {

  const { children } = props;

  const uniqueRemoteValidation = useRef(cacheTest(remoteValidationFunction));

  const setPropFieldValidation = (validation) => {
    // Async validation for dynamic validations based on values
    return Yup.lazy(values => {

      // Set up outer object schema for the entire form data object
      let required = Yup.object().shape({});
      let remote = Yup.object().shape({});

      // Loop through each field and set up required validation if set
      props.fields.forEach(input => {
        if(fieldService.isRequired(input, values) || fieldService.hasRemoteValidation(input, values)) {
          let nameArray = input.name.split('.');
          let placement = null;
          nameArray.forEach((el, index) => {
            placement = placement ? `${placement}.${el}` : el;
            if(!(index+1 >= nameArray.length)) {
              //Create object schema if attribute doesn't exist
              try {Yup.reach(required, placement)}
              catch(error) {
                // Split on bracket, indicates array
                if(el.includes('[')) {
                  //TODO: Doesn't work for array inside array, need to change the split for that [Evie]
                  required = required.setIn(placement.split('[')[0], Yup.array().of(Yup.object().shape({})));
                }
                required = required.setIn(placement, Yup.object().shape({}));
              }
            } else {
              if(fieldService.isRequired(input, values)) {
                // Set the last part of the string (the input itself) as required
                const msg = input.required === true ? input.label + ' is required' : input.required;
                switch(input.type) { // use switch to handle future types that need *typed* required logic
                  case 'checkbox':
                  case 'toggle':
                    required = required.setIn(placement, Yup.boolean().oneOf([true], msg));
                    break;
                  case 'currency':
                  case 'difference':
                  case 'number':
                    required = required.setIn(placement, Yup.number().required(msg));
                    break;
                  case 'integer':
                    required = required.setIn(placement, Yup.number().integer(`${input.label} must be an integer`).required(msg));
                    break;
                  case 'date':
                  case 'datePicker':
                    required = required.setIn(placement, Yup.date().typeError(msg).required(msg));
                    break;
                  case 'password':
                    required = required.setIn(placement, Yup.string().required(msg).min(8, 'Must be at least 8 characters'))
                    break;
                  case 'array':
                    required = required.setIn(placement, Yup.string().required(msg))
                    break;
                  default:
                    required = required.setIn(placement, Yup.string().required(msg));
                    break;
                };
              }
              // Remote validation
              if(fieldService.hasRemoteValidation(input, values)) {
                const { remoteValidation } = input;
                input.pending = 'Verifying...';

                remote = remote.setIn(placement, Yup.string()
                  .test(
                    `${input.name}_remote`,
                    remoteValidation.msg,
                    function (data) {
                      return uniqueRemoteValidation.current(data, remoteValidation.url, input, this.createError);
                    }
                  )
                );
              }
            }
          });
        }
      });
      // Combine required fields with remote validation
      required = required.concat(remote);
      // Combine automated validation with the custom validation prop passed in
      if(validation) return validation.concat(required);
      // Otherwise just return the automatic validations
      return required;
    });
  };

  const getInitialTouched = (inputs) => {
    const touched = {};
    inputs.forEach(field => {
      if(field.remoteValidation) touched[field.name] = true;
    });
    return touched
  }

  // Handle nulls for all field types
  const getInitialValues = (inputs) => {
    const initialValues = {};
    inputs.forEach(field => {
      if(field && field.name && !initialValues[field.name]) {
        // Set proper default value if initial value is null or undefined
        if(field.value === null || typeof field.value === 'undefined') {
          if(field.type === 'checkbox' || field.type === 'toggle') field.value = false;
          else if(field.type === 'array' || field.type === 'itemList') field.value = [];
          else if(field.type === 'address') field.value = {};
          else if(field.type === 'editor') field.value = {
            blocks: [],
          };
          else if(field.type === 'content') field.value = null;
          else field.value = '';
        }

        if((field.type === 'array' || field.type === 'itemList') && field.options === null) field.options = [];
        if(field.type !== 'content') {
          set(initialValues, field.name, field.value);
        }
      }
    });
    return initialValues;
  };

  return (
      <StyledForm
        id={props.id}
        className={props.className}
        onSubmit={props.onSubmit}
        onChange={props.onChange}
        validate={props.validate}
        formStyle={props.formStyle}
        validation={setPropFieldValidation(props.validation)}
        initialTouched={getInitialTouched(props.fields)}
        initialValues={getInitialValues(props.fields)}
        autoComplete={props.autocomplete}
        enableReinitialize={props.reinitialize}
        disabled={props.disabled}
      >
        {(formik) => (
          <>
            <RenderFields fields={props.fields} form={formik} formStyle={props.formStyle}/>
            { children && typeof children === "function" ? children(formik) : children }
          </>
        )}
      </StyledForm>
  );
}

DynamicForm.propTypes = {
  /** ID of the form element, used to submit button */
  id: PropTypes.string.isRequired,
  /** Determines the style preset for the form (options are 'stacked','underline','overlap','default') */
  formStyle: PropTypes.string,
  /** Array of JSON object defining the form inputs */
  fields: PropTypes.array.isRequired,
  /** Validation object */
  validation: PropTypes.object,
  /** handle change in form */
  onChange: PropTypes.func,
  /** onSubmit handler in parent that activates when valid form submits */
  onSubmit: PropTypes.func,
  /** ID of the form element, used to submit button */
  autocomplete: PropTypes.string,
};

DynamicForm.defaultProps = {
  formStyle: 'default',
  onChange: noop,
  autocomplete: null,
}

export { DynamicForm };
