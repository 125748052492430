import axios from 'axios';
import { get } from 'lodash';
import moment from 'moment';

import { getToken, refreshToken } from '../contexts/UserContext.js';
import { userService } from '../services/userService';

export const request = axios.create({
  baseURL: process.env.REACT_APP_API_V2_URL
});

// Set request defaults
request.defaults.headers.common['Content-Type'] = 'application/json';
request.defaults.headers.common['App'] = 'web';
request.CancelToken = axios.CancelToken;

// Refresh Token Interceptor Helpers
let refreshing;
const shouldAuthIntercept = (config) => {
  const { tokenExpiration } = getToken();
  return (
    !config.allowExpired && // endpoint config override
    (refreshing || // intercept all requests until existing refresh is resolved
      (tokenExpiration && moment.utc(tokenExpiration).isBefore()) // if current token has expired already
    )
  );
};

// Refresh Token Interceptor
request.interceptors.request.use(config => {
  if(shouldAuthIntercept(config)) {
    if(!refreshing) refreshing = refreshToken(); // create refresh request if one doesn't exist
    return refreshing.then(() => {
      // update the original request Authorization header with refreshed one
      config.headers.Authorization = get(request,'defaults.headers.common.Authorization');
      return config;
    })
    .finally(() => refreshing = undefined);
  }
  else return config;
});

// Unauthorized API Interceptor Helpers
const shouldAuthRedirect = (response) => {
  return (
    get(response,'status') === 401  &&
    !get(response,'request.withCredentials') &&
    !get(response,'config.ignoreUnauthorized')
  );
};

// Unauthorized API global handler (redirect to signin)
request.interceptors.response.use(
  response => response, // don't do anything to successful responses
  error => {
    if(shouldAuthRedirect(get(error,'response'))) userService.redirect();
    return Promise.reject(error);
  }
);
