import DefaultList from '@editorjs/list';
import { createRoot } from 'react-dom/client';

import { ListEditor } from './ListEditor';
import CSS from './List.module.scss';
import icon from './icon.svg';

// extending existing 'List' class with overrides:
// https://github.com/editor-js/list/blob/master/src/index.js
export class List extends DefaultList {

  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      title: 'List',
      icon: `<img src="${icon}" alt="Button" height="24" width="24"/>`,
    };
  }

  constructor({ data, config, api, readOnly, block }) {
    super({ data, config, api, readOnly });

    this.block = block;

    /**
     * Internal data (set as defaults on construction)
     */
    this._data = {
      showHeading: false,
      columns: config.defaultColumns || 1,
      style: config.defaultStyle || 'unordered',
      heading: '',
      items: [],
    };

    // overrides internal data with initialization data
    this.data = data;
  }

  // render the tool inside the editor
  render() {
    this._elements.wrapper = document.createElement('div');
    const root = createRoot(this._elements.wrapper);
    root.render(<ListEditor tool={this}/>);
    return this._elements.wrapper;
  }

  renderSettings() {
    return [
      {
        name: 'unordered',
        label: 'Unordered',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="0">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9 6.5C9 5.67157 9.67157 5 10.5 5H19.5C20.3284 5 21 5.67157 21 6.5C21 7.32843 20.3284 8 19.5 8H10.5C9.67157 8 9 7.32843 9 6.5ZM9 11.5C9 10.6716 9.67157 10 10.5 10H19.5C20.3284 10 21 10.6716 21 11.5C21 12.3284 20.3284 13 19.5 13H10.5C9.67157 13 9 12.3284 9 11.5ZM10.5 15C9.67157 15 9 15.6716 9 16.5C9 17.3284 9.67157 18 10.5 18H19.5C20.3284 18 21 17.3284 21 16.5C21 15.6716 20.3284 15 19.5 15H10.5Z" fill="#151618"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7 6.5C7 7.32843 6.32843 8 5.5 8C4.67157 8 4 7.32843 4 6.5C4 5.67157 4.67157 5 5.5 5C6.32843 5 7 5.67157 7 6.5ZM7 11.5C7 12.3284 6.32843 13 5.5 13C4.67157 13 4 12.3284 4 11.5C4 10.6716 4.67157 10 5.5 10C6.32843 10 7 10.6716 7 11.5ZM5.5 18C6.32843 18 7 17.3284 7 16.5C7 15.6716 6.32843 15 5.5 15C4.67157 15 4 15.6716 4 16.5C4 17.3284 4.67157 18 5.5 18Z" fill="#C2C5C9"/>
        </svg>`,
        isActive: this._data.style === 'unordered',
        closeOnActivate: true,
        toggle: 'style',
        onActivate: () => this.toggleTune({ style: 'unordered' }),
      },
      {
        name: 'ordered',
        label: 'Ordered',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="0">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9 6.5C9 5.67157 9.67157 5 10.5 5H19.5C20.3284 5 21 5.67157 21 6.5C21 7.32843 20.3284 8 19.5 8H10.5C9.67157 8 9 7.32843 9 6.5ZM9 11.5C9 10.6716 9.67157 10 10.5 10H19.5C20.3284 10 21 10.6716 21 11.5C21 12.3284 20.3284 13 19.5 13H10.5C9.67157 13 9 12.3284 9 11.5ZM10.5 15C9.67157 15 9 15.6716 9 16.5C9 17.3284 9.67157 18 10.5 18H19.5C20.3284 18 21 17.3284 21 16.5C21 15.6716 20.3284 15 19.5 15H10.5Z" fill="#151618"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7 6.5C7 7.32843 6.32843 8 5.5 8C4.67157 8 4 7.32843 4 6.5C4 5.67157 4.67157 5 5.5 5C6.32843 5 7 5.67157 7 6.5ZM7 11.5C7 12.3284 6.32843 13 5.5 13C4.67157 13 4 12.3284 4 11.5C4 10.6716 4.67157 10 5.5 10C6.32843 10 7 10.6716 7 11.5ZM5.5 18C6.32843 18 7 17.3284 7 16.5C7 15.6716 6.32843 15 5.5 15C4.67157 15 4 15.6716 4 16.5C4 17.3284 4.67157 18 5.5 18Z" fill="#C2C5C9"/>
        </svg>`,
        isActive: this._data.style === 'ordered',
        closeOnActivate: true,
        toggle: 'style',
        onActivate: () => this.toggleTune({ style: 'ordered' }),
      },
      {
        name: 'heading',
        label: 'With heading',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="0">
          <rect x="4" y="5" width="16" height="3" rx="1.5" fill="#151618"/>
          <path fill-rule="evenodd" clip-rule="evenodd" fill="#C2C5C9" d="M5.5 10C4.67157 10 4 10.6716 4 11.5C4 12.3284 4.67157 13 5.5 13H12.5C13.3284 13 14 12.3284 14 11.5C14 10.6716 13.3284 10 12.5 10H5.5ZM5.5 15C4.67157 15 4 15.6716 4 16.5C4 17.3284 4.67157 18 5.5 18H18.5C19.3284 18 20 17.3284 20 16.5C20 15.6716 19.3284 15 18.5 15H5.5Z"/>
        </svg>`,
        isActive: this._data.showHeading,
        closeOnActivate: true,
        onActivate: () => this.toggleTune({ showHeading: !this._data.showHeading, heading: '' }),
      },
      {
        name: 'one-column',
        label: 'One column',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="0">
          <path d="M2.5 5C1.67157 5 1 5.67157 1 6.5V17.5C1 18.3284 1.67157 19 2.5 19H5.5C6.32843 19 7 18.3284 7 17.5V6.5C7 5.67157 6.32843 5 5.5 5H2.5Z" fill="#151618"/>
          <path d="M9 6.5C9 5.67157 9.67157 5 10.5 5H13.5C14.3284 5 15 5.67157 15 6.5V17.5C15 18.3284 14.3284 19 13.5 19H10.5C9.67157 19 9 18.3284 9 17.5V6.5Z" fill="#C2C5C9"/>
          <path d="M17 6.5C17 5.67157 17.6716 5 18.5 5H21.5C22.3284 5 23 5.67157 23 6.5V17.5C23 18.3284 22.3284 19 21.5 19H18.5C17.6716 19 17 18.3284 17 17.5V6.5Z" fill="#C2C5C9"/>
        </svg>`,
        isActive: this._data.columns === 1,
        closeOnActivate: true,
        toggle: 'columns',
        onActivate: () => this.toggleTune({ columns: 1 }),
      },
      {
        name: 'two-column',
        label: 'Two columns',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="0">
          <path d="M2.5 5C1.67157 5 1 5.67157 1 6.5V17.5C1 18.3284 1.67157 19 2.5 19H5.5C6.32843 19 7 18.3284 7 17.5V6.5C7 5.67157 6.32843 5 5.5 5H2.5Z" fill="#151618"/>
          <path d="M9 6.5C9 5.67157 9.67157 5 10.5 5H13.5C14.3284 5 15 5.67157 15 6.5V17.5C15 18.3284 14.3284 19 13.5 19H10.5C9.67157 19 9 18.3284 9 17.5V6.5Z" fill="#151618"/>
          <path d="M17 6.5C17 5.67157 17.6716 5 18.5 5H21.5C22.3284 5 23 5.67157 23 6.5V17.5C23 18.3284 22.3284 19 21.5 19H18.5C17.6716 19 17 18.3284 17 17.5V6.5Z" fill="#C2C5C9"/>
        </svg>`,
        isActive: this._data.columns === 2,
        closeOnActivate: true,
        toggle: 'columns',
        onActivate: () => this.toggleTune({ columns: 2 }),
      },
      {
        name: 'three-column',
        label: 'Three columns',
        icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="0">
          <path d="M2.5 5C1.67157 5 1 5.67157 1 6.5V17.5C1 18.3284 1.67157 19 2.5 19H5.5C6.32843 19 7 18.3284 7 17.5V6.5C7 5.67157 6.32843 5 5.5 5H2.5Z" fill="#151618"/>
          <path d="M9 6.5C9 5.67157 9.67157 5 10.5 5H13.5C14.3284 5 15 5.67157 15 6.5V17.5C15 18.3284 14.3284 19 13.5 19H10.5C9.67157 19 9 18.3284 9 17.5V6.5Z" fill="#151618"/>
          <path d="M17 6.5C17 5.67157 17.6716 5 18.5 5H21.5C22.3284 5 23 5.67157 23 6.5V17.5C23 18.3284 22.3284 19 21.5 19H18.5C17.6716 19 17 18.3284 17 17.5V6.5Z" fill="#151618"/>
        </svg>`,
        isActive: this._data.columns === 3,
        closeOnActivate: true,
        toggle: 'columns',
        onActivate: () => this.toggleTune({ columns: 3 }),
      },
    ]
  }

  /**
   * Update the data, trigger an onchange
   */
  toggleTune(data) {
    this.data = data;
    this.block.dispatchChange();
  }

  /**
   * List data setter
   */
  set data(newData) {
    // save the data internally (persist unchanged values)
    this._data = {
      ...this._data,
      ...newData,
    };
    // trigger a re-render
    const wrapper = this._elements.wrapper;
    if(wrapper) wrapper.parentNode.replaceChild(this.render(), wrapper);
  }

  /**
   * Return List data
   */
  get data() {
    return this._data;
  }

  /**
   * Read the latest data from the UI and save it to the block data
   */
  save() {
    this._data.heading = this._data.showHeading ? this._elements.wrapper.querySelector(`.${CSS.heading}`).innerHTML.trim() : '';
    const items = Array.from(this._elements.wrapper.querySelectorAll('li'));
    this._data.items = items.map(item => item.innerHTML.replace('<br>', ' ').trim());
    return this._data;
  }

  /**
   * Returns current List item by the caret position
   */
  get currentItem() {
    let currentNode = window.getSelection().anchorNode;
    if (currentNode.nodeType !== Node.ELEMENT_NODE) currentNode = currentNode.parentNode;
    return currentNode.closest(`li`);
  }

  /**
   * Get out from List Tool by Enter on the empty last item
   */
  handleEnter(event) {
    const items = Array.from(this._elements.wrapper.querySelectorAll('li'));
    if (items.length < 2) return;

    const lastItem = items[items.length - 1];
    const currentItem = this.currentItem;

    /** Prevent Default li generation if item is empty */
    if (currentItem === lastItem && !lastItem.textContent.trim().length) {
      /** Insert New Block and set caret */
      currentItem.parentElement.removeChild(currentItem);
      this.api.blocks.insert();
      this.api.caret.setToBlock(this.api.blocks.getCurrentBlockIndex());
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Handle backspace (don't allow deleting the last list item)
   */
  handleBackspace(event) {
    const items = Array.from(this._elements.wrapper.querySelectorAll('li'));
    const firstItem = items[0];
    // if there are no items, just exit
    if (!firstItem) return;
    // if there is exactly one item, git rid of any linebreaks and save it
    if (items.length < 2 && !firstItem.innerHTML.replace('<br>', ' ').trim()) {
      return event.preventDefault();
    }
  }

  /**
   * Allow List Tool to be converted to/from other block
   *
   */
  static get conversionConfig() {
    return {
      /**
       * To create exported string from list, concatenate with a space.
       */
      export: (data) => {
        return data.items.join(' ');
      },
      /**
       * To create a list from other block's string, just put it at the first item
       *
       * @param {string} string - string to create list tool data from that
       * @returns {ListData}
       */
      import: (string) => {
        return {
          items: [string],
          style: 'unordered',
          showHeading: false,
        };
      },
    };
  }

  /**
   * Sanitizer rules
   */
  static get sanitize() {
    return {
      style: false,
      items: { br: true },
      heading: {},
    };
  }

};
