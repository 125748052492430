import React from 'react';
import { get, merge } from 'lodash';
import { createRoot } from 'react-dom/client';

import { helpers } from '@moved/services';

import { BaseTool } from '../BaseTool';
import { ButtonEditor } from './ButtonEditor';
import { ButtonSettings } from './ButtonSettings';

import CSS from './Button.module.scss';
import icon from './icon.svg';

export class Button extends BaseTool {
  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      title: 'Link Button',
      icon: `<img src="${icon}" alt="Button" height="24" width="24"/>`,
    }
  }

  /**
   * Sanitizer rules
   */
  static get sanitize() {
    return {
      text: false, // disallow HTML
      url: false, // disallow HTML
      color: false, // disallow HTML
      customColor: false, // disallow HTML
    };
  }

  static isCustomColorDark(customColor) {
    return customColor && helpers.isHexDark(customColor);
  }

  static getBaseColor(data) {
    return (
      data.color === 'custom' ?
        (data.customColor && this.isCustomColorDark(data.customColor) ? 'primary' :'secondary') :
        data.color
    );
  }


  /**
   * Construct class base data
   *
   * @param {RawData} data — previously saved HTML data
   * @param {object} config - user config for Tool
   * @param {object} api - CodeX Editor API
   * @param {boolean} readOnly - read-only mode flag
   */
  constructor({ data, config, api, block, readOnly }) {
    super({ api, block, readOnly });

    this.wrapper = undefined;

    this.placeholders = merge(
      {
        text: 'Enter text...',
        url: 'Enter url...',
      },
      get(config,'placeholders',{}),
    );

    this.data = {
      text: data.text,
      url: data.url,
      color: data.color || 'secondary',
      customColor: data.customColor,
    };

  }

  // render the tool inside the editor
  render() {
    this.wrapper = this.makeDOMElement('div',[CSS.editor]);
    const root = createRoot(this.wrapper);
    root.render(<ButtonEditor button={this}/>);
    return this.wrapper;
  }

  // format the data when saving
  save(wrapper) {
    this.data = {
      ...this.data,
      text: wrapper.querySelector(`.${CSS.button}`).innerHTML,
      url: wrapper.querySelector(`[name="urlValue"]`).value,
    };
    return this.data;
  }

  /**
   * Settings
   *
   * @public
   * @returns {Element}
   */
  renderSettings() {
    const wrapper = this.makeDOMElement('div');
    const root = createRoot(wrapper);
    root.render(
      <ButtonSettings
        block={this}
        onChange={(color,customColor) => this._toggleTune(color,customColor)}
      />
    );
    return wrapper;
  }

  /**
   * update the data for the active tune and force rerenders as needed
   * @private
   * @param {string} name - specific tune configuration from settings
   */
  _toggleTune(name, customColor) {
    this.data.color = name;
    this.data.customColor = (name === 'custom') ? customColor : null;
    // manually force an onchange event to save the data
    this.block.dispatchChange();
    // update the tool markup because it does not react to the data change
    const tool = this.wrapper;
    if(tool) tool.parentNode.replaceChild(this.render(), tool);
  }

  // expose the static helpers as public instance methods
  isCustomColorDark() {
    return this.constructor.isCustomColorDark(this.data.customColor);
  }

  getBaseColor() {
    return this.constructor.getBaseColor(this.data);
  }

};
