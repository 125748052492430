import React, { useState } from 'react';
import classNames from 'classnames';

import { Icon } from '@moved/ui';
import { useModal } from '@moved/services';

import { AboutUsModal } from '../AboutUsModal';
import mvd_logo from './mvd-logo.svg';
import CSS from './Footer.module.scss';

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  const Modal = useModal();

  const [siteOpen, setSiteOpen] = useState(false);
  const [legalOpen, setLegalOpen] = useState(false);
  const [connectOpen, setConnectOpen] = useState(false);

  return (
    <footer id={'footer'} className={CSS.footer}>
      <div className={CSS.container}>

        <div className={CSS.branding}>
          <img src={mvd_logo} alt="Moved." className={CSS.mvd_logo} />
          <span className={CSS.divider} />
          <p className={CSS.copyright}>
            <span>&copy; Moved {currentYear}</span>
            <span>All rights reserved</span>
          </p>
        </div>

        <div className={CSS.nav}>

          <div className={classNames(CSS.nav_col, { [CSS.nav_open]: siteOpen })}>
            <h4 onClick={ () => setSiteOpen(!siteOpen) }>
              <span>Site</span>
              <Icon symbol='Chevron-right' library='navigation' color='white' className={CSS.nav_arrow} />
            </h4>
            <p>
              <span className={CSS.nav_link} onClick={() => Modal.open(<AboutUsModal/>)}>About</span>
            </p>
            <p>
              <a href={`${process.env.REACT_APP_MOVED_WEB_URL}/contact`} target="_blank" rel="noopener noreferrer">Contact</a>
            </p>
          </div>

          <div className={classNames(CSS.nav_col, { [CSS.nav_open]: legalOpen })}>
            <h4 onClick={ () => setLegalOpen(!legalOpen) }>
              <span>Legal</span>
              <Icon symbol='Chevron-right' library='navigation' color='white' className={CSS.nav_arrow} />
            </h4>
            <p>
              <a href={`${process.env.REACT_APP_MOVED_WEB_URL}/privacy-policy`} target="_blank" rel="noopener noreferrer">Privacy policy</a>
            </p>
            <p>
              <a href={`${process.env.REACT_APP_MOVED_WEB_URL}/terms-of-use`} target="_blank" rel="noopener noreferrer">Terms of use</a>
            </p>
          </div>

          <div className={classNames(CSS.nav_col, { [CSS.nav_open]: connectOpen })}>
            <h4 onClick={ () => setConnectOpen(!connectOpen) }>
              <span>Connect with us</span>
              <Icon symbol='Chevron-right' library='navigation' color='white' className={CSS.nav_arrow} />
            </h4>
            <p>
              <a href="https://instagram.com/movedapp" target="_blank" rel="noopener noreferrer">Instagram</a>
            </p>
            <p>
              <a href="https://facebook.com/movedapp" target="_blank" rel="noopener noreferrer">Facebook</a>
            </p>
            <p>
              <a href="https://twitter.com/movedapp" target="_blank" rel="noopener noreferrer">Twitter</a>
            </p>
            <p>
              <a href="https://moved.com/chapter-by-moved" target="_blank" rel="noopener noreferrer">Blog</a>
            </p>
          </div>

        </div>
      </div>
    </footer>
  )
};
