import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useOptionalControl } from '@moved/services';

import CSS from './Textarea.module.scss';

export const Textarea = ({
  name,
  value,
  isControlled,
  label,
  hint,
  placeholder,
  disabled,
  readOnly,
  onChange,
  className,
  error,
  autoResize,
}) => {
  const [text, setText] = useOptionalControl(value ?? '', isControlled);
  const input = useRef();
  const caretPosition = useRef();

  const handleChange = (e) => {
    if(disabled) return;
    isControlled && e.preventDefault();
    const newValue = e.target.value;
    caretPosition.current = e.target.selectionStart;
    setText(newValue);
    onChange?.({[name]:newValue});
  };

  // caret position persistence
  useEffect(() => {
    if(isControlled) input.current.setSelectionRange(caretPosition.current,caretPosition.current);
  },[isControlled, text]);

  // auto height resize
  useEffect(() => {
    if(autoResize || readOnly) {
      input.current.style.height = 'auto';
      input.current.style.height = `${input.current.scrollHeight+2}px`; // +2 for border
    }
  },[text, autoResize, readOnly]);

  return (
    <div className={classNames('stackVertical-4', className)}>
      { label && (
        <label className={classNames(CSS.label, 'labelS contentSecondary')} htmlFor={name}>{ label }</label>
      )}
      <textarea
        className={classNames(
          CSS.textarea,
          { [CSS.hasError]: error },
        )}
        value={text}
        name={name}
        id={name}
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        onChange={handleChange}
        rows={autoResize ? '1' : '2'}
        ref={input}
      />
      { error ? (
        <div className='labelS contentError'>{ error }</div>
      ) : hint && (
        <div className={classNames('labelS', disabled ? 'contentTertiary' : 'contentSecondary')}>{ hint }</div>
      )}
    </div>
  );
};

Textarea.propTypes = {
  /** Name to use for the form input */
  name: PropTypes.string.isRequired,
  /** Text value to use for this input (only initial value if not controlled) */
  value: PropTypes.string,
  /** Flag to make the input a controlled input */
  isControlled: PropTypes.bool,
  /** Label text for the input */
  label: PropTypes.string,
  /** (optional) Second line of text */
  hint: PropTypes.string,
  /** (optional) Placeholder inside the textarea before user input */
  placeholder: PropTypes.string,
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** Flag to readonly the input */
  readOnly: PropTypes.bool,
  /** onChange handler function */
  onChange: PropTypes.func,
  /** class name to add to the input container */
  className: PropTypes.string,
  /** error message to display for this input */
  error: PropTypes.string,
  /** Flag to tell the textarea to automatically grow if the text inside is too large */
  autoResize: PropTypes.bool,
};
