import React from 'react';
import { Field } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';
import { get } from 'lodash';

import formCSS from '../StyledForm.module.scss';
import { InputWrapper } from '../InputWrapper';

/*
*  Implemented autosizing via: https://github.com/Andarist/react-textarea-autosize
*  Supports minRows (number) and maxRows (number) in addition to native textarea props
*/

const FieldTextarea = (props) => {
  const { input } = props;

  const onChange = (e, setFieldValue, name) => {
    //Passing up the onchange event for use in parent
    input.onChange && input.onChange(e, setFieldValue, name);
    return setFieldValue(name, e.target.value, true);
  }

  return (
    <InputWrapper {...props}>
      <Field
        name={input.name}
        validate={input.validate}
      >
        {({ field, form, disabled }) => {
          const { errors, touched, setFieldValue } = form;
          const classes =  classNames(formCSS.base_textarea, {
            [formCSS.has_error]: get(errors, input.name) && get(touched, input.name),
            [formCSS.has_value]: field.value,
            [formCSS.bordered]: input.bordered,
          });
          return (
            <TextareaAutosize
              {...field}
              id={input.name}
              className={classes}
              placeholder={input.placeholder}
              readOnly={input.readOnly}
              disabled={disabled ? 'disabled' : null }
              onChange={e => {onChange(e, setFieldValue, field.name);}}
              onBlur={e => input.onBlur && input.onBlur(form)}
              onFocus={e => input.onFocus && input.onFocus(form)}
              minRows={input.minRows}
              maxRows={input.maxRows}
            />
          );
        }}
      </Field>
    </InputWrapper>
  );
}

export default FieldTextarea;
