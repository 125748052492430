/*
*  Lightweight wrapper for `react-horizontal-scrolling-menu`
*  - Handles functional logic for standard use of Next, Previous buttons
*    as well as scrolling to the selected item within the context of the
*    scroller internally
*  - Unopinionated about all styles, but provides all of the props to pass them
*    in as necessary
*  - Supporst pass through of any props that work with `react-horizontal-scrolling-menu`
*    https://github.com/asmyshlyaev177/react-horizontal-scrolling-menu#readme
*/

import React, { useContext, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { ScrollMenu as Scroller, VisibilityContext } from 'react-horizontal-scrolling-menu';
// Polyfill for older browser support
import intersectionObserver from 'intersection-observer'; // eslint-disable-line
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Helper to map mouse wheel scroll events and trackpad scroll events to the horizontal scroll actions
const onWheel = (apiObj, ev) => {
  const isTouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
  if (isTouchpad) { ev.stopPropagation(); return; }
  if (ev.deltaY < 0) apiObj.scrollNext();
  else if (ev.deltaY > 0) apiObj.scrollPrev();
}

// Wrapper to use any custom content as a Previous button (must be used inside the Horizontal Scroller for context)
export const ScrollPrevious = ({ className, disabledClassName, scrollTo='end', children }) => {
  const { isFirstItemVisible, scrollToItem, visibleItemsWithoutSeparators, getItemById, getItemByIndex } = useContext(VisibilityContext);
  const firstVisibleItemId = visibleItemsWithoutSeparators[0];
  const firstVisibleItem = getItemById(firstVisibleItemId);
  const prevItem = firstVisibleItem && getItemByIndex(parseInt(firstVisibleItem.index) - 1);
  const handleClick = () => { if(prevItem) scrollToItem(prevItem.entry.target,'smooth',scrollTo) };
  return (
    <div className={classNames(className,{[disabledClassName]: isFirstItemVisible})} onClick={handleClick}>
      { children }
    </div>
  );
};

// Wrapper to use any custom content as a Next button (must be used inside the Horizontal Scroller for context)
export const ScrollNext = ({ className, disabledClassName, scrollTo='start', children }) => {
  const { isLastItemVisible, scrollToItem, visibleItemsWithoutSeparators, getItemById, getItemByIndex } = useContext(VisibilityContext);
  const lastVisibleItemId = visibleItemsWithoutSeparators[visibleItemsWithoutSeparators.length - 1];
  const lastVisibleItem = getItemById(lastVisibleItemId);
  const nextItem = lastVisibleItem && getItemByIndex(parseInt(lastVisibleItem.index) + 1);
  const handleClick = () => { if(nextItem) scrollToItem(nextItem.entry.target,'smooth',scrollTo) };
  return (
    <div className={classNames(className,{[disabledClassName]: isLastItemVisible})} onClick={handleClick}>
      { children }
    </div>
  );
};

// Wrapper to use any custom content as an item in the scroll container (must be used inside the Horizontal Scroller for context)
export const ScrollItem = ({
  id, // required
  onSelect,
  scrollOnSelect = 'auto',
  className,
  children,
}) => {
  const { scrollToItem, getItemElementById } = useContext(VisibilityContext);
  const handleSelect = () => {
    if(scrollOnSelect) scrollToItem(getItemElementById(id),'smooth',scrollOnSelect);
    onSelect && onSelect(id);
  };
  return (
    <div id={id} onClick={handleSelect} className={className}>
      { children }
    </div>
  );
};

export const HorizontalScroll = forwardRef(({
  scrollOnMount,
  children,
  ...props
}, ref) => {
  const apiRef = useRef();
  useEffect(() => {
    const { scrollToItem, getItemElementById } = apiRef.current || {};
    if (scrollOnMount) { scrollToItem(getItemElementById(scrollOnMount)); }
  },[]);  // eslint-disable-line

  // map the apiRef to the parent ref
  useImperativeHandle(ref, () => ({ ...apiRef.current }));

  return (
    <Scroller
      onWheel={onWheel}
      apiRef={apiRef}
      { ...props }
    >
      { children }
    </Scroller>
  );
});

HorizontalScroll.propTypes = {
  /* if initial scroll position, pass in the id of the element to scroll to */
  scrollOnMount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  /* a reference object that will be assigned the scroller API for parent controls */
  ref: PropTypes.object,
  /* content to render inside the scroller */
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  /* NOTE: also supports pass through of any props that work with `react-horizontal-scrolling-menu` */
  /* https://github.com/asmyshlyaev177/react-horizontal-scrolling-menu#readme */
};
