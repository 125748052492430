import React from 'react';
import { get, merge } from 'lodash';
import { addressService } from '../../services/addressService';
import { address } from './address';

const hasFloorInfo = (stop) => {
  return (stop.floor || stop.has_elevator || stop.flights_of_stairs !== 0);
};

export const stopDisplay = (stop, options) => {
  options = merge({}, {
    floor: true,
    stairs: true,
    type: false,
    ownership: false,
  }, options);
  return (
    <div>
      <div>
        { address(stop.address,get(options,'address')) }
      </div>
      {(get(options,'floor') || get(options,'stairs')) && hasFloorInfo(stop) && (
        <div>
          {[
            get(options,'floor') && addressService.getFloorLabel(stop),
            get(options,'stairs') && addressService.getStairsLabel(stop),
          ].filter(v=>v).join(' - ')}
        </div>
      )}
      {(get(options,'type') || get(options,'ownership')) && (
        <div>
          {[
            get(options,'type') && addressService.getTypeLabel(stop),
            get(options,'ownership') && addressService.getOwnershipLabel(stop),
          ].filter(v=>v).join(' - ')}
        </div>
      )}
    </div>
  );
};

export const stopDisplayText = (stop, options) => {
  options = merge({}, {
    floor: true,
    stairs: true,
    type: false,
    ownership: false,
  }, options);
  return [
    address(stop.address,get(options,'address')),
    (get(options,'floor') || get(options,'stairs')) && hasFloorInfo(stop) && (
      [
        get(options,'floor') && addressService.getFloorLabel(stop),
        get(options,'stairs') && addressService.getStairsLabel(stop),
      ].filter(v=>v).join(' - ')
    ),
    (get(options,'type') || get(options,'ownership')) && (
      [
        get(options,'type') && addressService.getTypeLabel(stop),
        get(options,'ownership') && addressService.getOwnershipLabel(stop),
      ].filter(v=>v).join(' - ')
    ),
  ].filter(v=>v).join(`\n`);
};
