import React from 'react';
import { Field } from 'formik';

import { CustomSelect, InputWrapper } from '../';

import formCSS from '../StyledForm.module.scss';

export const FieldSelect = (props) => {

  const { input, validate, formStyle } = props;

  const onChange = (option, setFieldValue, name) => {
    input.onChange && input.onChange(option, setFieldValue, name);
    return;
  };

  return (
    <InputWrapper {...props}>
      <Field
        name={input.name}
        validate={validate}
        component={CustomSelect}
        options={input.options}
        isMulti={input.isMulti}
        onChange={onChange}
        readOnly={input.readOnly}
        disabled={input.disabled}
        label={input.label}
        placeholder={input.placeholder}
        className={formCSS.select_container}
        formStyle={formStyle}
      />
    </InputWrapper>
  );
}
