import React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import { get } from 'lodash';

import formCSS from '../StyledForm.module.scss';
import { InputWrapper } from '../InputWrapper';
import { AtomSpinner } from '../../AtomSpinner';

export const FieldDefault = (props) => {
  const { input, validate } = props;

  const onBlur = (e, setFieldValue, name) => {
    //Passing up the onBlur event for use in parent
    input.onBlur && input.onBlur(e, setFieldValue, name);
    // return setFieldValue(name, e.target.value, true);
  }

  const onChange = (e, setFieldValue, name) => {
    //Passing up the onchange event for use in parent
    input.onChange && input.onChange(e, setFieldValue, name);
    return setFieldValue(name, e.target.value, true);
  }

  return (
    <InputWrapper {...props}>
      <Field
        name={input.name}
        validate={validate}
      >
        {({ field, form, disabled }) => {
          const { errors, touched, setFieldValue } = form;
          const classes = classNames({
            [formCSS.has_error]: (get(errors, input.name) && get(touched, input.name)) || input.externalError,
            [formCSS.has_value]: field.value,
          });
          return (
            <>
              <input
                {...field}
                className={classes}
                placeholder={input.placeholder}
                type={input.type}
                id={input.name}
                readOnly={input.readOnly}
                maxLength={input.maxlength}
                onBlur={e => {onBlur(e, setFieldValue, field.name);}}
                onChange={e => {onChange(e, setFieldValue, field.name);}}
                disabled={disabled ? 'disabled' : null }
              />
              {input.pending && (
                <div className={formCSS.verify_pending}>
                  <span>{input.pending}</span>
                  <AtomSpinner size={16} />
                </div>
              )}
            </>
          );
        }}
      </Field>
    </InputWrapper>
  );
}
