import { get, merge } from 'lodash';

import { BaseTool } from '../BaseTool';

import CSS from './CheckList.module.scss';
import icon from './icon.svg';

const COLORS = {
  black: '#151618',
  blue: '#3f92f5',
  green: '#40a369',
  red: '#dd5858',
  orange: '#d87844',
  yellow: '#f5d365',
  purple: '#a967e8',
  brown: '#b08f75',
};

export class CheckList extends BaseTool {
  /**
   * Get Tool toolbox settings
   * icon - Tool icon's SVG
   * title - title to show in toolbox
   *
   * @returns {{icon: string, title: string}}
   */
  static get toolbox() {
    return {
      title: 'Checklist',
      icon: `<img src="${icon}" alt="Checklist" height="12" width="24"/>`,
    }
  }

  /**
   * Allow Checklist to be converted to/from other blocks
   */
  static get conversionConfig() {
    return {
      /**
       * To create exported string from list, concatenate items by dot-symbol.
       *
       * @param {ListData} data - list data to create a string from thats
       * @returns {string}
       */
      export: (data) => {
        return data.items.join('. ');
      },
      /**
       * To create a list from other block's string, split it at the dot-symbol.
       *
       * @param {string} string - string to create list tool data from that
       * @returns {ListData}
       */
      import: (string) => {
        return {
          items: string.split('. '),
          color: 'green',
        };
      },
    };
  }

  /**
   * Sanitizer rules
   */
  static get sanitize() {
    return {
      color: {},
      items: {
        br: true,
      },
    };
  }

  /**
   * Settings
   *
   * @public
   * @returns {Element}
   */
  renderSettings() {
    const wrapper = this.makeDOMElement('div', [ this.CSS.settingsWrapper ], {});

    this.settings.forEach((item) => {
      const itemEl = this.makeDOMElement('div', this.CSS.settingsButton, {
        innerHTML: item.icon,
      });

      itemEl.addEventListener('click', () => {
        this.toggleTune(item.name);

        // clear other buttons
        const buttons = itemEl.parentNode.querySelectorAll('.' + window.CSS.escape(this.CSS.settingsButton));

        Array.from(buttons).forEach((button) =>
          button.classList.remove(this.CSS.settingsButtonActive)
        );

        // mark active
        itemEl.classList.toggle(this.CSS.settingsButtonActive);
      });

      this.api.tooltip.onHover(itemEl, item.title, {
        placement: 'top',
        hidingDelay: 500,
      });

      if (this._data.style === item.name) {
        itemEl.classList.add(this.CSS.settingsButtonActive);
      }

      wrapper.appendChild(itemEl);
    });

    return wrapper;
  }

  /**
   * Toggles List style
   *
   * @param {string} color - 'ordered'|'unordered'
   */
  toggleTune(color) {
    const newTag = this.makeMainTag(color);

    while (this._elements.wrapper.hasChildNodes()) {
      newTag.appendChild(this._elements.wrapper.firstChild);
    }

    this._elements.wrapper.replaceWith(newTag);
    this._elements.wrapper = newTag;
    this._data.color = color;
  }

  /**
   * Tool`s styles
   *
   * @returns {{baseClass: string, wrapper: string, item: string, settingsWrapper: string, settingsButton: string, settingsButtonActive: string}}
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
      wrapper: CSS.editor,
      item: CSS.item,
      settingsWrapper: CSS.settings,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,
    };
  }

  get initialIcon() {
    return { library: 'code', symbol: 'Stop' };
  }

  /**
   * Construct class base data
   *
   * @param {RawData} data — previously saved HTML data
   * @param {object} config - user config for Tool
   * @param {object} api - CodeX Editor API
   * @param {boolean} readOnly - read-only mode flag
   */
  constructor({ data, config, api, readOnly }) {
    super({ api, readOnly });
    this._elements = {
      wrapper: null,
    };
    this.placeholders = merge(
      {
        content: 'Enter content...',
      },
      get(config,'placeholders',{}),
    );

    this.settings = Object.keys(COLORS).map(key => {
      return this.generateSettings(key,COLORS[key]);
    });

    this._data = {
      items: data.items || [],
      color: data.color,
    };

    this.data = data;
  }

  generateSettings(name, color) {
    return {
      name,
      title: name.charAt(0).toUpperCase() + name.slice(1),
      icon: `<svg width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
          <path d="M0 0h24v24H0z" style="stroke: none !important"></path>
          <circle stroke="none" cx="12" cy="12" fill="${color}" style="fill: ${color}" ie-style="fill: ${color};" r="10"></circle>
          <path style="stroke: none !important" d="M16.338 7.334a.902.902 0 0 1 1.357-.05c.388.396.409 1.062.047 1.487l-6.72 7.895a.9.9 0 0 1-1.327.08l-3.36-3.157c-.402-.379-.449-1.043-.104-1.485.345-.441.951-.492 1.354-.114l2.661 2.501z" fill="#fff" style="fill: #fff" ie-style="fill: #fff;"></path>
        </g>
      </svg>`,
      default: color.name === 'green',
    };
  }

  render() {
    this._elements.wrapper = this.makeMainTag(this._data.color);

    // fill with data
    if (this._data.items.length) {
      this._data.items.forEach((item) => {
        this._elements.wrapper.appendChild(this.makeDOMElement('div', this.CSS.item, {
          innerHTML: item,
        }));
      });
    } else {
      this._elements.wrapper.appendChild(this.makeDOMElement('div', this.CSS.item));
    }

    if (!this.readOnly) {
      // detect keydown on the last item to escape List
      this._elements.wrapper.addEventListener('keydown', (event) => {
        const [ENTER, BACKSPACE] = [13, 8]; // key codes

        switch (event.keyCode) {
          case ENTER:
            this.getOutofList(event);
            break;
          case BACKSPACE:
            this.backspace(event);
            break;
          default:
            break;
        }
      }, false);
    }

    return this._elements.wrapper;
  }

  /**
   * Creates main tag with color based class depending on color
   *
   * @param {string} color - 'green', 'blue', 'red', etc.
   * @returns {HTMLOListElement|HTMLUListElement}
   */
  makeMainTag(color){
    return this.makeDOMElement('div', [this.CSS.wrapper, CSS[color]], {
      contentEditable: !this.readOnly,
      data: {color: this._data.color},
    });
  }

  /**
   * Returns current List item by the caret position
   *
   * @returns {Element}
   */
  get currentItem() {
    let currentNode = window.getSelection().anchorNode;

    if (currentNode.nodeType !== Node.ELEMENT_NODE) {
      currentNode = currentNode.parentNode;
    }

    return currentNode.closest(`.${this.CSS.item}`);
  }

  /**
   * Get out from Checklist Tool
   * by Enter on the empty last item
   *
   * @param {KeyboardEvent} event
   */
  getOutofList(event) {
    const items = this._elements.wrapper.querySelectorAll('.' + window.CSS.escape(this.CSS.item));

    /**
     * Save the last one.
     */
    if (items.length < 2) {
      return;
    }

    const lastItem = items[items.length - 1];
    const currentItem = this.currentItem;

    /** Prevent item generation if last item is empty */
    if (currentItem === lastItem && !lastItem.textContent.trim().length) {
      /** Insert New Block and set caret */
      currentItem.parentElement.removeChild(currentItem);
      this.api.blocks.insert();
      this.api.caret.setToBlock(this.api.blocks.getCurrentBlockIndex());
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Handle backspace
   *
   * @param {KeyboardEvent} event
   */
  backspace(event) {
    const items = this._elements.wrapper.querySelectorAll('.' + window.CSS.escape(this.CSS.item)),
        firstItem = items[0];

    if (!firstItem) {
      return;
    }

    /**
     * Save the last one.
     */
    if (items.length < 2 && !firstItem.innerHTML.replace('<br>', ' ').trim()) {
      event.preventDefault();
    }
  }

  /**
   * Select LI content by CMD+A
   *
   * @param {KeyboardEvent} event
   */
  selectItem(event) {
    event.preventDefault();

    const selection = window.getSelection(),
        currentNode = selection.anchorNode.parentNode,
        currentItem = currentNode.closest('.' + this.CSS.item),
        range = new Range();

    range.selectNodeContents(currentItem);

    selection.removeAllRanges();
    selection.addRange(range);
  }

  /**
   * List data setter
   *
   * @param {ListData} listData
   */
  set data(data) {
    if (!data) {
      data = {};
    }

    this._data.color = data.color || 'green';
    this._data.items = data.items || [];

    const oldView = this._elements.wrapper;

    if (oldView) {
      oldView.parentNode.replaceChild(this.render(), oldView);
    }
  }

  /**
   * Return List data
   *
   * @returns {ListData}
   */
  get data() {
    this._data.items = [];

    const items = this._elements.wrapper.querySelectorAll('.' + window.CSS.escape(this.CSS.item));

    for (let i = 0; i < items.length; i++) {
      const value = items[i].innerHTML.replace('<br>', ' ').trim();

      if (value) {
        this._data.items.push(items[i].innerHTML);
      }
    }

    return this._data;
  }

  //-------

  // format the data when saving
  save() {
    return this.data;
  }

}
