import PropTypes from 'prop-types';
import { Icon } from '../../Icon';

import CSS from './Chip.module.scss';

export const Chip = ({
  className,
  disabled,
  label,
  icon,
  name,
  onChange,
  selected,
  value,
}) => {
  const handleClick = () => {
    if (disabled) return;
    onChange?.(value);
  };

  return (
    <div className={className}>
      <input
        className={CSS.hiddenInput}
        name={name}
        type='checkbox'
        checked={selected}
        disabled={disabled}
        readOnly // Fixes warning, "You provided a `checked` prop to a form field without an `onChange` handler"
      />
      <label
        className={CSS.chip}
        onClick={handleClick}
      >
        {icon != null && (
          <Icon
            size='20px'
            symbol={icon.symbol}
            library={icon.library}
          />
        )}
        <span>
          {label}
        </span>
      </label>
    </div>
  );
};

Chip.propTypes = {
  /** (Optional) Custom class name to be applied to the chip */
  className: PropTypes.string,

  /** (Optional) Activates the disabled styles and disables click events */
  disabled: PropTypes.bool,

  /** The inner text of the chip */
  label: PropTypes.string.isRequired,

  /** (Optional) an Icon object to render before chip innerText */
  icon: PropTypes.shape({
    symbol: PropTypes.string.isRequired,
    library: PropTypes.string.isRequired,
  }),

  /** Name to use for the form input */
  name: PropTypes.string.isRequired,

  /** Callback invoked on click; passes up value */
  onChange: PropTypes.func.isRequired,

  /** Activates the selected styles */
  selected: PropTypes.bool,

  /** The value passed up by the onChange handler; also set on the form when the chip is selected */
  value: PropTypes.any.isRequired,
};
