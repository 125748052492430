import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Icon } from '../../Icon';
import CSS from './Option.module.scss';

export const Option = ({
  name,
  value,
  label,
  description,
  allowMultiple=false,
  icon={},
  size='large',
  selected,
  disabled,
  onChange,
  className,
}) => {
  const handleSelect = () => {
    if(disabled) return;
    onChange?.(value);
  };

  const containerClasses = [
    CSS.container,
    CSS[size],
    { [CSS.selected]: selected },
    { [CSS.disabled]: disabled },
    className,
  ];

  return (
    <div className={classNames(...containerClasses)} onClick={handleSelect}>
      <input className={CSS.input} name={name} type='checkbox' checked={selected} disabled={disabled} readOnly />
      { icon.symbol && (
        <Icon
          library={icon.library}
          symbol={icon.symbol}
          size={size === 'large' ? '48px' : '24px'}
          className={CSS.icon}
          role='graphics-symbol'
          tabIndex='0'
        />
      )}
      <div className={CSS.content}>
        <div className={classNames(CSS.label, disabled ? 'contentSecondary' : 'contentPrimary')}>{ label }</div>
        { description && size === 'large' && (
          <div className={classNames(CSS.description, disabled ? 'contentTertiary' : 'contentSecondary')}>{ description }</div>
        )}
      </div>
      { (allowMultiple || selected) && (
        <Icon
          library='code'
          symbol={allowMultiple ? (selected ? 'Checkbox-on' : 'Checkbox-off') : 'Radio-on'}
          size='24px'
          className={CSS.checkmark}
          role='graphics-symbol'
          tabIndex='0'
        />
      )}
    </div>
  );
};

Option.propTypes = {
  /** Name to use for the form input (shared for this select list item) */
  name: PropTypes.string.isRequired,
  /** Value to use for this select item input */
  value: PropTypes.any.isRequired,
  /** Select item checked state */
  selected: PropTypes.bool.isRequired,
  /** Label text for the input */
  label: PropTypes.node.isRequired,
  /** (optional) Second line of text */
  description: PropTypes.string,
  /** Flag to treat as checkboxes vs radio inputs */
  allowMultiple: PropTypes.bool,
  /** Flag to disable the input */
  disabled: PropTypes.bool,
  /** Size variant to display */
  size: PropTypes.oneOf(['small','large','square']),
  /** onChange handler function */
  onChange: PropTypes.func,
  /** (optional) class name to add to the select item component */
  className: PropTypes.string
};
