import React from 'react';

import weekCSS from '../../styles/WeeklyCalendar.module.scss';
import { AvailabilityDay } from './AvailabilityDay';
import { AvailabilityKey } from './AvailabilityKey';

//If calendar data is pending, don't rerender
const shouldSkipUpdate = (oldProps, newProps) => newProps.getCalendarPending;

export const AvailabilityWeek = React.memo((props) => {
  const { week, selected, preferences, view, jobs } = props;
  return (
    <div className={weekCSS.week_row}>

      <AvailabilityKey preferences={preferences} />

      { week.map(day => (
        <AvailabilityDay
          key={day.date}
          date={day.date}
          jobs={jobs[day.date]}
          appointments={day.slots}
          active={day.date === selected}
          view={view}
        />
      ))}

    </div>
  );
}, shouldSkipUpdate);
