import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import cardSVG from '../../../images/sprites/credit-cards.svg';
import CSS from './CreditCardLogo.module.scss';

export const CreditCardLogo = ({ symbol, width=36, className }) => (
  <i className={classNames(CSS.icon, className)} style={{ width: `${width}px`, height: `${width * 2/3}px` }}>
    <svg xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" alt={symbol}>
      <use xlinkHref={`${cardSVG}#${symbol}`}></use>
    </svg>
  </i>
);

CreditCardLogo.propTypes = {
  /** Number that determines size of svg in pixels */
  width: PropTypes.number,
  /** Which icon to use */
  symbol: PropTypes.oneOf(['visa','amex','stripe','apple-pay','apple-pay-dark','discover','mastercard','paypal','paycheck']),
  /** Optional class name */
  className: PropTypes.string,
};
