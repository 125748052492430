import { generate, request } from '@moved/services';

const creator = {
  namespace: 'JOBS_GET_JOB_OFFER',
  request: (token) => request.get(`/vendors/job-offers/${token}`).then(r => r.data.data.vendor_job_offer),
  selector: (state, token) => state?.jobOffers?.[token],
  reducers: {
    success: (state, { response: jobOffer, params: [token] }) => {
      return {
        ...state,
        jobOffers: {
          ...state.jobOffers,
          [token]: jobOffer,
        },
      };
    },
    failure: (state, { params: [token] }) => {
      return {
        ...state,
        jobOffers: {
          ...state.jobOffers,
          [token]: null,
        },
      };
    },
  }
};

export const {
  action: getJobOffer,
  reducer,
  useResource: useJobOffer,
  usePending: useJobOfferPending,
} = generate.redux(creator);

export const initialState = {
  jobOffers: {},
};
