import reduceReducers from 'reduce-reducers';
import { logoutReducer } from '../../services/userService';

export const rootReducer = (initialState, reducers) => {
  const baseReducer = reduceReducers(initialState, ...reducers);
  return (state, action) => {
    const newState = { ...initialState, ...state };
    const reducer = reduceReducers(initialState, logoutReducer, baseReducer);
    return reducer(newState, action);
  };
}
