import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { userService, useUser } from '@moved/services';
import { Button } from '@moved/ui';

import image from './images/404@2x.png';
import CSS from './Four04.module.scss';

export const Four04 = () => {
  const { user, isAuthenticated } = useUser();
  const history = useHistory();
  const redirect = () => userService.redirect(user,history);

  return (
    <div className={classNames('stackVertical justify-center items-center', CSS.wrapper)}>
      <div className={CSS.image}>
        <img src={image} alt="404" />
      </div>
      <div className={classNames('stackVertical gap-40 items-center', CSS.content)}>
        <div className='stackVertical gap-24 items-center'>
          <h1 className='displayXL contentPrimary'>Oops...</h1>
          <h3 className='headingL contentSecondary'>This page must be stuck in a moving box somewhere.</h3>
        </div>
        <Button
          onClick={redirect}
          color='secondary'
          text={isAuthenticated ? 'Back to Dashboard' : 'Sign in'}
        />
      </div>
    </div>
  );
};
