import { makeDOMElement } from './shared/helpers';

export class BaseTool {
  /**
   * Notify core that read-only mode is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow to press Enter inside the content
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  /**
   * Tool`s styles
   *
   * @returns {{baseClass: string}}
   */
  get CSS() {
    return {
      baseClass: this.api.styles.block,
    };
  }

  /**
   * Construct base class data
   *
   * @param {object} api - CodeX Editor API
   * @param {object} block - CodeX Editor Block API
   * @param {boolean} readOnly - read-only mode flag
   */
  constructor({ api, block, readOnly }) {
    this.api = api;
    this.block = block;
    this.readOnly = readOnly;
  }

  /**
   * Helper for making Elements with attributes
   *
   * @param  {string} tagName           - new Element tag name
   * @param  {Array|string} classNames  - list or name of CSS classname(s)
   * @param  {object} attributes        - any attributes
   * @param  {object} dataAttributes    - any data-attributes
   * @returns {Element}
   */
  makeDOMElement(...args) {
    return makeDOMElement(...args)
  }

}
