import React, { useState } from 'react';
import { get } from 'lodash';
import { AppStoreLink } from '../../../../../sondheim/components/AppStoreLink';

import CSS from './AppStoreLink.module.scss';

export const AppStoreLinkEditor = ({ appStoreLink }) => {
  const stores = get(appStoreLink,'data.stores',['apple','google']);

  // apple
  const [showAppleUrl, setShowAppleUrl] = useState();
  const [appleUrl, setAppleUrl] = useState(get(appStoreLink,'data.appleUrl',''));

  // google
  const [showGoogleUrl, setShowGoogleUrl] = useState();
  const [googleUrl, setGoogleUrl] = useState(get(appStoreLink,'data.googleUrl',''));

  // helper to handle input visibility states
  const setUrlInputVisibility = (store) => {
    if(store === 'apple') {
      setShowAppleUrl(!showAppleUrl);
      setShowGoogleUrl(false);
    } else {
      setShowGoogleUrl(!showGoogleUrl);
      setShowAppleUrl(false);
    }
  };

  return (
    <>
      <input type='hidden' name='appleUrlValue' value={appleUrl}/>
      { stores.includes('apple') && (
        <div className={CSS.link_wrapper}>
          <AppStoreLink type='apple' onClick={() => setUrlInputVisibility('apple')} height={44}/>
          { showAppleUrl && (
            <div className={CSS.url_wrapper}>
              <div className={CSS.label}>URL:</div>
              <input
                className={CSS.url_input}
                tabIndex={0}
                placeholder={get(appStoreLink,'placeholder')}
                onChange={event => setAppleUrl(event.target.value)}
                value={appleUrl}
              />
            </div>
          )}
        </div>
      )}
      <input type='hidden' name='googleUrlValue' value={googleUrl}/>
      { stores.includes('google') && (
        <div className={CSS.link_wrapper}>
          <AppStoreLink type='google' onClick={() => setUrlInputVisibility('google')} height={44} />
          { showGoogleUrl && (
            <div className={CSS.url_wrapper}>
              <div className={CSS.label}>URL:</div>
              <input
                className={CSS.url_input}
                tabIndex={0}
                placeholder={get(appStoreLink,'placeholder')}
                onChange={event => setGoogleUrl(event.target.value)}
                value={googleUrl}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
