import { ordinal } from '../utils/formatters/ordinal';
import { title } from '../utils/formatters/title';

const floorOptions = [
  { value: 'basement', label: 'Basement' },
  { value: 'ground', label: 'Ground Floor' }
].concat(Array.from({length: 60}, (v, i) => ({
  value:(i+1).toString(),
  label:`${ordinal(i+1)} Floor`,
  requireElevator: (i+1) > 7,
})));

const stairOptions = [
  { value: 0, label: 'No Stairs' },
  { value: 1, label: '1 Flight of Stairs' },
  { value: 2, label: '2 Flights of Stairs' },
  { value: 3, label: '3 Flights of Stairs' },
  { value: 4, label: '4 Flights of Stairs' },
  { value: 5, label: '5 Flights of Stairs' },
  { value: 6, label: '6 Flights of Stairs' },
];

const stairElevatorOptions = [
  { value: 'has_elevator', label: 'Elevator' },
  ...stairOptions,
];

const simpleTypeOptions = [
  { label: 'Apartment', value: 'apartment', icon: { symbol: 'Building' }, },
  { label: 'House', value: 'house', icon: { symbol: 'Door-open', }, },
];

const typeOptions = [
  ...simpleTypeOptions,
  { label: 'Storage', value: 'storage', icon: { symbol: 'Home' }, },
  { label: 'Other', value: 'other', icon: { symbol: 'Other#1' }, },
];

const ownershipOptions = [
  { label: 'Own', value: 'own', },
  { label: 'Rent', value: 'rent', },
];

const getStopHint = ({ address, type, floor, flights_of_stairs, has_elevator }) => {
  return [
    type && (type !== 'apartment') && `Type: ${title(type)}`,
    address?.unit && `Unit: ${address.unit}`,
    floor && `Floor: ${addressService.getFloorLabel({ floor })}`,
    !has_elevator && flights_of_stairs && `Stairs: ${addressService.getStairsLabel({ flights_of_stairs })}`,
  ].filter(Boolean).join(' | ');
};

const getStopError = ({ type='apartment', address, floor, flights_of_stairs, has_elevator }) => {
  const missing = [
    type === 'apartment' && floor == null && 'Floor',
    type !== 'storage' && flights_of_stairs == null && has_elevator == null && 'Stairs/Elevator',
  ].filter(Boolean);
  if(missing.length === 0) return;
  return `Missing ${missing.join(', ')}`;
};

export const addressService = {
  floorOptions,
  stairOptions,
  stairElevatorOptions,
  simpleTypeOptions,
  typeOptions,
  ownershipOptions,
  getStopHint,
  getStopError,
  getFloorLabel: (stop) => floorOptions.find(option => option.value === stop.floor)?.label ?? '',
  getStairsLabel: (stop) => stairElevatorOptions.find(option => option.value === (stop.has_elevator ? 'has_elevator' : stop.flights_of_stairs))?.label ?? '',
  getTypeLabel: (stop) => typeOptions.find(option => option.value === stop.type)?.label ?? '',
  getOwnershipLabel: (stop) => ownershipOptions.find(option => option.value === stop.deed)?.label ?? '',
};
