import React from 'react';
import { ErrorMessage } from 'formik';
import classNames from 'classnames';

import formCSS from './StyledForm.module.scss';

const InputLabel = ({input}) => (
  <label className={formCSS.input_label} htmlFor={input.id || input.name}>
    <span>{input.label}</span>
  </label>
);

export const InputWrapper = ({ input, formStyle, children }) => {
  return (
    <div className={classNames(formCSS.input_row, formCSS[formStyle], formCSS[`type_${input.type}`], {[formCSS.half_input]: input.half}, input.className)}>
      { children }
      { input.label && !input.hiddenLabel && (<InputLabel input={input}/>) }
      { input.flavor && (
        <span className={formCSS.flavor}>{input.flavor}</span>
      )}
      {input.externalError && (
        <label htmlFor={input.id || input.name} component="label" className={formCSS.error_msg}>{input.externalError}</label>
      )}
      <ErrorMessage name={input.name} htmlFor={input.id || input.name} component="label" className={formCSS.error_msg} />
    </div>
  );
};
