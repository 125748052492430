import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import movr from '../../../images/movr.svg';
import CSS from './AtomSpinner.module.scss';

export const AtomSpinner = ({ size=45, white, className }) => {
  return (
    <div className={classNames('common--atomSpinner',className)}>
      <div style={{width: size + 'px', height: size + 'px'}} className={['spinner', CSS.atom_spinner].join(' ')}>
        <img src={movr} className={CSS['movr-logo'] + ' ' + (white  ? CSS.white : '')} alt="Loading&hellip;" />
      </div>
    </div>
  );
};

AtomSpinner.propTypes = {
  /** Number that determines size of spinner in pixels */
  size: PropTypes.number,
  /** Boolean to determine if spinner is white or black */
  white: PropTypes.bool,
};
