import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import CSS from "./Transitions.module.scss";
import scale from "./scale.module.scss";
import scaleOut from "./scaleOut.module.scss";
import crossFade from './crossFade.module.scss';
import slideRight from "./slideRight.module.scss";
import slideLeft from "./slideLeft.module.scss";
import slide from './slide.module.scss';

import rotateRoomTop from './rotateRoomTop.module.scss';
import rotateRoomBottom from './rotateRoomBottom.module.scss';
import rotateRoomRight from './rotateRoomRight.module.scss';
import rotateRoomLeft from './rotateRoomLeft.module.scss';

import moveToTopEasing from './moveToTopEasing.module.scss';
import moveToBottomEasing from './moveToBottomEasing.module.scss';

// the childFactory allows to change the transition of the leaving component
// https://github.com/reactjs/react-transition-group/issues/182
const childFactoryCreator = (props) => child => React.cloneElement(child, props)

export const Transitions =  ({ transition = '', pageKey, children, className }) => {
  let transitionProps = {};
  switch (transition) {
    case 'slide':
      transitionProps = {
        classNames: slide,
        timeout: 1000,
      };
      break;
    case 'slideRight':
      transitionProps = {
        classNames: slideRight,
        timeout: 300,
      };
      break;
    case 'slideLeft':
      transitionProps = {
        classNames: slideLeft,
        timeout: 300,
      };
      break;
    case 'scale':
      transitionProps = {
        classNames: scale,
        timeout: 600,
      };
      break;
    case 'scaleOut':
      transitionProps = {
        classNames: scaleOut,
        timeout: 600,
      };
      break;
    case 'crossFade':
      transitionProps = {
        classNames: crossFade,
        timeout: 500,
      };
      break;

    case 'rotateRoomTop':
      transitionProps = {
        classNames: rotateRoomTop,
        timeout: 600,
      };
      break;
    case 'rotateRoomBottom':
      transitionProps = {
        classNames: rotateRoomBottom,
        timeout: 600,
      };
      break;
    case 'rotateRoomRight':
      transitionProps = {
        classNames: rotateRoomRight,
        timeout: 600,
      };
      break;
    case 'rotateRoomLeft':
      transitionProps = {
        classNames: rotateRoomLeft,
        timeout: 600,
      };
      break;

    case 'moveToTopEasing':
      transitionProps = {
        classNames: moveToTopEasing,
        timeout: 700,
      };
      break;
    case 'moveToBottomEasing':
      transitionProps = {
        classNames: moveToBottomEasing,
        timeout: 700,
      };
      break;

    default:
      transitionProps = {
        classNames: {},
        timeout: 0
      };
      break;
  }

  // Allow overriding to no transition and no forced unmount
  if(!transition || transition === 'none') return typeof children === "function" ? children() : children;

  return  (
    <TransitionGroup
      className={classNames(CSS.group,className)}
      childFactory={childFactoryCreator(transitionProps)}
    >
      <CSSTransition key={pageKey} timeout={transitionProps.timeout}>
        {/* you should wrap CSSTransition child in a div in case it could be null
        see https://github.com/reactjs/react-transition-group/issues/208 */}
        {state => (
          <div className={CSS.wrapper}>
            { typeof children === "function" ? children(state) : children }
          </div>
        )}

      </CSSTransition>
    </TransitionGroup>
  );
};
