import React from 'react';

import { helpers } from '@moved/services';

import { Icon } from '../../../sondheim/components/Icon';
import { FileTypeIcon } from '../FileTypeIcon';

import CSS from './Uploader.module.scss';

export const UploaderFilePreview = ({ file, onDelete }) => {
  const fileType = file.name ? file.name.split('.').pop().trim() : '';
  const canShowPreview = ['jpg','jpeg','png','gif'].includes(fileType);
  const preview = helpers.canvas.usePreview(file);

  const handleDelete = (e) => {
    e.stopPropagation();
    onDelete(file);
  }
  return (
    <div key={file.name} className={CSS.file_preview}>
      <div className={CSS.thumbnail}>
        { canShowPreview && preview ? (
          <img src={preview} style={{maxWidth:'100%',maxHeight:'100%'}} alt="Preview" />
        ) : (
          <FileTypeIcon type={fileType} size={60}/>
        )}
      </div>
      <div className={CSS.copy}>{file.name}</div>
      { onDelete && (
        <div className={CSS.delete} onClick={handleDelete}>
          <Icon library='general' symbol='Trash' />
        </div>
      )}
    </div>
  );
}
