import React from 'react';
import ReactDOM from 'react-dom';

import { OverlayProvider, OverlayInstance } from '@moved/services';

import { IconSelector } from '../../../../../sondheim/components/IconSelector';

export const EditorIconSelector = ({ initial, color, size='32px', onSelect }) => {
  return (
    <OverlayProvider>
      <IconSelector
        onSelect={onSelect}
        initial={initial || {library:'general',symbol:'Heart'}}
        useLibraries={true}
        color={color}
        size={size}
      />
      { ReactDOM.createPortal(<OverlayInstance />, document.body) }
    </OverlayProvider>
  );
};
